<template>
    <base-component-list 
        :blocks="blocks"
        title="Service settings"
    ></base-component-list>
</template>

<script>
import ServiceSubstances from '../components/Settings/ServiceSubstances.vue'
import ServiceUnits from '../components/Settings/ServiceUnits.vue'
import ServiceMethods from '../components/Settings/ServiceMethods.vue'
export default {
    name: "ServiceSettings",
    metaInfo: {
        title: 'Service settings'
    },
    computed: {
        blocks() {
            return [
                {
                    component: ServiceUnits
                },
                {
                    component: ServiceMethods
                },
                {
                    component: ServiceSubstances
                }
            ]
        }
    }

}
</script>

<style>

</style>