<template>
    
    <edit-form
        :title="title"
        :subTitle="subTitle"
        :schema="schema"
        storeNamespace="services"
        objectNamespace="O_priceperiod"
        :objectId="id"
        :listOnSave="listOnSave"
    >

    </edit-form>

</template>

<script>
import EditForm from '@/components/form/EditForm'
import {priceperiodSchema} from '@/modules/Service/schema/priceperiodSchema'
export default {
    name: "PriceperiodForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
    },
    data() {
      return {

      }
    },
    computed: {
        schema() {
            
          return this.$helpers.schemaParser(priceperiodSchema)

        },
        title() {
            return this.id ? "Update periceperiod" : "New priceperiod";
        },
        subTitle() {
            return this.id ? "Here you can edit a priceperiod" : "Here you type the information of the new priceperiod";
        },
        
    },
}
</script>

<style>

</style>