<template>

    <div>
        <base-action-list
            title="Grupper"
            subtitle="Här ser du vilka grupper som har tillgång till Labflow service"
            :headers="headers"
            storeNamespace="aaa"
            listNamespace="A_group"
            :newEnabled="false"
            :editEnabled="false"
            :icon="groupsLogo"
        >

        </base-action-list>
    </div>

</template>

<script>



export default {
    name: "Groups",
    metaInfo: {
        title: 'Groups'
    },

    data() {
      return {
        headers: [
            {
                text: 'Grupp namn',
                align: 'start',
                sortable: false,
                value: 'name'
            },
            {
                text: 'Prefix',
                align: 'start',
                sortable: false,
                value: 'prefix'
            }
        ],
        groupsLogo: require('@/assets/images/svg/share.svg')
      }
    },
}
</script>

<style>

</style>