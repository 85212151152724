
import Groups from './pages/group/Groups.vue'
import GroupEdit from './pages/group/GroupEdit.vue'

import Users from './pages/user/Users.vue'
import UserEdit from './pages/user/UserEdit.vue'
import Profile from './pages/user/Profile.vue'

import Roles from './pages/role/Roles.vue'

import AaaStore from './store/aaa.js'


// eslint-disable-next-line no-unused-vars
export function aaaModule({ app, router, store, eventBus }) {
   
	router.addRoute('Home', { 
		path: 'aaa', 
		name:'aaa', 
		component: () => import("@/components/ParentNode"), 
		
	})

    router.addRoute('aaa', { path: 'groups', redirect: to => {return to.path + '/list'} })
	router.addRoute('aaa', { path: 'groups/list', component: Groups })
	router.addRoute('aaa', { path: 'groups/edit/:id?', component: GroupEdit, props: true })

    router.addRoute('aaa', { path: 'users', redirect: to => {return to.path + '/list'} })
	router.addRoute('aaa', { path: 'users/list', component: Users })
	router.addRoute('aaa', { path: 'users/edit/:id?', component: UserEdit, props: true })
	router.addRoute('aaa', { path: 'users/profile/:id', component: Profile, props: true })

    router.addRoute('aaa', { path: 'roles', redirect: to => {return to.path + '/list'} })
	router.addRoute('aaa', { path: 'roles/list', component: Roles })


	store.registerModule('aaa', AaaStore)

}
