<template>

    <div>
        <base-cta-list
            title="QR-kod Innerpåsar"
            subtitle="Här hittar du samtliga batch av innerpåsar"
            :headers="headers"
            storeNamespace="samples"
            listNamespace="A_batch"
            :icon="logo"
            buttonText="Ny batch"
            :editEnabled="false"
        >

            <template v-slot:row.name="{ index }">
                Batch {{index + 1}}
            </template>

            <template v-slot:row.filetype="{index}">
                <v-avatar tile size="46">
                    <button @click="openPrint(index)">
                        <img src="@/assets/images/file-types/001-pdf.svg" alt="" />
                    </button>
                </v-avatar>
            </template>

            <template v-slot:row.created_at="{ entity }">
                {{moment(entity.created_at).format('YYYY-MM-DD HH:mm:ss')}}
            </template>

            <template #dialog-content="{setDialog, dataId}"> 
                <inner-envelope-batch-form
                    :listOnSave="true"
                    @requestIsDone="setDialog(false)"
                    :id="dataId"
                ></inner-envelope-batch-form>
            </template>

        </base-cta-list>
        <div class="hidden">
            <print-qr-template
                :bags="printBags"
                ref="printQrTemplate"
            ></print-qr-template>
        </div>
    </div>

</template>

<script>
import InnerEnvelopeBatchForm from '../components/InnerEnvelopeBatchForm.vue'
import PrintQrTemplate from '@/components/util/PrintQrTemplate'
import {mapActions, mapGetters} from 'vuex'
export default {
    name: "InnerEnvelopeBatch",
    metaInfo: {
        title: 'Inner Envelope Batch'
    },
    components: {
        InnerEnvelopeBatchForm,
        PrintQrTemplate
    },
    data() {
      return {
        printBags: [],
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name',
                slot: true
            },
            {
                text: 'Quantity',
                align: 'start',
                sortable: true,
                value: 'numbers',
            },
            {
                text: 'Created at',
                align: 'start',
                sortable: true,
                slot: true,
                value: 'created_at',
            },
            {
                text: 'Print',
                align: 'center',
                sortable: true,
                value: 'filetype',
                slot: true,
                width: '1'
            },
        ],
        logo: require('@/assets/images/svg/qr-code.svg')
      }
    },
    methods: {
        ...mapActions('samples', ['get_O_batch']),
        
        async openPrint(index) {
            await this.get_O_batch({id: this.A_batch[index].id});
            this.printBags = this.O_batch.bags;
            await this.$nextTick();
            this.$refs.printQrTemplate.print();
        }
    },
    computed: {
        ...mapGetters('samples', ['O_batch', 'A_batch'])
    },
}
</script>

<style lang="scss" scoped>
    .hidden {
        position: absolute;
        left: -9999px;
        top: -9999px;
        display: none;
    }
</style>