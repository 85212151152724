//Store
import servicesStore from './store/services'
import pricepackagesStore from './store/pricepackages'

//Pages
import ServiceGroups from './pages/ServiceGroups'
import ServiceProducts from './pages/ServiceProducts'
import ServiceSettings from './pages/ServiceSettings'
import ServicePricePackageStepper from './pages/Pricepackage/ServicePricePackageStepper'
import ServicePricePackages from './pages/Pricepackage/ServicePricePackages'
import ServicePricePackageCard from './pages/Pricepackage/ServicePricePackageCard'
import ServicePricelistCard from './pages/Pricepackage/ServicePricelistCard'

/*
import PriceperiodStepper from './components/PriceperiodStepper'
*/

// eslint-disable-next-line no-unused-vars
export function serviceModule({ app, router, store, eventBus }) {

	router.addRoute('Home', { 
		path: 'service', 
		name:'service', 
		component: () => import("@/components/ParentNode"), 
		redirect: to => {return to.path + '/list'}
	})



	router.addRoute('service', { path: 'groups', component: ServiceGroups }) //OK!
	router.addRoute('service', { path: 'settings', component: ServiceSettings }) //OK!
    router.addRoute('service', { path: 'products', component: ServiceProducts }) //OK!
    router.addRoute('service', { path: 'pricepackage', component: ServicePricePackages }) //OK!
	router.addRoute('service', { path: 'pricepackagestepper', component: ServicePricePackageStepper })//OK!
	router.addRoute('service', { path: 'pricepackage/:id?', component: ServicePricePackageCard, props:true}) //OK!
	router.addRoute('service', { path: 'pricepackage/pricelist/:id?', component: ServicePricelistCard, props:true}) //OK!

	/*
	
	router.addRoute('service', { path: 'priceperiod/:id?', component: PriceperiodStepper, props:true})
    
	*/





	/**
	 * Register store
	 */
	store.registerModule('services', servicesStore)
	store.registerModule('pricepackages', pricepackagesStore)


}