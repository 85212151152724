<template>
    <base-card
        :boxShadow="boxShadow"
        class=""
    >
        <v-card-text class="pa-7 ">
            <div class="d-flex justify-space-between">
                <div class="pr-4">
                    <h5 class="text-18 ">{{ title }}</h5>
                    <p class="">{{ subTitle }}</p>
                </div>
                <img style="height:96px;width:96px" :src="image" v-if="image" />
            </div>
            <slot name="basic-info-slot"></slot>
        </v-card-text>
      
        <v-card-actions
            class="primary--text grey lighten-5 py-4"
            v-if="link"
            style="cursor:pointer"
        >
   
            <slot name="basic-action-slot"></slot>
        </v-card-actions>
    </base-card>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        link: {
            type: Boolean,
            default: false
        },
        boxShadow: {
            type: Boolean,
            default: true
        }
    }
}
</script>
<style lang="scss" scoped>
    .bt-1 {
        border-top: 1px solid rgba(0,0,0, 0.12)
    }
</style>
