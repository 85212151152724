export default { 

    capFirstLetter(val) {
      return val.charAt(0).toUpperCase() + val.slice(1);
    },
    capAllLetters(string){
        return string.toUpperCase();
    },
    filterKeys(object, ...keys) {
        return keys.reduce((result, key) => ({ ...result, [key]: object[key] }), {});
    },
    filterByKeyArray(object, keysArray) {
        return Object.fromEntries(Object.entries(object).filter(([key]) => keysArray.includes(key)) )
    },
    isObject(obj) {
        return Object.prototype.toString.call(obj) === '[object Object]';
    },
    isObjectEmpty(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    },
    filterUniqueArray(arr) {
        return [...new Map(arr.map(v => [JSON.stringify(v), v])).values()]
    },
    groupByKey(array, flag) {
        return array.reduce((acc, obj) => {
            const key = obj[flag];
            if(!acc[key]) {
              acc[key] = []
            }
            acc[key].push(obj);
            return acc
        }, {})
    },
    removeDuplicates(array, key) {
       
        let flag = {};
        let unique = [];
        if(array.length > 0) {
            array.forEach(elem => {
                if(!flag[elem[key]] && elem[key] !== undefined) {
                    flag[elem[key]] = true;
                    unique.push(elem[key]);
                }
            });
        }
    
        return unique
    },
    replaceStrByObject(string, replacer, splitAt, delimiter) {
 
        const pieces = string.split(splitAt);
        
        const arr = pieces.map((i) => {
            if(i.charAt(0) === delimiter) {
                const str = i.substring(3).toLowerCase();
                if(str in replacer) {
                    i = replacer[str]
                }
            }
           
            return i;
        })

        return arr.join(splitAt)
    },
    schemaParser(filter, object = {}, filterOutConditions = false) {
        

        let obj = []
        for(const key in filter) {
            
         
            /*
            let jsonStr = filter[key].replace(/(\w+:(?<!^))|(\w+ :(?<!^))/g, function(s) {
                return '"' + s.substring(0, s.length-1) + '":';
            });
            */
            let jsonStr = filter[key].replace(/(\w+:)|(\w+ :)/g, function(s) {
                return '"' + s.substring(0, s.length-1) + '":';
            });

            
            jsonStr = jsonStr.replaceAll('^', '');
         
            const append = JSON.parse(jsonStr);
            
            if("content" in append) {
                obj.push(append)
            }
            else if(key in object) {
                obj.push(
                    {
                        field: key, 
                        content: object[key], 
                        ...append
                    }
                );
            }
            else {
                obj.push(
                    {
                        field: key,
                        ...append
                    }
                );
            }
          
   
        }


        if(filterOutConditions) {
            obj = this.conditionalParser(obj, object);
        }


        return obj;

    },
    conditionalParser(inputSchema, object) {
  
        //Conditional rendering
        let schema = inputSchema.filter(o => {

            if(!o.conditions) {
                return true
            }
            
            let arr = o.conditions.split(".");
            let values = arr[1].split("|");

            if(values.includes(object[arr[0]])) {
                return true
            }
            else {
                return false;
            }
        });

        schema.sort((a,b) => {
            return (a.section - b.section) || (a.order - b.order);
        })
        
        return schema;
    },
    filterAndAssign(object, filter) {
   
        let obj = []
        for(const key in filter) {
            

            const append = filter[key]
            .map(x => {
                return [
                    x.substr(0,x.indexOf(':')).trim(),
                    x.substr(x.indexOf(':')+1).trim()
                ]
            })
            .reduce((a, x) => {

                if(x[1].startsWith("[") && x[1].endsWith("]")) {
                    const items = x[1].slice(1,-1);
                    a[x[0]] = items.split(",");
                }
                else {
                    a[x[0]] = x[1];
                }
                
                return a;
            }, {});   
       
  
            if("content" in append) {
                obj.push(append)
            }
            else if(key in object) {
                obj.push(
                    {
                        field: key, 
                        content: object[key], 
                        ...append
                    }
                );
            }
            else {
                obj.push(
                    {
                        field: key,
                        ...append
                    }
                );
            }
   
        }
        return obj;
    },
    cleanForm(schema, form, action = "") {
  
        const prepare = {
            pKeys: [],
            pForm: {...form}
        }

        const result = schema.reduce((res, o) => {
            if(!("exclude" in o)) {
                res["pKeys"].push(o.field)
            }
            else {
                if(!(o["exclude"].includes(action))) {
                    res["pKeys"].push(o.field)
                }
            }
            //If object is passed strip out id to save
            if(o.type === 'selectData' && res["pForm"][o.field]) {
                if(this.isObject(res["pForm"][o.field]) && res["pForm"][o.field].id) {
                    res["pForm"][o.field] = res["pForm"][o.field].id;
                }
            }
            if(o.type === 'arrayInput' && res["pForm"][o.field]) {
                res["pForm"][o.field] = res["pForm"][o.field].flat().map(obj => {
                    return this.cleanForm(o.schema, obj);
                });
            }
            if(o.type === 'arrayData' && res["pForm"][o.field]) {

                //Strip out id if nested
                res["pForm"][o.field] = res["pForm"][o.field].flat().map(obj => {
                   return Object.keys(obj).reduce((acc, key) => {
                       if(this.isObject(obj[key])) {
                           acc[key] = obj[key].id; //TODO Check against schema which value
                       }
                       else {
                           acc[key] = obj[key]
                       }
                       return acc; 
                    }, {})
                })
            

                res["pForm"][o.field] = this.filterUniqueArray(res["pForm"][o.field])
            }

            return res;
        }, prepare);

        const {pForm, pKeys} = result

        return this.filterByKeyArray(pForm, pKeys);

    },
    timeout(interval) {
        
        return new Promise((resolve) => {
            setTimeout(function(){

            resolve("done");
            }, interval);
        });
   
    }
};

export function mapFields(options) {
    const object = {};

    for (let x = 0; x < options.fields.length; x++) {
      const field = [options.fields[x]];
      object[field] = {
        get() {
          return this.$store.state[options.base][options.object][field] ? this.$store.state[options.base][options.object][field] : "";
        },
        set(value) {
          this.$store.commit(options.base+ "/" + options.mutation, { [field]: value });
        }
      };
    }
    
    return object;
  }  