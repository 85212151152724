<template>
    
    <edit-form
        :title="title"
        :subTitle="subTitle"
        :schema="schema"
        storeNamespace="clients"
        objectNamespace="O_client"
        :objectId="id"
        :listOnSave="listOnSave"
    >

    </edit-form>

</template>

<script>
import EditForm from '@/components/form/EditForm'

export default {
    name: "ClientForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
    },
    data() {
      return {

      }
    },
    computed: {
        schema() {
            
            const filter = {
              type: '{ type:"buttons", rules:"required", buttonStyle:"compact", items:[{value: "person", icon:"mdi-account-plus", caption:"This is caption text"}, {value: "organisation", icon:"mdi-domain", caption:"This is caption text"}] }',
              name_first: '{type:"input", rules:"required", conditions:"type.person"}',
              name_last: '{type:"input", rules:"required", conditions:"type.person"}',
              name_organisation: '{type:"input", rules:"required", conditions:"type.organisation"}',
              authority_id: '{type:"input", rules:"required", conditions:"type.organisation|person"}',
              vat_id: '{type:"input", rules:"required", conditions:"type.organisation"}',
              //id_group: '{type:"hiddenInput",  rules:"required", exclude:["update"], conditions:"type.organisation|person", storeNamespace: "profile", objectNamespace: "dashboardView", keyName: "id"}'
            }
            
            return this.$helpers.schemaParser(filter)

        },
        title() {
            return this.id ? "Uppdatera kund" : "Ny kund";
        },
        subTitle() {
            return this.id ? "Här kan du redigera en kund" : "Här fyller du i kundens uppgifter";
        },
        
    },
}
</script>

<style>

</style>