<template>
    <div>
        
        <div class="d-flex align-center justify-space-between mt-10 mb-5">
            <button
                @click="focusQrInput" 
                :class="[isQrFocused ? 'green' : 'red', 'flex-grow-1 rounded-pill text-center text-white py-2 subtitle-1']"
            >
                {{isQrFocused ? 'Scanner is on': 'Scanner is off'}}
            </button>
            
        </div>


        <input 
            type="text" 
            id="qrcode"
            ref="qrInput"
            class="qr-input"
            v-model="qrCode"
            v-focus="isQrFocused"
            @focus="isQrFocused = true" 
            @blur="isQrFocused = false"
            @keyup.enter="onQrCode()" 
            name="qrcode"
        />
        <slot :isQrFocused="isQrFocused"></slot>

    </div>
</template>

<script>

import { focus } from 'vue-focus';




export default {
    name: 'ZebraQrScanner',
    directives: { focus: focus },

    data() {
        return {

            isQrFocused: false,
            qrCode: "",
        }
    },

    methods: {
        focusQrInput() {
            this.$refs.qrInput.focus();
        },

        onQrCode() {
      
            try {
                const value = JSON.parse(this.qrCode);
                this.$emit('code', value);

            } catch (error) {
                //TELL NOT VALID JSON
            }
            this.qrCode = "";
        }
    },
    mounted() {
        this.focusQrInput();
    }
}
</script>

<style lang="scss" scoped>
.qr-input {
  
    position: absolute;
    left: -9999px;
    top: -9999px;

}
</style>