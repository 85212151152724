<template>

  <base-tab-card
    :tabs="tabs"
    title="Ekonomi"
    @visitedTabs="visitedTabs"
  >
  </base-tab-card>


</template>

<script>
/* eslint-disable vue/no-unused-components */
import Billing from '../components/Economy/Billing.vue'
import BillingTerms from '../components/Economy/BillingTerms.vue'

export default {
    components: {
        Billing,
        BillingTerms
    },
    props: {

    },
    data() {
        return {
            tabsVisited: []
        }
    },
    computed: {
        tabs() {
            return [
                {
                    name: 'Metoder', 
                    icon: 'mdi-email-fast',
                    component: Billing
                },
                {
                    name: 'Vilkor', 
                    icon: 'mdi-file-sign',
                    component: BillingTerms
                }
            ]
        }
    },
    methods: {
        visitedTabs(value) {
            this.tabsVisited = value
        }
    },
}
</script>

<style>

</style>