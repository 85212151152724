import Vue from 'vue';
import Vuetify, {
    VRow,
    VCol,
    VTextField,
    VTooltip,
    VCheckbox,
    VSelect,
} from 'vuetify/lib';
import { Ripple, Intersect, Touch, Resize, ClickOutside } from 'vuetify/lib/directives';
import { themePreset } from "../../src/themeConfig";

Vue.use(Vuetify, {
    components: { VRow, VTooltip, VCol, VTextField, VCheckbox, VSelect },
    directives: { Ripple, Intersect, Touch, Resize, ClickOutside },
});

export default new Vuetify({
	theme: themePreset.theme,
});
