<template>
    <div>
        <v-row>
        
            <v-col cols="12" class="text-center">
                <h2>{{ dashboardView.client_name }}</h2>
                <p>
                    Här kan du hantera din organisation, se användare och tidigare inlämnade prover. <br>I framtiden kommer du också kunna se statistik på denna startsida.
                </p>
            </v-col>
            <v-col cols="12" md="4">
                <TestCard mainIconName="mdi-account-multiple" mainIconBackgroundColorClass="primary" bottomButtonLinkTo="aaa/users/list" mainIconTextColor="white--text" headingText="Användare" subHeadingText="Inom organisationen" bottomButtonText="Visa"></TestCard>
            </v-col>
            <v-col cols="12" md="4">
                <TestCard mainIconName="mdi-test-tube" headingText="Prover" bottomButtonLinkTo="sample/result" mainIconBackgroundColorClass="primary" mainIconTextColor="white--text" subHeadingText="Inom organisationen" bottomButtonText="Visa"></TestCard>
            </v-col>
            <v-col cols="12" md="4">
                <TestCard mainIconName="mdi-account" headingText="Min profil" bottomButtonLinkTo="profile" mainIconBackgroundColorClass="primary" mainIconTextColor="white--text" subHeadingText="Dina uppgifter" bottomButtonText="Visa"></TestCard>
            </v-col>
    
    
    </v-row>
    </div>
</template>

<script>


import {mapGetters} from 'vuex'
import TestCard from '@/components/card/AnalyticCardVersionTwo.vue';
export default {
    name: 'CustomerDashboard',
    components: {
        TestCard
    },
    computed: {
       ...mapGetters('profile', ['dashboardView']),
    },
    methods: {
     
    },
    created () {

    }
}
</script>

<style lang="scss" scoped>

</style>