<template>
<v-row justify="center" v-if="userProfile">
        <v-col cols="10" md="10" class="text-center">
            <h3 class="mb-4">Personlig information</h3>
            <p>
                Grundläggande information, som namn och e-postadress, som du använder på Labflow
                services 
            </p>
        </v-col>

        <v-col cols="10" md="10">
            <expansion-card
            title="Profil"
            sub-title="En del information kan vara synlig för administratörer inom Labflow services"
            :list="personalInfo"
            @inputBlur="blurEvent"
            >
            </expansion-card>
        </v-col>

        <v-col cols="10" md="10">
            <expansion-card
            title="Konto information"
            :list="accountInfo"
            @inputBlur="blurEvent"
            :link="true"
            >
                <template v-slot:expansionActionSlot>
                    <base-hover-button
                        @click.native="changeActiveOrganization"
                        text="Ändra lösenord"
                        text-color="primary--text"
                        icon-name="mdi-form-textbox-password"
                        :noBg="true"
                        :to="{path: '/forgot'}"
                        shadow=""
                    />
                </template>
            </expansion-card>
        </v-col>

        <v-col cols="10" md="10">
            <expansion-card
            title="Behörigheter"
            sub-title="Här ser du dina behörigheter inom Labflow services"
            :list="groups"
            :image="imgTwo"
            :link="true"
            >
                <template v-slot:expansionLabelSlot>
                    Organisation
                </template>

                <template v-slot:expansionContentSlot="{item}">
                    
                    <v-icon color="primary" v-if="item[0].client_id == organization" class="mr-2">mdi-checkbox-marked-circle</v-icon>
                    {{item[0].client_name}}
                </template>

                <template v-slot:expansionPanelContentSlot="{ item }">
                   <v-chip 
                        class="my-2 mr-2"
                        v-for="role in item" :key="role.id"
                        :class="(role.client_id == organization && role.id == dashboardView.id) ? 'primary' : 'default'" 
                    >
                        {{ role.name }}
                    </v-chip>
                </template> 

                <template v-slot:expansionActionSlot>
                    <base-hover-button
                        @click.native="changeActiveOrganization"
                        text="Ändra aktiv organisation"
                        text-color="primary--text"
                        icon-name="mdi-logout"
                        :noBg="true"
                        shadow=""
                    />
                </template>

            </expansion-card>
        </v-col>
    </v-row>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ExpansionCard from '@/components/card/ExpansionCard'
import eventBus from '@/plugins/eventbus.js'

export default {
    name: 'Profile',
    components: {
        ExpansionCard
    },
    metaInfo: {
        title: 'Profile Page'
    },
    data() {
        return {
            imgTwo: require('@/assets/images/svg/login.svg'),  
        }
    },
    methods: {
        ...mapActions('profile', ['updateUser']),
        changeActiveOrganization() {
            eventBus.$emit.organizationPicker();
        },
        async blurEvent(data) {
            let obj = {...this.userData, ...{[data.fieldName]: data.value}}
            this.updateUser(obj);
        },
    },
    computed: {
        ...mapGetters('profile', ['userProfile', 'organization', 'dashboardView', 'userProfileExist']),
        userData() {
            return this.$helpers.filterKeys(this.userProfile, 'name_first', 'name_last', 'email')
        },
        personalInfo() {
            
            const filter = {
                name_first: ['type:input', 'rules:required'],
                name_last: ["type:input", 'rules:required']
            }
            
            return this.$helpers.filterAndAssign(this.userData, filter)

        },
        accountInfo() {
    
            const filter = {
                email: ['type:input', 'rules:required|email'],
                password: [
                    'icon: mdi-lock', 
                    'content:', 
                    'label: password', 
                    'panelContent: Password is hidden for security reasons'
                ]
            }

        
            return this.$helpers.filterAndAssign(this.userData, filter)
        },
        groups() {

            if(this.userProfileExist && this.userProfile.groups) {
                const groups = this.userProfile.groups.filter(o => o.type === "group");
                const filtered = this.$helpers.groupByKey(groups, 'client_id');

                return filtered
            }
            else {
                return false;
            }

        }

    },
    created() {
     
    }

}
</script>