import Profile from './pages/Profile.vue'
import ProfileStore from './store/profile.js'

import OrganizationPicker from './components/OrganizationPicker.vue'

// eslint-disable-next-line no-unused-vars
export function profileModule({ app, router, store, eventBus }) {

	router.addRoute('Home', { 
		path: 'profile', 
		name:'profile', 
		component: () => import("@/components/ParentNode"), 
		redirect: to => {return to.path + '/user'}
	})

	router.addRoute('profile', { path: 'user', component: Profile })


	/**
	 * Register store
	 */
	store.registerModule('profile', ProfileStore)


	/**
	 * Register global components
	 */
	app.component('organizationPicker', OrganizationPicker);


	/**
	 * Register events
	 */
	eventBus.$on.logOut(function() {
		store.dispatch('profile/clearAll');
	})

}





