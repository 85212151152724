<template>

    <base-cta-list
        title="Test"
        subtitle=""
        :headers="headers"
        storeNamespace="devtest"
        listNamespace="A_site"
        :icon="userLogo"
        buttonText="New User"
    >

         <template #dialog-content="{setDialog}"> 
             <stepper-test
                @stepper-done="setDialog(false)"
             ></stepper-test>
         </template>

    </base-cta-list>

</template>

<script>
import StepperTest from './StepperTest.vue'


export default {
    components: { StepperTest },
    data() {
      return {
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: false,
                value: 'name',
                width: '30%'
            },
            {
                text: 'Grupper', 
                value: 'groups',
                slot: true,
            }
        ],
        userLogo: require('@/assets/images/svg/man.svg')
      }
    }
}
</script>

<style>

</style>