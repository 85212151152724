<template>
    <v-select 
        :placeholder="'Select ' + label"
        :error-messages="errors"
        :label="label"
        :value="value"
        :items="items"
        :multiple="multiple"
        v-on="$listeners"
        outlined
        clearable
    >
        <template v-slot:selection="{ item }">
            <span v-if="$te('fields.' + `${item.text}`)">{{ $helpers.capFirstLetter($t('fields.' + `${item.text}`)) }}</span>
            <span v-else>{{item.text ? item.text : item }}</span>
        </template>
        <template v-slot:item="{ item }">
            <span v-if="$te('fields.' + `${item.text}`)">{{ $helpers.capFirstLetter($t('fields.' + `${item.text}`)) }}</span>
            <span v-else>{{item.text ? item.text : item }}</span>
        </template>
    </v-select>
            
</template>

<script>

export default {
    name: "SelectSimple",
    props: {
        items: {
            type: Array,
            default: () => {
                return []
            }
        },
        label: {
            type: String,
            default: ""
        },
        errors: {
            type:[String, Array],
            default: function() {
                return []
            }
        },
        value: {
            type:[String, Array, Object, Boolean, Number],
            default: ""
        },
        multiple: {
            type: Boolean,
            default: false
        },
        onResetForm: {
            type: Number,
            default: 0
        },
        setDefaultValue: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            initialValue: false,
        }
    },
    watch: {
        onResetForm: {
            handler() {
                this.$emit('input', this.initialValue);
            }
        }
    },
    mounted () {
        if(this.items.length >= 1 && !this.value && this.setDefaultValue) {
            if(this.items[0].value) {
                this.initialValue = this.items[0].value
                this.$emit('input', this.items[0].value);    
            }
            else {
                this.initialValue = this.items[0]
                this.$emit('input', this.items[0]);    
            }

        }
    }
}
</script>

<style>

</style>