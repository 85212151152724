<template>
    <v-row>
        <v-col cols="12">
            <simple-card>

                <v-card-title>
                    {{title}}
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="list"
                    :options.sync="options"
                    :footer-props="{
                        itemsPerPageOptions:[10,20,50,100]
                    }"
                    item-key="id"
                    show-select
                    class="table-one"
                    multi-sort
                >


                    <template 
                        v-for="slotValue in slotValues" 
                        v-slot:[`item.${slotValue.value}`]="{ item }">
                        <div :key="slotValue.value">
                            <slot :name="[`row.${slotValue.value}`]" :entity="item" :index="list.indexOf(item)"></slot>
                        </div>
                    </template>

                </v-data-table>
         
            </simple-card>
        </v-col>
    </v-row>
</template>
<script>

import SimpleCard from '@/components/card/SimpleCard'

export default {
    name:"DataTable",
    props: {
        title: {
            type: String,
            default: 'Table'
        },
        headers: {
            type: Array,
            default: () => ([])
        },
        list: {
            type: Array,
            default: () => ([])
        },
    },
    components: {
        SimpleCard,
    },
    data() {
        return {
            search: '',
            selected: [],
            options: {},
        }
    },
    methods: {

    },
    computed: {
        slotValues() {
            return this.headers.filter(o => (o.slot === true)); 
        },
    },
    created() {
    }
}
</script>
<style lang="scss">
    
</style>