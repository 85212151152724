export const priceperiodSchema = {
    price_normal: '{ type:"input", rules:"required|numeric|min_value^:1", inputType:"number" }',
    price_reduced_amount: '{ type:"input", rules:"required|numeric|min_value^:0", inputType:"number" }',
    price_reduced_type: '{type:"select", items: ["fixed", "discount"], rules:"required"}',
    price_vat: '{type:"select", items: ["25"], rules:"required"}',
    price_currency: '{type:"select", items: ["SEK"], rules:"required"}',
    date_valid_from: '{type:"datePicker"}',
    date_valid_to: '{type:"datePicker"}',
    auto_extend_mode: '{type:"select", items: ["auto", "manual"], rules:"required"}', 
    auto_extend_periods: '{ type:"input", rules:"required|numeric|min_value^:0", inputType:"number" }',
    //end_previous: '{type:"switch"}'
}