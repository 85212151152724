import eventBus from '@/plugins/eventbus.js'

function successResponseHandler(response) {
    
    if(response.config.method === 'put' && (response.status === 200 || response.status === 204)) {
        eventBus.$emit.snackMsg({
            type: 'success',
            text: 'Uppdaterad!'
        });
    }
    else if(response.config.method === 'post' && response.status === 201) {
        eventBus.$emit.snackMsg({
            type: 'success',
            text: 'Skapad!'
        });
    }


    return Promise.resolve(response);
}


export default successResponseHandler;