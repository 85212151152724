<template>
    <expansion-update-form
        :schema="siteSchema"
        :id="id"
        :initialLoad="initialLoad"
        storeNamespace="settings"
        objectNamespace="O_site"
    ></expansion-update-form>
</template>

<script>
import ExpansionUpdateForm from '@/components/form/ExpansionUpdateForm'
import {siteSchema} from '../schema/siteSchema.js'

export default {
    name: "SiteExpansionUpdate",
    components: {
        ExpansionUpdateForm
    },
    props: {
        id: {
            type: String,
        },
        initialLoad: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            siteSchema
        }
    },
}
</script>