<template>
  <div>

      <base-stepper
        title="New pricepackage"
        subtitle="Here you can create a new pricepackage"
        v-slot="{changeStep}"
        :numOfSteps="3"
        :initialStep="1"
      >

        <v-stepper-items>
          
          <step-form-content
            title="Pricepackage details"
            subTitle="Please enter the details for the new pricepackage"
            :schema="schemaStep1"
            storeNamespace="pricepackages"
            objectNamespace="O_pricepackage"
            :step="1"
            :saveOnContinue="true"
            :clearStoreOnCreate="true"
            @step-change="changeStep"
          ></step-form-content>

          <step-form-content
            title="Pricelist details"
            subTitle="Please enter the details for the new pricelist"
            :schema="schemaStep2"
            storeNamespace="services"
            objectNamespace="O_pricelist"
            :step="2"
            :saveOnContinue="true"
            :clearStoreOnCreate="true"
            @step-change="changeStep"
          ></step-form-content>

          <v-stepper-content class="pa-0" 
              :step="3"
          >
            <v-lazy>
              <priceperiod-stepper
                @stepper-done="onDone"
              ></priceperiod-stepper>
            </v-lazy>
          </v-stepper-content>

        </v-stepper-items>
      </base-stepper>

  </div>
</template>

<script>
import StepFormContent from '@/components/form/StepFormContent'
import PriceperiodStepper from '@/modules/Service/components/Pricepackage/PriceperiodStepper.vue'
import {pricelistSchema} from '@/modules/Service/schema/pricelistSchema.js'
import {pricePackageSchema} from '@/modules/Service/schema/pricePackageSchema.js'
export default {
    name: "ServicePricePackageStepper",
    components: {
      StepFormContent,
      PriceperiodStepper
    },
    methods: {
      onDone() {
        this.$router.push('/service/pricepackage')
      }
    },
    computed: {
        schemaStep1() {
            return this.$helpers.schemaParser(pricePackageSchema)
        },
        schemaStep2() {
          return this.$helpers.schemaParser(pricelistSchema)
        },

    }
}
</script>

<style>

</style>