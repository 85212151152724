<template>

    <v-data-table
        :headers="headers"
        :items="list"
        :footer-props="{
            itemsPerPageOptions:[10,20,50,100]
        }"
        item-key="id"
        class="table-one"
        multi-sort
    >


        <template 
            v-for="slotValue in slotValues" 
            v-slot:[`item.${slotValue.value}`]="{ item }">
            <div :key="slotValue.value">
                <slot :name="[`row.${slotValue.value}`]" :entity="item" :index="list.indexOf(item)"></slot>
            </div>
        </template>


        <template v-slot:item.action="{item}">
            <div class="d-flex">
                <v-tooltip top>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn
                            color="success"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="editItem(item)"
                            icon
                        >
                            <v-icon>mdi-pencil-box-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Edit</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn
                            color="danger"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="deleteItem(item)"
                        >
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Delete</span>
                </v-tooltip>
            </div>
        </template>
    </v-data-table>


</template>
<script>

import {mapGetters} from 'vuex'

export default {
    name:"ActionTable",
    props: {
        title: {
            type: String,
            default: 'Table'
        },
        headers: {
            type: Array,
            default: () => ([])
        },
        storeNamespace: {
            type:String,
            default: ''
        },
        objectNamespace: {
            type:String,
            default: ''
        },
        keyName: {
            type:String,
            default: ''
        },
        changePathOnEdit: {
            type:Boolean,
            default: true
        },
        editPath: {
            type:String,
            default: 'edit'
        }
    },
    beforeCreate() {
        let storeNamespace = this.$options.propsData.storeNamespace;
        let objectNamespace = this.$options.propsData.objectNamespace;
  
        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters(storeNamespace, [objectNamespace]),
        }
    },
    data() {
        return {
            search: '',
            options: {},
            loading: false
        }
    },
    methods: {
        editItem(item) {
            if(this.changePathOnEdit) {
                const path = this.$route.path.substring(0, this.$route.path.lastIndexOf('/')) 
                this.$router.push({ path: path + `/${this.editPath}/${item.id}` }) 
            }
            else {
        
                this.$emit('onEdit', item);
            }

            
        },
        deleteItem(item) {
            console.log("🚀 ~ file: ActionTable.vue ~ line 98 ~ delete ~ item", item)
        }
    },
    computed: {
     
        slotValues() {
            return this.headers.filter(o => (o.slot === true)); 
        },
        list() {
            return this[this.objectNamespace][this.keyName];
        },
    },


}
</script>
<style lang="scss">
    
</style>