<template>
    <div>
        <v-row 
            justify="center"
            class="mb-4"
        
        >
            <v-col 
                cols="12"
            >
                <div class="d-flex flex-column justify-space-between align-end align-sm-center flex-sm-row">
                    <div class="text-left d-flex align-center">
                        <v-avatar
                            color="secondary"
                            size="72"
                            class="mr-4"
                            v-if="icon"
                        >
                            <v-icon
                                dark
                                x-large
                            >
                                {{icon}}
                            </v-icon>
                        </v-avatar>
                        <div v-if="!hideTitle">
                            <h3 class="mb-0">{{title}}</h3>
                            <p class="mb-0">
                            {{subtitle}}
                            </p>
                        </div>
                    </div>
                    <div
                        class="mt-7 mt-sm-0"
                    >   
                        <v-btn 
                            color="primary" 
                            large
                            :to="{path: buttonLink}"
                            v-if="buttonLink"
                        >
                            <v-icon
                                class="mr-1"
                                small
                            >
                                mdi-plus
                            </v-icon>
                            {{buttonText}}
                        </v-btn>
                        
                        <v-btn 
                            color="primary" 
                            large
                           
                            @click="setDialog(true)" 
                            v-else
                        >
                            <v-icon
                                class="mr-1"
                                small
                            >
                                mdi-plus
                            </v-icon>
                            {{buttonText}}
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <base-action-table
            :title="title"
            :headers="headers"
            :storeNamespace="storeNamespace"
            :listNamespace="listNamespace"
            :changePathOnEdit="changePathOnEdit"
            :editPath="editPath"
            @onEdit="edit"
        >
            <template v-for="(_, scopedSlotName) in scopedSlots" v-slot:[scopedSlotName]="slotData">
                <slot :name="scopedSlotName" v-bind="slotData" />
            </template>

        </base-action-table>

        <v-dialog
            v-model="dialog"
            max-width="600px"
        >
            <slot
                name="dialog-content"
                v-bind:setDialog="setDialog"
                v-bind:dataId="dataId"
                v-if="dialog"
            >
            </slot>

        </v-dialog>
    </div>
</template>

<script>

export default {
    name: "CtaTable",
    props: {
        title: {
            type: String,
            default: 'Table'
        },
        subtitle: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        buttonText: {
            type: String,
            default: 'New'
        },
        headers: {
            type: Array,
            default: () => ([])
        },
        storeNamespace: {
            type:String,
            default: ''
        },
        listNamespace: {
            type:String,
            default: ''
        },
        changePathOnEdit: {
            type:Boolean,
            default: false
        },
        buttonLink: {
            type:String,
            default: ''
        },
        editPath: {
            type:String,
            default: 'edit'
        },
        hideTitle: {
            type:Boolean,
            default: false
        }

    },
    data() {
        return {
            dialog: false,
            dataId: "",
        }
    },
    methods: {
        setDialog(value) {
            this.dialog = value;
        },
        async edit(item) {
            this.dataId = item.id;
          
            this.setDialog(true)
        }
    },
    computed: {
        scopedSlots() {

            return Object.fromEntries(
                Object.entries(this.$scopedSlots).filter(([key]) => key != 'dialog-content')
            )
        }
    },
    watch: {
        dialog: function(val) {
            //Remove dataId will trigger clear store
            if(val === false) {
                this.dataId = "";
            }
        }
    }
}
</script>

<style>

</style>