import { extend } from 'vee-validate';
import { required, email, numeric, min_value } from 'vee-validate/dist/rules';

// No message specified.
extend('email', email);
extend('min_value', min_value);

// Override the default message.
extend('required', {
  ...required,
  message: 'This field is required'
});

extend('numeric', {
  ...numeric,
  message: 'Only numbers are allowed'
});
