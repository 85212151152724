<template>
    <div>

        <v-row justify="space-around">
            <v-col
                cols="12"
            >
                <v-chip-group
                    mandatory
                    v-model="selectedSite"
                    active-class="primary--text"
                >
                    <v-chip
                        v-for="site in A_site"
                        :key="site.id"
                    >
                        {{ site.name }}
                    </v-chip>
                </v-chip-group>

            </v-col>
        </v-row>




        <izy-card
            :title="site ? 'Labbköer ' + site.name : ''"
        >   
            <div class="mt-2">
            <apexchart
                type="bar"
                height="350"
                width="100%"
                :options="chartOptions"
                :series="series"
            />
            </div>
        </izy-card>


        <div v-for="queue in queues" :key="queue.id">

            <izy-card
                class="mt-8"
                :title="queue.name" 
                v-if="queue.inner_bags && queue.inner_bags.length > 0"
            >


                    <v-data-table
                        :items="queue.inner_bags"
                        :headers="headers"
                        :single-expand="true"
                        show-expand
                        @click:row="showRow"
                        item-key="id"
                        class="style-1"
                    >

                        <template
                            v-slot:item.client="{ item }"
                        >
                            {{ item.client.name }}
                        </template>

                        <template
                            v-slot:item.received_at="{ item }"
                        >
                            {{ moment(item.received_at).format('YYYY-MM-DD HH:mm:ss') }}
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <div class="row">
                                    <div class="col-4">
                                        <p class="mb-0 font-weight-bold grey--text darken-2">Inlämnad av</p> 
                                        {{ item.registerd_by.name }}
                                    </div>
                                    <div class="col-4">
                                        <p class="mb-0 font-weight-bold grey--text darken-2">Provnamn</p> 
                                        {{ item.name }}
                                    </div>
                                    <div class="col-4">
                                        <p class="mb-0 font-weight-bold grey--text darken-2">Rum</p> 
                                        {{ item.room }}
                                    </div>
                                    <div class="col-4">
                                        <p class="mb-0 font-weight-bold grey--text darken-2">Material</p> 
                                        {{ item.material }}
                                    </div>
                                    <div class="col-4">
                                        <p class="mb-0 font-weight-bold grey--text darken-2">Övrigt</p> 
                                        {{ item.description ? item.description : '-' }}
                                    </div>
                                </div>
                                
                            </td>
                        </template>


                    </v-data-table>

            </izy-card>

        </div>

    </div>

</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import IzyCard from '@/components/card/IzyCard'
export default {
    name: "LabQueues",
    metaInfo: {
        title: 'Lab Queues'
    },
    components: {
        'apexchart': VueApexCharts,
        IzyCard
    },
    data() {
      return {
          loading: false,
          selectedSite: '',
          expanded: [],
          chartOptions: {
            chart: {
              stacked: true,
              toolbar: {
                show: true
              },
              zoom: {
                enabled: true
              }
            },
            xaxis: {
                type: 'category'
            },
          },
         headers: [
            {
                text: 'Client',
                align: 'start',
                sortable: true,
                value: 'client'
            },
            {
                text: 'Recevied at',
                align: 'start',
                sortable: true,
                value: 'received_at'
            },
            { 
                text: '', 
                value: 'data-table-expand' 
            },
        ],
      }
    },
    methods: {
        ...mapActions('lab', ['list_A_site']),
        async getData() {
            this.loading = true;
            await this.list_A_site();
            this.loading = false;
        },
        showRow(item, event) {
            event.expand()

        },
    },
    computed: {
        ...mapGetters('lab', ['A_site']),
        site() {
            return this.A_site[this.selectedSite]
        },
        queues() {
            return this.site && this.site['queue'] ? this.site.queue : []
        },
        /*
        series() {
            let dataTemp = this.queue.map(e => {
                return {x: e.name, y: 0}
            })
            
            return this.queue.reduce((a, el) => {
                let innerarr = [];
                if(el.products) {
                    innerarr = el.products.map(prod => {
                        return {
                            name: prod.name,
                            data: dataTemp.map(obj => obj.x !== el.name ? obj :  {x: el.name, y: prod.bags.length})
                        };
                    })
                }
                return [...a, ...innerarr]
            },[])
        }
        */
       series() {
            let dataTemp = this.queues.map(e => {
                return {x: e.name, y: 0}
            })

            return this.queues.map(el => {
                return {
                    name: el.name_full,
                    data: dataTemp.map(obj => obj.x !== el.name ? obj :  {x: el.name, y: el.inner_bags.length}),
                    color: el.color
                };
            })
       }
    },
    created () {
        this.getData();
    },

}
</script>

<style scoped>
    * >>>.style-1:hover {
        cursor: pointer !important;
    }
</style>
