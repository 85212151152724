var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-cta-list',{attrs:{"title":"QR-koder Emballagepåsar","subtitle":"Här hittar du kundens alla batcher av emaballagepåsasr","headers":_vm.headers,"storeNamespace":"clients","listNamespace":"A_batch","buttonText":"Nytt emballage","inlineMode":true,"editEnabled":false},scopedSlots:_vm._u([{key:"row.name",fn:function(ref){
var index = ref.index;
return [_vm._v(" Batch "+_vm._s(index + 1)+" ")]}},{key:"row.created_at",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" "+_vm._s(_vm.moment(entity.created_at).format('YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"row.filetype",fn:function(ref){
var index = ref.index;
return [_c('v-avatar',{attrs:{"tile":"","size":"46"}},[_c('button',{on:{"click":function($event){return _vm.openPrint(index)}}},[_c('img',{attrs:{"src":require("@/assets/images/file-types/001-pdf.svg"),"alt":""}})])])]}},{key:"dialog-content",fn:function(ref){
var setDialog = ref.setDialog;
var dataId = ref.dataId;
return [_c('client-envelope-form',{attrs:{"listOnSave":true,"id":dataId},on:{"requestIsDone":function($event){return setDialog(false)}}})]}}])}),_c('div',{staticClass:"hidden"},[_c('print-qr-template',{ref:"printQrTemplate",attrs:{"bags":_vm.printBags}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }