<template>


    <base-cta-list
        title="Betalning"
        subtitle="Här hittar du tillgängiga betalningsmetoder"
        :headers="headers"
        storeNamespace="economy"
        listNamespace="A_method"
        :icon="logo"
        buttonText="Ny betalningsmetod"
        :inlineMode="true"
        
    >

         <template #dialog-content="{setDialog, dataId}"> 
             <billing-method-form
                :listOnSave="true"
                @requestIsDone="setDialog(false)"
                :id="dataId"
             ></billing-method-form>
         </template>

    </base-cta-list>



</template>

<script>
import BillingMethodForm from './BillingMethodForm'



export default {
    name: "Billing",
    metaInfo: {
        title: 'Billing'
    },
    components: {
        BillingMethodForm
    },
    data() {
      return {
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name'
            }
        ],
        logo: require('@/assets/images/svg/skycraper.svg')
      }
    },
}
</script>

<style>

</style>