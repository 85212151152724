<template>
    <div>
        <div id="printContainer">
            <div class="pContainer">
                <div 
                    class="pCode"
                    v-for="item in bags" :key="item.id"
                >
                    <span>{{description}}</span>
                    <Base64Svg :base64="item.qr"></Base64Svg>
                    <span>{{item.hasOwnProperty('client') ? item.client.client_name : ''}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Base64Svg from './Base64Svg.vue'
export default {
    components: {
        Base64Svg,
    },
    props: {
        description: {
            type: String,
            default: ""
        },
        bags: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    methods: {
        async print () {
            await this.$htmlToPaper('printContainer');
        }
    }
}
</script>

<style lang="scss" scoped>




</style>