import Vue from 'vue'

const eventBus = new Vue()

const EVENT_TYPES = { 
	logIn: 'logIn', 
	logOut: 'logOut',
  doSignOut: 'doSignOut',
	snackMsg: 'snackMsg',
  organizationPicker: 'organizationPicker',
}

export default {
  $emit: {
    logIn: (payload) => eventBus.$emit(EVENT_TYPES.logIn, payload),
    logOut: (payload) => eventBus.$emit(EVENT_TYPES.logOut, payload),
    doSignOut: (payload) => eventBus.$emit(EVENT_TYPES.doSignOut, payload),
    snackMsg: (payload) => eventBus.$emit(EVENT_TYPES.snackMsg, payload),
    organizationPicker: (payload) => eventBus.$emit(EVENT_TYPES.organizationPicker, payload),
  },
  $on: {
    logIn: (callback) => eventBus.$on(EVENT_TYPES.logIn, callback),
    logOut: (callback) => eventBus.$on(EVENT_TYPES.logOut, callback),
    doSignOut: (callback) => eventBus.$on(EVENT_TYPES.doSignOut, callback),
    snackMsg: (callback) => eventBus.$on(EVENT_TYPES.snackMsg, callback),
    organizationPicker: (callback) => eventBus.$on(EVENT_TYPES.organizationPicker, callback),
  }
}