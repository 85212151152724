var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"table-one",attrs:{"headers":_vm.headers,"items":_vm.list,"footer-props":{
        itemsPerPageOptions:[10,20,50,100]
    },"item-key":"id","multi-sort":""},scopedSlots:_vm._u([_vm._l((_vm.slotValues),function(slotValue){return {key:("item." + (slotValue.value)),fn:function(ref){
    var item = ref.item;
return [_c('div',{key:slotValue.value},[_vm._t([("row." + (slotValue.value))],null,{"entity":item,"index":_vm.list.indexOf(item)})],2)]}}}),{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }