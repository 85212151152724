<template>
    <e-info-card
        :title="title"
        :sub-title="subTitle"
        :image="image"
        :link="link"
        :boxShadow="boxShadow"
    >
        <template slot="basic-info-slot">
            <v-expansion-panels class="my-4">
                <v-expansion-panel
                    v-for="(item, i) in list"
                    :key="i"
                    class=""
                    elevation="1"
                >
                    <v-expansion-panel-header
                        class="border-b-2 border-gray-600"
                    >
                        <template v-slot:default="{ open }">
                            <div
                                class="d-flex justify-space-between sm:align-center align-start"
                            >
                                <div
                                    class="md:flex block justify-space-between sm:align-center align-start  flex-1"
                                >
                                    <div
                                        class=" flex-grow-1"
                                        style="flex-basis:136px;"
                                    >
                                        <p
                                            class="text-uppercase caption ma-0 mb-3 mr-4"
                                        >
                                            <slot name="expansionLabelSlot" :item="item"></slot>
                                            <span v-if="item.field">
                                                {{ $t("fields."+`${item.field}`) }} 
                                            </span>
                                            <span v-else>
                                                {{ item.label }}
                                            </span>
                                        </p>
                                                        
                                
                                    </div>
                                    <div
                                        class="flex-grow-1 text-left mr-2"
                                        style="flex-basis:136px;"
                                    >
                                        <div v-if="item.type && item.type === 'input'">
                                            <p class="ma-0" v-if="!open" key="0">
                                                {{ item.content }}
                                            </p>
                                            <p class="ma-0 text--secondary" v-else key="1">
                                                Enter your new {{ $t("fields."+`${item.field}`) }} 
                                            </p>
                                        </div>

                                        <p class=" ma-0 " v-else>
                                            <slot name="expansionContentSlot" :item="item"></slot>
                                            {{ item.content }}
                                            <v-icon v-if="item.icon">{{
                                                item.icon
                                            }}</v-icon>
                                        </p>

                                    </div>

                                </div>
                            </div>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                        class=" font-weight-medium"
                    >
                        <ValidationProvider 
                            v-slot="{ errors, valid }"
                            v-if="item.type && item.type === 'input'"
                            :rules="item.rules"
                            :name="$t('fields.'+`${item.field}`)"
                        >
                            <v-text-field 
                                :placeholder="'Start typing your new ' + $t('fields.'+`${item.field}`)"
                                :error-messages="errors"
                                :success="valid"
                                v-model="form[item.field]"
                                @blur="valid ? blurEvent($event, item.field) : null"
                            ></v-text-field>
                        </ValidationProvider>
                        <div 
                            v-else
                        >
                            <slot name="expansionPanelContentSlot" :item="item"></slot>
                            {{item.panelContent}}
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </template>
        <template slot="basic-action-slot">
            <slot name="expansionActionSlot"></slot>
        </template>
    </e-info-card>
</template>
<script>
import EInfoCard from '@/components/card/BasicInfoCard'
import { ValidationProvider } from 'vee-validate';

export default {
    components: {
        EInfoCard,
        ValidationProvider
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        link: {
            type: Boolean,
            default: false
        },
        list: {
            type: [Array, Object],
            default: function() {
                return []
            }
        },
        boxShadow: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            form: {}
        }
    },
    methods: {
        blurEvent(e, fieldName) {
            const value = e.target.value
            this.$emit('inputBlur', {value, fieldName});
            this.resetForm();
        },
        resetForm() {
            this.form = {};
        }
    }
}
</script>
