
import {modules} from './modules'
import { registerModule } from './modules/register.js'
import Vue from 'vue'
import { i18n } from './services/i18n'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import helpers from './services/helpers';
import uuid from './services/uuid';
import axiosInterceptor from './services/axiosInterceptor';

import axios from "axios";
import VueAxios from "vue-axios";
import VueMeta from "vue-meta";
import UiKit from '@/plugins/ui.kit';
import moment from 'moment';
import '@/plugins/base';
import '@/plugins/apexChart';
import '@/plugins/print';
//import '@/plugins/webfontloader'

import '@/assets/scss/site-specific.scss'


import '@/services/validation'

axiosInterceptor(store);

Vue.use(VueMeta);
Vue.use(VueAxios, axios);
Vue.use(UiKit);


Vue.config.productionTip = false

const plugin = {
  install () {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
    Vue.prototype.moment = moment
    Vue.prototype.buildnumber = config.CONFIG_VUE_APP_BUILDNUMBER
  }
}
Vue.use(plugin)
Vue.mixin(uuid);
registerModule(modules)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
