<template>

    <base-cta-table
        title="Pricelists"
        :headers="headers"
        storeNamespace="pricepackages"
        listNamespace="A_pricelist"
        buttonText="New pricelist"
        :hideTitle="true"
        :changePathOnEdit="true"
        editPath="pricelist"
    >

         <template #dialog-content="{setDialog}"> 
             <pricelist-stepper
                @stepper-done="setDialog(false)"
             ></pricelist-stepper>
         </template>



    </base-cta-table>



</template>

<script>
import PricelistStepper from './PricelistStepper.vue'
export default {
    name: "PricepackagePricelists",
    metaInfo: {
        title: 'Pricepackage Pricelists'
    },
    components: {
        PricelistStepper
    },
    data() {
      return {
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name',
            },
            {
                text: 'Action', 
                value: 'action'
            }
        ]
      }
    },
    methods: {

    },
    computed: {
       
    },
    created() {
      
    }
}
</script>

<style>

</style>