<template>


<izy-card
    :title="title"
    :inlineMode="false"
>
    <div class="px-5 pt-5">
        <div class="tabHeader d-flex justify-start flex-wrap mb-4">

        <button 
            v-for="(item, i) in tabs"
            :class="[tab === i ? 'tab-active-btn' : '', 'tab-btn py-4 pl-4 pr-6 ma-2 rounded-lg block']"
            :key="`tab-${i}`"
            @click="tab = i" 
        >
            <v-icon left>{{item.icon}}</v-icon>
            {{ item.name }}


        </button>

        </div>


        <v-tabs 
        v-model="tab"
        height="auto"
        >
        <template v-for="(item, i) in tabs">
            <v-tab-item :key="`tab-item-${i}`">
                <component 
                    :is="item.component" 
                    v-bind="[item.props ? {...item.props} : '']"
                    @hook:mounted="onComponentMounted(i)"
                    v-if="tab === i">
                </component>
            </v-tab-item>
        </template>
        </v-tabs>

    </div>


</izy-card>








</template>

<script>


import IzyCard from '@/components/card/IzyCard'

export default {
    name: "TabCard",
    props: {
        tabs: {
            type: Array,
            default: () => {
                return []
            }
        },
        title: {
            type: String,
            default: ''
        }
    },
    components: {
        IzyCard
    },
    data() {
        return {
            tab: 0,
            visitedTabs: []
        }
    },
    methods: {
        onComponentMounted(value) {
            if(!this.visitedTabs.includes(value)) {
                this.visitedTabs.push(value)
            }
        }
    },
    watch: {
        visitedTabs(value) {
            this.$emit('visitedTabs', value)
        }
    }
}
</script>

<style lang="scss" scoped>
    .tab-btn {

        text-align: left;
        background: #f5f8fa;
        color: #7e8299;
        display: flex;
        align-items: center;
        border: 1px solid #eff2f5;
        i {
            font-size: 26px;
        }
        &:hover {
            background: #f1faff !important;
            color: var(--v-primary-base) !important;
            i {
                color: var(--v-primary-base) !important
            }
        }
    }
    .tab-active-btn {
        background: #f1faff !important;
        color: var(--v-primary-base) !important;
        border-color: var(--v-primary-base) !important;
        i {
            color: var(--v-primary-base) !important
        }
    }
    .izy-tabs .v-tabs-bar {
        height: auto !important;
    }

</style>