<template>
    <div>
        <v-row>
        
            <v-col cols="12" class="text-center">
                <h2>{{ dashboardView.client_name }}</h2>
                <p>
                    Här kommer du kunna se dina inskickade prover och statistik.
                </p>
            </v-col>
            <v-col cols="12" md="4">
                <TestCard mainIconName="mdi-account" headingText="Min profil" bottomButtonLinkTo="profile" mainIconBackgroundColorClass="primary" mainIconTextColor="white--text" subHeadingText="Dina uppgifter" bottomButtonText="Visa"></TestCard>
            </v-col>
    
    
    </v-row>
    </div>
</template>

<script>


import {mapGetters} from 'vuex'
import TestCard from '@/components/card/AnalyticCardVersionTwo.vue';
export default {
    name: 'CustomerDashboard',
    components: {
        TestCard
    },
    computed: {
       ...mapGetters('profile', ['dashboardView']),
    },
    methods: {
     
    },
    created () {

    }
}
</script>

<style lang="scss" scoped>

</style>