import Test from './pages/Test.vue'
import Example1 from './pages/Example1.vue'
import StepperTest from './pages/StepperTest.vue'
import ListTest from './pages/ListTest.vue'
import ArrayTest from './pages/ArrayTest.vue'
//import TestStore from './store/test.js'
import DevTest from './store/devtest.js'

// eslint-disable-next-line no-unused-vars
export function testModule({ app, router, store, eventBus }) {

	router.addRoute('Home', { 
		path: 'test', 
		name:'test', 
		component: () => import("@/components/ParentNode"), 
		redirect: to => {return to.path + '/test'}
	})

	router.addRoute('test', { path: 'test', component: Test })
	router.addRoute('test', { path: 'example1', component: Example1 })
	router.addRoute('test', { path: 'stepper', component: StepperTest })
	router.addRoute('test', { path: 'list', component: ListTest })
	router.addRoute('test', { path: 'array', component: ArrayTest })

	/**
	 * Register store
	 */
	//store.registerModule('test', TestStore)

	store.registerModule('devtest', DevTest)

}





