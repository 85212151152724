<template>
    
        <edit-form
            :title="title"
            :subTitle="subTitle"
            :schema="schema"
            storeNamespace="aaa"
            objectNamespace="O_group"
            :objectId="id"
            :listOnSave="listOnSave"
        >
            <template v-slot:headerSlot="{orginalObject}">
                <div class="text-center mb-10">
                    <div v-if="!$helpers.isObjectEmpty(orginalObject)">
                        <h3 class="mb-4">{{orginalObject.name}}</h3>
                        <p>
                            Här kan du redigera en grupp
                        </p>
                    </div>
                    <div v-else>
                        <h3 class="mb-4">User</h3>
                        <p>
                            Här kan du skapa en ny grupp
                        </p>
                    </div>
                </div>
            </template>
        </edit-form>
    
</template>

<script>
import EditForm from '@/components/form/EditForm'

export default {
    name: "GroupForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
    },
    computed: {
       schema() {
            const filter = {
                name: '{ type:"input", rules:"required"}',
            }
            
            return this.$helpers.schemaParser(filter)
        },
        title() {
            return this.id ? "Uppdatera grupp" : "Ny grupp";
        },
        subTitle() {
            return this.id ? "Här kan du redigera en grupp" : "Här kan du skapa en ny grupp";
        },
        
    },
}
</script>

<style>

</style>