var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('izy-card',{staticClass:"mt-8",attrs:{"title":"Journals"}},[_c('v-data-table',{staticClass:"style-1",attrs:{"items":_vm.A_journal,"headers":_vm.headers,"single-expand":true,"show-expand":"","item-key":"id"},on:{"click:row":_vm.showRow},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.name)+" ")]}},{key:"item.received_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.received_at).format('YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"item.site",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.site.name)+" ")]}},{key:"item.data-table-expand",fn:function(ref){
var isExpanded = ref.isExpanded;
return [_c('v-icon',{on:{"click":_vm.showRow}},[_vm._v(_vm._s(isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[(_vm.loadingJournal)?_c('div',{staticClass:"d-flex justify-center py-5"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('div',{staticClass:"row pa-5"},[_c('table',{staticClass:"simple-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Journal id")]),_c('th',[_vm._v("Kund")]),_c('th',[_vm._v("Fakturamärkning")]),_c('th',[_vm._v("Objekt")]),_c('th',[_vm._v("Rum")])])]),_c('tbody',_vm._l((_vm.O_journal.journalrows),function(row,idx){return _c('tr',{key:row.id,on:{"click":function($event){return _vm.openJournalItem(idx)}}},[_c('td',[_vm._v(_vm._s(row.journal_id))]),_c('td',[_vm._v(_vm._s(row.client.name))]),_c('td',[_vm._v(_vm._s(row.name_outer))]),_c('td',[_vm._v(_vm._s(row.reference))]),_c('td',[_vm._v(_vm._s(row.room))])])}),0)])])])]}}])})],1),_c('v-dialog',{attrs:{"width":"980px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('journal-card',{attrs:{"journal-item":_vm.journalItem},on:{"closeCard":function($event){_vm.dialog=false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }