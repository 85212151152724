import Clients from './pages/Clients.vue'
import ClientEdit from './pages/ClientEdit.vue'
import ClientStepper from './pages/ClientStepper.vue'
import ClientsStore from './store/clients.js'
import CustomerStore from './store/customer.js'
import ClientsUserStore from './store/clientsUser.js'
import ClientsEconomyStore from './store/clientEconomy.js'
import ClientCard from './pages/ClientCard.vue'
import ClientAllUsers from './pages/ClientAllUsers.vue'

import ClientUserForm from './components/Users/ClientUserForm.vue'

// eslint-disable-next-line no-unused-vars
export function clientsModule({ app, router, store, eventBus }) {

	router.addRoute('Home', { 
		path: 'clients', 
		name:'clients', 
		component: () => import("@/components/ParentNode"), 
		redirect: to => {return to.path + '/list'}
	})

	router.addRoute('clients', { path: 'list', component: Clients })
	router.addRoute('clients', { path: 'edit/:id?', component: ClientEdit, props:true})

	router.addRoute('clients', { path: 'card/:id?', component: ClientCard, props:true})

	router.addRoute('clients', { path: 'stepper', component: ClientStepper })

	router.addRoute('clients', { path: 'userform', component: ClientUserForm })
	router.addRoute('clients', { path: 'users', component: ClientAllUsers })

	store.registerModule('clients', ClientsStore)
	store.registerModule('clientsUser', ClientsUserStore)
	store.registerModule('clientsEconomy', ClientsEconomyStore)
	store.registerModule('customer', CustomerStore)

}