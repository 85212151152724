import eventBus from '@/plugins/eventbus.js'
import authService from '../api/authService.js'
import jwt_decode from "jwt-decode";
import router from '@/router';
import storageService from '@/services/storageService.js';

const state = {

  loggedInUser:
    storageService.getToken() != null &&
    storageService.getToken().length > 0
        ? jwt_decode(storageService.getToken())
        : false,
  loading: false,
  error: null,
  
}

const getters = {
  loggedInUser: state => state.loggedInUser,
  loading: state => state.loading,
  isLoggedIn: state => state.loggedInUser ? true : false,
  error: state => state.error,
}

const actions = {
  login({commit }, data) {// eslint-disable-line no-unused-vars

    commit("CLEAR_ERROR");
    commit("SET_LOADING", true);    

    authService.login(data)
    .then((response) => {
      storageService.setToken(response.data.token)
      commit("SET_USER", response.data.token);
      eventBus.$emit.logIn();
    })
    .catch((error) => {
      storageService.removeToken();
      commit("SET_ERROR", error.response.data.message)
    })

    
  },
  signOut({ commit }) {
    storageService.removeObj();
    commit("SET_LOGOUT");

    eventBus.$emit.logOut();
  },
  

}

const mutations = {
  SET_USER(state, data) {
    state.loggedInUser = jwt_decode(data);
    state.loading = false;
    state.error = null;
  },
  SET_LOGOUT(state) {
    state.loggedInUser = null;
    state.loading = false;
    state.error = null;
    router.push({name: 'sign-in'});
  },
  SET_LOADING(state, data) {
    state.loading = data;
    state.error = null;
  },
  SET_ERROR(state, data) {
    state.error = data;
    state.loggedInUser = null;
    state.loading = false;
  },
  CLEAR_ERROR(state) {
    state.error = null;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
