
import helpers from '@/services/helpers.js';
import api from "@/services/api";
import storageService from "@/services/storageService";



const actionEndpoints = (key, path, endpoint, type, rules = false) => {
    let apiClass = {}
    const name = helpers.capFirstLetter(key);
    
    let basepath = path.split("/");
    basepath = basepath.pop() || basepath.pop();
    
    if(basepath != endpoint) {
        if(rules) {
            if("endpointProps" in rules) {
                let count = 0;
                rules['endpointProps'].forEach((x) => {
                    
                    if(path.slice(-1) != "/") {
                        path += "/";
                        
                    }
                    if(x === basepath && count === 0) {
                        
                        path += ":id" + helpers.capFirstLetter(x);
                        
                    }
                    else {
                        path += x + "/:id" + helpers.capFirstLetter(x) 
                    }
                    count++;
                })
            }
            if("prepend" in rules) {
                rules['prepend'].forEach((x) => {
                    if(path.slice(-1) != "/") {
                        path += "/";
                    }
                    path += x;
                })
            }
        }
        if(path.slice(-1) != "/") {
            path += "/";
        }
        path += endpoint;
    }


    if(type === 'object') {
        apiClass[`get_${name}`] = function(id, ids = false, params = {}) {
            const endpath = (ids) ? helpers.replaceStrByObject(path, ids, "/", ":") : path;
            const getParams = (!helpers.isObjectEmpty(params)) ? "?" + new URLSearchParams(params).toString() : '';
            const idPath = id ? '/' + id : ''; 
            return api.get(storageService.getDashboardView()['name'] + "/" + endpath + idPath + getParams)
        }
        apiClass[`create_${name}`] = function(data, ids = false) {
            const endpath = (ids) ? helpers.replaceStrByObject(path, ids, "/", ":") : path;
            return api.post(storageService.getDashboardView()['name'] + "/" + endpath, data)
        }
        apiClass[`update_${name}`] = function(data, id, ids = false) {
            const endpath = (ids) ? helpers.replaceStrByObject(path, ids, "/", ":") : path;
            const idPath = id ? '/' + id : ''; 
            return api.put(storageService.getDashboardView()['name'] + "/" + endpath + idPath, data)
        }
        apiClass[`patch_${name}`] = function(data, id, ids = false) {
            const endpath = (ids) ? helpers.replaceStrByObject(path, ids, "/", ":") : path;
            const idPath = id ? '/' + id : ''; 
            return api.patch(storageService.getDashboardView()['name'] + "/" + endpath + idPath, data)
        }
        apiClass[`delete_${name}`] = function(id, ids = false, params = {}) {
            const endpath = (ids) ? helpers.replaceStrByObject(path, ids, "/", ":") : path;
            const getParams = (!helpers.isObjectEmpty(params)) ? "?" + new URLSearchParams(params).toString() : '';
            const idPath = id ? '/' + id : ''; 
            return api.delete(storageService.getDashboardView()['name'] + "/" + endpath + idPath + getParams)
        }
    }
    else if(type === 'array') {
        apiClass[`list_${name}`] = function(ids = false, params = {}) {
            const endpath = (ids) ? helpers.replaceStrByObject(path, ids, "/", ":") : path;
            const listParams = (!helpers.isObjectEmpty(params)) ? "?" + new URLSearchParams(params).toString() : '';

    
            return api.get(storageService.getDashboardView()['name'] + "/" + endpath + listParams)
        }
    }
    
    if(rules && 'actions' in rules) {
        const keys = rules['actions'].map(x => x + "_" + name);
        apiClass = helpers.filterByKeyArray(apiClass, keys);
    }
    
    return apiClass;
}


export default function(state, path) {
    let apiClass = {}
    const stateRule = ("stateRules" in state) ? true : false;
    

    Object.keys(state).forEach(key => {
      
        const keySplit = key.split("_");
        if(keySplit[0] === 'O' || keySplit[0] === 'A') {
            let isKeyRule = false;

            if(stateRule) {
                
                if(keySplit[1] in state["stateRules"]) {
                    isKeyRule = true;
                }
            }

            if (Array.isArray(state[key])) {
                if(isKeyRule) {
                    Object.assign(apiClass, actionEndpoints(key, path, keySplit[1], 'array', state["stateRules"][keySplit[1]]));
                }
                else {
                    Object.assign(apiClass, actionEndpoints(key, path, keySplit[1], 'array'))
                }
            }

            else if(helpers.isObject(state[key])) {
                if(isKeyRule) {
                    
                    Object.assign(apiClass, actionEndpoints(key, path, keySplit[1], 'object', state["stateRules"][keySplit[1]]));
                }
                else {
                    Object.assign(apiClass, actionEndpoints(key, path, keySplit[1], 'object'))
                }
                
            }
        }
       
    });

  
    return apiClass;
}
