import BaseStore from "@/modules/baseStore.js";
import BaseApi from "@/modules/baseApi.js";

const state = {
  A_method: [],
  A_term: [],

  stateRules: {
    method: {
        prepend: ['billing']
    },
    term: {
        prepend: ['billing']
    }
  }
}

const api = BaseApi(state, "customer/client")

export default BaseStore.extend({
  state,
  api
})