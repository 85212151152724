<template>

    <v-tabs>
        <v-tab>Grunduppgifter</v-tab>
        <v-tab>Behörigheter</v-tab>
        <v-tab>Lösenord</v-tab>
        <v-tab-item>
            <edit-form
                :title="title"
                :subTitle="subTitle"
                :schema="schemaUser"
                storeNamespace="clients"
                objectNamespace="O_user"
                :objectId="id"
                :listOnSave="listOnSave"
            ></edit-form>
        </v-tab-item>
        <v-tab-item>
            <v-dialog
                content-class="cta-dialog"
                v-model="permissionDialog"
                max-width="600"
                :scrollable="true"
            >
                <edit-form
                    title="Lägg till behörighet"
                    :schema="schemaUserPermission"
                    storeNamespace="clients"
                    objectNamespace="O_permission"
                    :listOnSave="false"
                    listStoreNamespace="clients"
                    listObjectNamespace="A_user"
                    refreshStoreNamespace="clients"
                    refreshObjectNamespace="O_user"
                    @requestIsDone="permissionDialog = false"
                ></edit-form>
            </v-dialog>
            <div class="pt-5 px-3 d-flex">
                <v-btn color="success" small @click="permissionDialog = true">Lägg till behörighet</v-btn>
            </div>
            
            <div class="pa-3">
                <base-simple-list
                    :headers="groupsHeaders"
                    storeNamespace="clients"
                    objectNamespace="O_user"
                    keyName="groups"
                >
                </base-simple-list>
            </div>
            
        </v-tab-item>
        <v-tab-item>
            <div class="pa-3">
                <h4 class="h6">Skicka begäran om återställning av lösenord</h4>
                <p>Ett e-postmeddelande kommer skickas till användaren med en länk för att kunna ändra sitt lösenord.</p>
                <v-btn
                    @click="sendReset()" 
                    small 
                    :loading="loading"
                    color="success"
                >
                Skicka e-postmeddelande
                </v-btn>
            </div>
        </v-tab-item>
    </v-tabs>

</template>

<script>
import EditForm from '@/components/form/EditForm'
import {clientUserSchema} from '@/modules/Clients/schema/clientUserSchema.js'
import {clientEditUserAddPermissionSchema} from '@/modules/Clients/schema/clientEditUserAddPermissionSchema.js'
import eventBus from '@/plugins/eventbus.js'
import authService from '@/modules/Auth/api/authService.js'
import {mapGetters} from 'vuex';

export default {
    name: "ClientUserEdit",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            permissionDialog: false,
            groupsHeaders: [
                {
                    text: 'Permission',
                    align: 'start',
                    sortable: false,
                    value: 'name'
                },
                {
                    text: 'Valid from',
                    align: 'start',
                    sortable: false,
                    value: 'date'
                },
                {
                    text: 'Valid to',
                    align: 'start',
                    sortable: false,
                    value: 'date'
                }
            ]
        }
    },
    methods: {
      sendReset() {
        this.loading = true;
        const data = {
            "username": this.O_user.username,
            "frontendUrl": window.location.origin + '/' + 'reset'
        }  
        authService.forgot(data)
        .then(() => {

            eventBus.$emit.snackMsg({
              "text": "Message sent!",
              "type": "success",
            });
        })
        .catch(() => {
             eventBus.$emit.snackMsg({
              "text": "Couldn't send reset link. Please try again",
              "type": "danger"
            });
        })
        .finally(() => {
            this.loading = false;
        })
      }
    },
    computed: {
        ...mapGetters('clients', ['O_user']),
       schemaUser() {
            return this.$helpers.schemaParser(clientUserSchema)
        },
        schemaUserPermission() {
            return this.$helpers.schemaParser(clientEditUserAddPermissionSchema)
        },
        title() {
            return this.id ? "Uppdatera användare" : "Ny användare";
        },
        subTitle() {
            return this.id ? "Här kan du redigera en användare" : "Här kan du skapa en ny användare";
        },
        
    },
}
</script>

<style>

</style>