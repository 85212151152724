<template>
    <base-cta-list
        title="Köer"
        subtitle="Här hittar du köerna för aktuell sajt"
        :headers="headers"
        storeNamespace="settings"
        listNamespace="A_queue"
        :hideTitle="true"
        buttonText="Ny kö"
        :inlineMode="true"
    >

        <template v-slot:row.color="{ entity }">
            {{entity.color}}
        </template>

         <template #dialog-content="{setDialog, dataId}"> 
             <queue-form
                :listOnSave="true"
                @requestIsDone="setDialog(false)"
                :id="dataId"
             ></queue-form>
         </template>

    </base-cta-list>
</template>

<script>
import QueueForm from './QueueForm.vue'
export default {
    components: {
        QueueForm,
    },
    data() {
        return {
            headers: [
                {
                    text: 'Name',
                    align: 'start',
                    sortable: false,
                    value: 'name'
                },
                {
                    text: 'Färg', 
                    value: 'color',
                    slot: true
                }
            ],
        }
    },
}
</script>

<style>

</style>