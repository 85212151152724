import storageService from './storageService.js';

export default (to, from, next) => {
  if (
    storageService.getToken() != null &&
    storageService.getToken().length > 0
  ) {
    next()
  } else {
    storageService.removeToken()
    next('/sign-in')
  }
}
