<template>


    <base-cta-list
        title="Service price packages"
        subtitle="Here you find the current service price packages"
        :headers="headers"
        storeNamespace="pricepackages"
        listNamespace="A_pricepackage"
        buttonText="New price package"
        buttonLink="pricepackagestepper"
        :icon="pricepackageLogo"
        :changePathOnEdit="true"
        editPath="pricepackage"
    >

        <template v-slot:row.price_lists="{ entity }">

            <v-menu
                bottom
                right
                transition="scale-transition"
                origin="top left"
            >
                <template v-slot:activator="{ on }">
                    <v-chip
                        class="ma-2"
                        color="green"
                        label
                        text-color="white"
                        v-on="on"
                    >
                        <v-icon left>
                            mdi-label
                        </v-icon>
                        {{entity.price_lists.length}} st
                    </v-chip>
                </template>
                <v-card width="300">
                    <v-list dark>
                        <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{entity.name}}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn
                            icon
                            @click="menu = false"
                            >
                            <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <v-list>
                        <v-list-item
                            v-for="pricelist in entity.price_lists" 
                            :key="pricelist.id"
                            @click="goPricelist(pricelist.id)"
                        >
                            <v-list-item-subtitle>{{pricelist.name}}</v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>





            
        </template>

        <template v-slot:row.default="{ entity }">

            <v-icon color="primary" v-if="entity.default" class="mr-2">mdi-checkbox-marked-circle</v-icon>
        </template>

    </base-cta-list>



</template>

<script>

export default {
    name: "ServicePricePackages",
    metaInfo: {
        title: 'Service Price Packages'
    },
    data() {
      return {
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name'
            },
            {
                text: 'Default',
                align: 'start',
                sortable: true,
                value: 'default',
                slot: true
            },
            {
                text: 'Pricelists',
                align: 'end',
                sortable: true,
                value: 'price_lists',
                slot: true
            }
        ],
        pricepackageLogo: require('@/assets/images/svg/price-tag.svg'),
      }
    },
    methods: {
        goPricelist(pricelistId) {
            this.$router.push({ path: 'pricepackage/pricelist/' + pricelistId })
        }
    },
}
</script>

<style>

</style>