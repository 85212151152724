<template>
    
    <v-card
        class="mx-auto"
        style="box-shadow: 0 8px 32px rgb(47 60 74 / 1%), 0 8px 16px rgb(47 60 74 / 2%)"
        :class="{'pa-4': padding, cardClass}"
        :color="$vuetify.theme.dark && color == 'white' ? 'dark' : color"
        :dark="$vuetify.theme.dark"
        max-width="100%"
    >
     
        <v-card-title class="edit-header pb-4">
            <div>
                <span class="font-weight-bold">
                    {{ title }}
                </span>
                <span class="d-flex subtitle-1">
                    {{ subTitle }}
                </span>
            </div>
            
            <v-spacer></v-spacer>
            <slot name="titleAction"></slot>
        </v-card-title>

        <v-card-text class="pa-7 ">

            <div class="edit-form">
                <slot name="editSlot"></slot>
                <vuex-fields
                    ref="formFieldRef"
                    :schema="schema"
                    :storeNamespace="storeNamespace"
                    :objectNamespace="objectNamespace"
                    :listNamespace="listNamespace"
                    :refreshObjectNamespace="refreshObjectNamespace"
                    :refreshStoreNamespace="refreshStoreNamespace"
                    :listStoreNamespace="listStoreNamespace"
                    :listObjectNamespace="listObjectNamespace"
                    :objectId="objectId"
                    :sectionTitles="sectionTitles"
                    :arrayMode="arrayMode"
                    :listOnSave="listOnSave"
                    :clearStoreOnCreate="clearStoreOnCreate"
                    :stateRulesIds="stateRulesIds"
                    @is-valid="changeValidStatus"
                    @is-update="changeIsUpdate"
                    @request-done="requestIsDone"
                ></vuex-fields>
            </div>
        </v-card-text>

    
        <v-card-actions
            class="primary--text grey lighten-5 py-4"
            v-if="!getThemeMode.isLoading"
            style="cursor:pointer"
        >
            <v-row class="px-7">
                <v-btn text class="ma-1" v-if="resetFormEnabled" @click="resetForm()">Reset form</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="ma-1" color="primary" @click="saveItem()" :disabled="!isValid">{{isUpdate ? "Update" : "Save"}}</v-btn>
            </v-row>
            
        </v-card-actions>

    </v-card>
    
</template>
<script>

import VuexFields from '@/components/form/VuexFields'
import {mapGetters} from 'vuex'
export default {
    name: "EditForm",
    components: {

        VuexFields,
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        schema: {
            type: Array,
            default: function() {
                return []
            }
        },
        storeNamespace: {
            type:String,
            default: ''
        },
        objectNamespace: {
            type:String,
            default: ''
        },
        refreshStoreNamespace: {
            type:String,
            default: ''
        },
        refreshObjectNamespace: {
            type:String,
            default: ''
        },
        listObjectNamespace: {
            type: String
        },
        listStoreNamespace: {
            type: String
        },
        listNamespace: {
            type:String,
            default: null
        },
        objectId: {
            type: String,
            default: null
        },
        sectionTitles: {
            type: Array,
            default: function() {
                return []
            }
        },
        arrayMode: {
            type: Boolean,
            default: false
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
        color: {
            type: String,
            default: 'white'
        },
        cardClass: {
            type: String,
            default: ''
        },
        padding: {
            type: Boolean,
            default: false
        },
        clearStoreOnCreate: {
            type: Boolean,
            default: true
        },
        resetFormEnabled: {
            type: Boolean,
            default: true
        },
        resetOnDone: {
            type: Boolean,
            default: false
        },
        stateRulesIds: {
            type: Object,
            default: function() {
                return {}
            }
        },
    },
    data () {
      return {
          isValid: false,
          isUpdate: false,
      }
    },
    methods:{
       async resetForm() {
           await this.$refs.formFieldRef.resetForm()
       },
       saveItem() {
           this.$refs.formFieldRef.saveItem()
       },
       changeValidStatus(status) {
           this.isValid = status
       },
       changeIsUpdate(status) {
           this.isUpdate = status;
       },
       async requestIsDone(res) {

           if(this.listOnSave && !this.objectId || this.resetOnDone) {
               await this.resetForm();
           }
           this.$emit('requestIsDone', res)
           this.$parent.$emit('requestIsDone')
       }
    },
    computed: {
        ...mapGetters(['getThemeMode'])
    },

}
</script>
<style lang="scss" scoped>
    .edit-form {
        max-width: 700px;
        margin: 0 auto;
    }
    .edit-header {
        border-bottom: 1px solid #e9edf1;
        padding-right: 72px !important;
    }
    .subtitle-1 {
        line-height: 1.5rem;
    }
</style>