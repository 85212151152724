import BaseStore from "@/modules/baseStore.js";
import BaseApi from "@/modules/baseApi.js";

const state = {
  A_envelope: [],
  O_envelope: {},

  A_arrival: [],
  O_arrival: {},

  A_bag: [],
  O_bag: {},

  stateRules: {
    envelope: {
      actions: ['create', 'get', 'list'],
    },
    bag: {
      actions: ['get', 'update']
    }
  }

}

const api = BaseApi(state, "aaa/sample/")


export default BaseStore.extend({
  state,
  api
})