import eventBus from '@/plugins/eventbus.js'

function errorResponseHandler(error) {
    
    let errorMessage = {
        text: 'An error occurred. Please contact system administrator.',
        type: "danger"
    }

    if(error.response) {
        // check if turned of
        /*
        if( error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false ) {
            return Promise.reject(error);
        }
        */

        //Form validation error
        if(error.response.status == 422) {

            errorMessage = {
                text: 'Validation failed. Please check your form.',
                type: "warning"
            }

            /*
            vm.$message.error("Validation failed. Please check your form.");
            
            
            if(error.response.data.hasOwnProperty('errors')) {
                _.forEach(error.response.data.errors, (value, key) => {
                    vm.$store.dispatch('errors/ADD_FORM_ERROR', {key, value});
                });
            }
            */
        
        }

        else if(error.response.status == 500) {
    
            errorMessage = {
                "text": error.response.data.message ? error.response.data.message : 'Something is wrong. Please contact system administrator.',
                "type": "danger"
            }

            /*
            if(error.response.data.hasOwnProperty('message')) {
                vm.$message.error(error.response.data.message);
            }
            else {
                vm.$message.error("Something is wrong. Please contact system administrator.");
            }
            */
        }
        else if(error.response.status == 403) {
            errorMessage = {
                "text": 'Request is forbidden',
                "type": "warning"
            }

            /*
            vm.$message.error("Request is unauthorized");
            */
        }
        else if(error.response.status == 401) {
            errorMessage = {
                "text": 'Unauthorized',
                "type": "warning"
            }
            /*
            if(error.response.data.hasOwnProperty('message')) {
                vm.$message.error(error.response.data.message);
            }
            else {
                vm.$message.error("Unauthorized");
            }
            */
        }
        else if(error.response.status == 404) {
            errorMessage = {
                "text": 'Not found',
                "type": "warning"
            }
            /*
            if(error.response.data.hasOwnProperty('message')) {
                vm.$message.error(error.response.data.message);
            }
            else {
                vm.$message.error('404! Page does not exist. You have been redirected back to home.');
            }
            vm.$router.push({name: 'home'});
            */
        }
        else if(error.response.status == 406) {
            errorMessage = {
                "text": "Not acceptable!",
                "type": "warning"
            }
        }
        else if(error.response.status == 409) {
            errorMessage = {
                "text": "Conflict! A similar item already exists",
            }
        }

    }

    eventBus.$emit.snackMsg(errorMessage);


    return Promise.reject(error);
}



export default errorResponseHandler;