import BaseStore from "@/modules/baseStore.js";
import BaseApi from "@/modules/baseApi.js";



const state = {
  A_client: [],
  O_client: {},
  A_permission: [],
  O_permission: {},
  
  A_group: [],

  A_user: [],
  O_user: {},

  A_contact: [],
  O_contact: {},

  A_batch: [],
  O_batch: {},

  A_agreement: [],
  O_agreement: {},

  A_result: [],
  O_result: {},

  stateRules: {
    permission: {
      actions: ['create'],
      endpointProps: ['client']
    },
    group: {
      actions: ['list'],
      endpointProps: ['client']
    },
    contact: {
      endpointProps: ['client']
    },
    user: {
      endpointProps: ['client']
    },
    batch: {
      endpointProps: ['client'],
      prepend: ['sample','envelope', 'outer']
    },
    agreement: {
      endpointProps: ['client'],
      prepend: ['sample']
    },
    result: {
      endpointProps: ['client'],
      prepend: ['sample']
    }
  }
}


const api = BaseApi(state, "customer/client")

export default BaseStore.extend({
  state,
  api
})