<template>
    <div>
    <base-cta-list
        title="QR-koder Emballagepåsar"
        subtitle="Här hittar du kundens alla batcher av emaballagepåsasr"
        :headers="headers"
        storeNamespace="clients"
        listNamespace="A_batch"
        buttonText="Nytt emballage"
        :inlineMode="true"
        :editEnabled="false"
    >
        
        <template v-slot:row.name="{ index }">
            Batch {{index + 1}}
        </template>

        <template v-slot:row.created_at="{ entity }">
            {{moment(entity.created_at).format('YYYY-MM-DD HH:mm:ss')}}
        </template>


        <template v-slot:row.filetype="{index}">
            <v-avatar tile size="46">
                <button @click="openPrint(index)">
                    <img src="@/assets/images/file-types/001-pdf.svg" alt="" />
                </button>
            </v-avatar>
        </template>


         <template #dialog-content="{setDialog, dataId}"> 
             <client-envelope-form
                :listOnSave="true"
                @requestIsDone="setDialog(false)"
                :id="dataId"
             ></client-envelope-form>
         </template>

    </base-cta-list>
    <div class="hidden">
        <print-qr-template
            :bags="printBags"
            ref="printQrTemplate"
        ></print-qr-template>
    </div>
    </div>
</template>

<script>
import ClientEnvelopeForm from './ClientEnvelopeForm.vue'
import PrintQrTemplate from '@/components/util/PrintQrTemplate'
import {mapActions, mapGetters} from 'vuex'
export default {
    name: "ClientEnvelope",
    metaInfo: {
        title: 'Client Envelope'
    },
    components: {
        ClientEnvelopeForm,
        PrintQrTemplate
    },
    data() {
      return {
        printBags: [],
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name',
                slot: true
            },
            {
                text: 'Quantity',
                align: 'start',
                sortable: true,
                value: 'numbers',
            },
            {
                text: 'Created at',
                align: 'start',
                sortable: true,
                slot: true,
                value: 'created_at',
            },
            {
                text: 'Print',
                align: 'center',
                sortable: true,
                value: 'filetype',
                slot: true,
                width: '1'
            },
        ]
      }
    },
    methods: {
        ...mapActions('clients', ['get_O_batch']),
        
        async openPrint(index) {
            this.printBags = this.A_batch[index].bags
            await this.$nextTick();
            this.$refs.printQrTemplate.print();
        }
    },
    computed: {
        ...mapGetters('clients', ['O_batch', 'A_batch', 'O_client']),
    }
}
</script>

<style lang="scss" scoped>
    .hidden {
        position: absolute;
        left: -9999px;
        top: -9999px;
        display: none;
    }
</style>