
import labStore from './store/lab'
import Examination from './pages/Examination.vue'
import LabQueues from './pages/LabQueues.vue'
import LabJournals from './pages/LabJournals.vue'

// eslint-disable-next-line no-unused-vars
export function labModule({ app, router, store, eventBus }) {

	router.addRoute('Home', { 
		path: 'lab', 
		name:'lab', 
		component: () => import("@/components/ParentNode"), 
		redirect: to => {return to.path + '/list'}
	})


    router.addRoute('lab', { 
		path: 'examination', 
		component: Examination,
		// eslint-disable-next-line no-unused-vars
		beforeEnter: (to, from, next) => {
			// reject the navigation
			store.commit('lab/CLEAR_O_EXAMINATION');
			next();
		}, 
	})

	router.addRoute('lab', { path: 'queues', component: LabQueues})

	router.addRoute('lab', { path: 'journal', component: LabJournals})

	/**
	 * Register store
	 */
	store.registerModule('lab', labStore)

}