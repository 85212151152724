
const STOARAGE_KEY = "LFVUEX";

class storageService {

    getObj() {
        if(localStorage.getItem(STOARAGE_KEY) != 'undefined') {
            return JSON.parse(localStorage.getItem(STOARAGE_KEY));
        }
    }
    setObj(obj) {
        localStorage.setItem(STOARAGE_KEY,  JSON.stringify(obj))
    }
    removeObj() {
        localStorage.removeItem(STOARAGE_KEY);
    }

    getObjKey(key) {
        const obj = this.getObj();
        if(obj != null && key in obj) {
            return obj[key];
        }
        else {
            return false;
        }
    }
    setObjKey(key, value) {
        let obj = this.getObj();

        if(obj != null && key in obj) {
            obj[key] = value;
            this.setObj(obj);
        }
        else {
            localStorage.setItem(STOARAGE_KEY,  JSON.stringify({...{[key]: value}, ...obj}))
        }
        
    }
    removeObjKey(key) {
        let obj = this.getObj();

        if(obj != null && key in obj) {
            delete obj[key]; 
        }

        this.setObj(obj);
    }



    getToken() {
        return this.getObjKey("token");
    }

    setToken(value) {
        this.setObjKey("token", value)
    }

    removeToken() {
        this.removeObjKey("token");
    }


    getOrganization() {
        return this.getObjKey("organization");
    }

    setOrganization(value) {
        this.setObjKey("organization", value)
    }

    removeOrganization() {
        this.removeObjKey("organization");
    }


    getDashboardView() {
        return this.getObjKey("dashboardview");
        //return 'admin'
    }

    setDashboardView(value) {
        this.setObjKey("dashboardview", value)
    }

    removeDashboardView(){
        this.removeObjKey("dashboardview");
    }
    

}

export default new storageService();