<template>


    <base-cta-list
        title="Service methods"
        subtitle="Here you find the current service methods"
        :headers="headers"
        storeNamespace="services"
        listNamespace="A_method"
        :icon="methodLogo"
        buttonText="New method"
    >

         <template #dialog-content="{setDialog, dataId}"> 
             <service-method-form
                :listOnSave="true"
                @requestIsDone="setDialog(false)"
                :id="dataId"
             ></service-method-form>
         </template>

    </base-cta-list>



</template>

<script>
import ServiceMethodForm from './ServiceMethodForm.vue'


export default {
    name: "ServiceMethods",
    metaInfo: {
        title: 'Service Methods'
    },
    components: {
        ServiceMethodForm,
    },
    data() {
      return {
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name'
            }
        ],
        methodLogo: require('@/assets/images/svg/report.svg')
      }
    },
}
</script>

<style>

</style>