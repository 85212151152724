<template>


    <base-cta-list
        title="Service substances"
        subtitle="Here you find the current service substances"
        :headers="headers"
        storeNamespace="services"
        listNamespace="A_substance"
        :icon="substanceLogo"
        buttonText="New substance"
    >

         <template #dialog-content="{setDialog, dataId}"> 
             <service-substance-form
                :listOnSave="true"
                @requestIsDone="setDialog(false)"
                :id="dataId"
             ></service-substance-form>
         </template>

    </base-cta-list>



</template>

<script>
import ServiceSubstanceForm from './ServiceSubstanceForm.vue'


export default {
    name: "ServiceSubstances",
    metaInfo: {
        title: 'Service substances'
    },
    components: {
        ServiceSubstanceForm,
    },
    data() {
      return {
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name'
            }
        ],
        substanceLogo: require('@/assets/images/svg/pipette-lab.svg')
      }
    },
}
</script>

<style>

</style>