export const clientSchema = {
    type: '{type:"select", items: [{"text":"organisation", "value":"organisation"},{"text":"person", "value":"person"}], rules:"required"}',
    name_first: '{type:"input", rules:"required", conditions:"type.person"}',
    name_last: '{type:"input", rules:"required", conditions:"type.person"}',
    name_organisation: '{type:"input", rules:"required", conditions:"type.organisation"}',
    authority_id: '{type:"input", rules:"required", conditions:"type.organisation|person"}',
    vat_id: '{type:"input", rules:"required", conditions:"type.organisation"}',
    //invoice_method: '{type:"selectData", rules:"required", storeNamespace: "clientsEconomy", objectNamespace:"A_method", itemText: "name", section:"2"}',
    //invoice_terms: '{type:"selectData", rules:"required", storeNamespace: "clientsEconomy", objectNamespace:"A_term", itemText: "name", section:"2"}',
    id_group: '{type:"hiddenInput",  rules:"required", exclude:["update"], conditions:"type.organisation|person", storeNamespace: "profile", objectNamespace: "dashboardView", keyName: "id"}',
}