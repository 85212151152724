<template>
  <update-card
    :tabs="tabs"
    :id="id"
    storeNamespace="clients"
    objectNamespace="O_client"
    @visitedTabs="visitedTabs"
  >

  </update-card>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import ClientBasicInformation from '../components/ClientBasicInformation.vue'
import ClientContacts from '../components/Contacts/ClientContacts'
//import ClientExpansionUpdate from '../components/ClientExpansionUpdate.vue'
import ClientUsers from '../components/Users/ClientUsers'
import ClientEnvelopes from '../components/Envelopes/ClientEnvelopes'
import ClientAgreements from '../components/Agreements/ClientAgreements'
import ClientSampleResults from '../components/Results/ClientSampleResults'
import UpdateCard from '@/components/card/UpdateCard'

export default {
    components: {
        ClientUsers, 
        ClientEnvelopes,
        ClientAgreements,
        ClientSampleResults,
        UpdateCard,
        ClientBasicInformation,
        ClientContacts
    },
    props: {
        id: {
            type: String
        },
    },
    data() {
        return {
            tabsVisited: []
        }
    },
    computed: {
        tabs() {
            return [
                {
                    name: 'Grunduppgifter', 
                    icon: 'mdi-clipboard-list-outline',
                    component: ClientBasicInformation,
                    props: {
                        id: this.id, 
                        initialLoad: this.tabsVisited.includes(0) ? true : false
                    }
                },
                /*
                {
                    name: 'Grunduppgifter', 
                    icon: 'mdi-clipboard-list-outline',
                    component: ClientExpansionUpdate,
                    props: {
                        id: this.id, 
                        initialLoad: this.tabsVisited.includes(0) ? true : false
                    }
                },
                */
                {
                    name: 'Kontaktuppgiter', 
                    icon: 'mdi-card-account-phone',
                    component: ClientContacts
                },
                {
                    name: 'Emballagepåsar', 
                    icon: 'mdi-email-fast',
                    component: ClientEnvelopes
                },
                {
                    name: 'Prover', 
                    icon: 'mdi-test-tube',
                    component: ClientSampleResults
                },
                {
                    name: 'Avtal', 
                    icon: 'mdi-handshake',
                    component: ClientAgreements
                },
                {
                    name: 'Användare', 
                    icon: 'mdi-account-group',
                    component: ClientUsers
                },

            ]
        }
    },
    methods: {
        visitedTabs(value) {
            this.tabsVisited = value
        }
    },
}
</script>

<style>

</style>