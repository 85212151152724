<template>
    <div>
        <div class="form-row" v-for="(child, idx) in value" :key="`group-${idx}`">
            <FormGenerator
                ref="formGenerator"
                :schema="item.schema"
                :form="child"
                :observerDisabled="false"
                :resetFormTimestamp="resetFormTimestamp"
                @is-valid="setValidStatus($event, idx)"
                @set-field-value="setFieldValue($event, idx)"
            ></FormGenerator>
            <v-btn 
                x-small 
                color="warning"
                outlined
                fab
                class="delete-btn"
                @click="deleteItem(idx)"
            >&times;</v-btn>
        </div>

        <v-btn
            @click="addItem"
            color="success"
        >Lägg till</v-btn>
    </div>
</template>
<script>

export default {
    name: "ArrayInput",
    components: {
        FormGenerator: () => import('@/components/form/FormGenerator'),
    },
    props: {
        item: {
            type: Object,
            default: function() {
                return {}
            }
        },
        value: {
            type:Array,
            default: function() {
                return []
            }
        },
    },
    data () {
     return {
        isValid: [],
        resetFormTimestamp: 0,
     }
    },
    methods:{
        setFieldValue(input, idx) {
            const {field, value} = input;
            let tempForm = [...this.value];

            tempForm[idx][field] = value;
            tempForm[idx] = this.$helpers.cleanForm(this.item.schema,tempForm[idx]);

            this.$emit('updated', tempForm);
        },
        setValidStatus(val, idx) {
            this.isValid[idx] = val;
        },
        resetForm() {
            this.resetFormTimestamp = Date.now();
        },
        addItem() {
            this.$emit('updated', [...this.value, []]);
        },
        deleteItem(index) {
            const arrCopy = [...this.value];
            arrCopy.splice(index, 1)
            this.$emit('updated', arrCopy)
        },
        cleanAllObjects() {
            const arrCopy = this.value.map(obj => {
                return this.$helpers.cleanForm(this.item.schema,obj)
            })
            this.$emit('updated', arrCopy)
        }
    },
    computed: {

    },

    watch: {

    },
    async created() {
        this.cleanAllObjects();

    }
}
</script>
<style lang="scss" scoped>
    .form-row {
        position: relative;
    }
    .delete-btn {
        font-size: 160% !important;
        position: absolute;
        right: -7%;
        top: 25px;
    }
    
</style>