<template>


    <base-cta-list
        title="Service products"
        subtitle="Here you find the current service products"
        :headers="headers"
        storeNamespace="services"
        listNamespace="A_product"
        :icon="logo"
        buttonText="New product"
    >

        <template v-slot:row.queue="{ entity }">
            <span v-for="queue in entity.queue_ids" :key="queue.id">
                {{ queue.name_full }}
            </span>

        </template>

         <template #dialog-content="{setDialog, dataId}"> 
             <service-product-form
                :listOnSave="true"
                @requestIsDone="setDialog(false)"
                :id="dataId"
             ></service-product-form>
         </template>

    </base-cta-list>



</template>

<script>
import ServiceProductForm from '../components/Products/ServiceProductForm.vue'


export default {
    name: "ServiceProducts",
    metaInfo: {
        title: 'Service Groups'
    },
    components: {
        ServiceProductForm,
    },
    data() {
      return {
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name'
            },
            {
                text: 'Group',
                align: 'start',
                sortable: true,
                value: 'group_name'
            },
            {
                text: 'Kö',
                align: 'start',
                value: 'queue',
                slot: true
            }
        ],
        logo: require('@/assets/images/svg/product.svg')
      }
    },
}
</script>

<style>

</style>