import api from "@/services/api";

class authService {

    login(credentials) {
        return api.post("aaa/authentication", credentials)
    }
    forgot(credentials) {
        return api.post("aaa/reset", credentials)
    }
    reset(credentials, token) {
        return api.put("aaa/reset/" + token, credentials)
    }

}

export default new authService

