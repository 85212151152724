<template>
    <div>

        <base-stepper
            v-slot="{changeStep, currentStep, stepperId}"
            :numOfSteps="2"
            :intialStep="1"
            :hideNavigator="true"
            :hideTitle="true"
        >
            
            <v-stepper-items>

                <step-form-content
                    title="Användarkonto"
                    subTitle="Fyll i användaruppgifter för den nya användaren"
                    :schema="schemaStep1"
                    storeNamespace="clients"
                    objectNamespace="O_user"
                    :saveOnContinue="true"
                    :clearStoreOnCreate="true"
                    :step="1"
                    :parentId="stepperId"
                    @step-change="changeStep"
                ></step-form-content>
                
                <step-form-content
                    title="Tilldela behörigheter"
                    subTitle="Välj vilka behörigheter du vill tilldela användaren"
                    :schema="schemaStep2"
                    storeNamespace="clients"
                    objectNamespace="O_permission"
                    :clearStoreOnCreate="true"
                    :step="2"
                    :saveOnContinue="true"
                    :listOnSave="true"
                    listObjectNamespace="A_user"
                    :lastStep="true"
                    :currentStep="currentStep"
                    :parentId="stepperId"
                    @step-change="changeStep"
                    @step-done="onDone"
                ></step-form-content>
            </v-stepper-items>
        </base-stepper>

    </div>
</template>

<script>
import StepFormContent from '@/components/form/StepFormContent'
import {clientUserSchema} from '@/modules/Clients/schema/clientUserSchema.js'
import {clientUserPermissionSchema} from '@/modules/Clients/schema/clientUserPermissionSchema.js'
export default {
    name: "ClientUserForm",
    components: {
      StepFormContent
    },
    methods: {
      onDone() {
        this.$emit('stepper-done');
      }
    },
    computed: {
        schemaStep1() {
            return this.$helpers.schemaParser(clientUserSchema)
        },
        schemaStep2() {
            return this.$helpers.schemaParser(clientUserPermissionSchema)
        },
    }
}
</script>

<style>

</style>