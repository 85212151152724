<template>
    
    <edit-form
        :title="title"
        :subTitle="subTitle"
        :schema="schema"
        storeNamespace="economy"
        objectNamespace="O_term"
        :objectId="id"
        :listOnSave="listOnSave"
    >

    </edit-form>

</template>

<script>
import EditForm from '@/components/form/EditForm'
import {billingTermSchema} from '@/modules/Settings/schema/billingTermSchema.js'

export default {
    name: "BillingTermForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
    },
    data() {
      return {

      }
    },
    computed: {
        schema() {
          return this.$helpers.schemaParser(billingTermSchema)

        },
        title() {
            return this.id ? "Uppdatera betalningsvilkor" : "Nytt betalningsvilkor";
        },
        subTitle() {
            return this.id ? "Här kan du redigera betalningsvikoret" : "Här fyller du i information om det nya betalningsvilkoret";
        },
        
    },
}
</script>

<style>

</style>