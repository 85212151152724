<template>
    <div>
        <h2 v-if="title" class="h3 mb-8 mt-2">{{title}}</h2>
        <component 
            v-for="(item, i) in blocks"
            :is="item.component" 
            v-bind="[item.props ? {...item.props} : '']"
            :key="i"
            class="mb-12"
        >
        </component>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        },
        blocks: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
}
</script>

<style>

</style>