<template>
    <div class="pImg">
        <img :src="'data:image/svg+xml;base64,'+base64" />
    </div>
  
</template>

<script>
export default {
    props: {
        base64: {
            type: String,
            default: ""
        },
    },
}
</script>

<style>

</style>