import BaseStore from "@/modules/baseStore.js";
import BaseApi from "@/modules/baseApi.js";



const state = {
  clientUserForm:{},
  A_user: [],
  O_user: {},

}


const api = BaseApi(state, "customer/client")

export default BaseStore.extend({
  state,
  api
})