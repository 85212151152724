<template>
    <v-row justify="center">
        <v-col cols="10">
            <client-form
                :id="id"
            ></client-form>
        </v-col>
    </v-row>
</template>

<script>
import ClientForm from '../components/ClientForm'

export default {
    name: "ClientEdit",
    components: {
        ClientForm
    },
    metaInfo: {
        title: 'Client'
    },
    props: {
        id: {
            type: String
        }
    }
}
</script>

<style>

</style>