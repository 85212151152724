import BaseStore from "@/modules/baseStore.js";
import BaseApi from "@/modules/baseApi.js";
//import usersService from '../api/usersService.js'

const state = {
  A_role: [],
  O_role: {}
}

const api = BaseApi(state, "aaa/role/")


export default BaseStore.extend({
  state,
  api
})