import BaseStore from "@/modules/baseStore.js";
import BaseApi from "@/modules/baseApi.js";

const state = {
  A_group: [],
  O_group: {},
  
  A_product: [],
  O_product: {},

  A_pricelist: [],
  O_pricelist: {},

  A_priceperiod: [],
  O_priceperiod: {},

  A_unit: [],
  O_unit: {},

  A_method: [],
  O_method: {},

  A_substance: [],
  O_substance: {},

  stateRules: {
    priceperiod: {
      endpointProps: ['pricelist']
    },

  }

}

const api = BaseApi(state, "sample/service")


export default BaseStore.extend({
  state,
  api
})