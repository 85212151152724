<template>

    <base-cta-list
        title="Kundavtal"
        subtitle="Här hittar du kundens avtal"
        :headers="headers"
        storeNamespace="clients"
        listNamespace="A_agreement"
        buttonText="Nytt avtal"
        :editEnabled="false"
        :inlineMode="true"
    >


         <template #dialog-content="{setDialog, dataId}"> 
             <client-agreement-form
                :listOnSave="true"
                @requestIsDone="setDialog(false)"
                :id="dataId"
             ></client-agreement-form>
         </template>

    </base-cta-list>



</template>

<script>
import ClientAgreementForm from './ClientAgreementForm.vue'
export default {
    name: "ClientAgreements",
    metaInfo: {
        title: 'Client Agreements'
    },
    components: {
        ClientAgreementForm,
    },
    data() {
      return {
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name',
            },
            {
                text: 'Valid from',
                align: 'start',
                sortable: true,
                value: 'date_valid_from',
            },
            {
                text: 'Valid to',
                align: 'start',
                sortable: true,
                value: 'date_valid_to',
     
            }
        ]
      }
    },
    methods: {

    },
    computed: {
       
    },
    created() {
      
    }
}
</script>

<style>

</style>