<template>
  <div>

      <base-stepper
        title="Manuell ankomst"
        v-slot="{changeStep, currentStep, previousStep}"
        :hideNavigator="true"
        :numOfSteps="4"
        :initialStep="1"
      >

        <v-stepper-items>
          
          <step-form-content
            title="Skapa en ny eller ladda en befintlig kund"
            subTitle=""
            :schema="schemaStep1"
            storeNamespace="arrivalsManual"
            objectNamespace="manualForm"
            :step="1"
            :clearStoreOnCreate="true"
            @step-change="changeStep"
          ></step-form-content>


          <v-stepper-content class="pa-0" 
              :step="2"
          >
            <v-lazy>
                <client-form-stepper
                    v-if="manualForm.type === 'new'"
                    :inlineMode="true"
                    @stepper-done="changeStep({action: 'next', curr: currentStep})"
                ></client-form-stepper>
                <jump-step 
                    v-else
                    :step="2"
                    :previousStep="previousStep"
                    :currentStep="currentStep" 
                    @step-change="changeStep"
                ></jump-step>
            </v-lazy>
          </v-stepper-content>


          <step-form-content
            title="Välj kund"
            subTitle=""
            :schema="schemaStep3"
            storeNamespace="clients"
            objectNamespace="O_client"
            :step="3"
            :currentStep="currentStep"
            :clearStoreOnCreate="true"
            @step-change="changeStep"
          ></step-form-content>

          <step-form-content
            title="Välj användare"
            subTitle=""
            :schema="schemaStep4"
            storeNamespace="clients"
            objectNamespace="O_user"
            :step="4"
            :currentStep="currentStep"
            :clearStoreOnCreate="true"
            @step-change="changeStep"
          ></step-form-content>


          <step-form-content
            title="Emballage information"
            subTitle=""
            :schema="schemaStep5"
            storeNamespace="arrivalsManual"
            objectNamespace="O_manual"
            :step="5"
            :currentStep="currentStep"
            :clearStoreOnCreate="true"
            :saveOnContinue="true"
            :lastStep="true"
            @step-change="changeStep"
            @step-done="onDone"
          ></step-form-content>

        </v-stepper-items>
      </base-stepper>

  </div>
</template>

<script>
import StepFormContent from '@/components/form/StepFormContent'
import ClientFormStepper from '@/modules/Clients/components/ClientFormStepper'
import JumpStep from '@/components/util/JumpStep'
import {mapGetters} from 'vuex'

//import PriceperiodStepper from '../components/PriceperiodStepper.vue'
export default {
    name: "ManualArrival",
    components: {
      StepFormContent,
      ClientFormStepper,
      JumpStep,
    },
    methods: {
      onDone() {
        this.$router.push('manual/scan')
      }
  
    },
    computed: {
        ...mapGetters('arrivalsManual', ['manualForm']),
        schemaStep1()  {
            const filter = {
                type: '{ type:"buttons", rules:"required", exclude:["save", "update"], buttonStyle:"compact", items:[{value: "new", icon:"mdi-account-plus", caption:"Skapa ny kund"}, {value: "load", icon:"mdi-upload", caption:"Ladda befintlig kund"}] }',
            }
            return this.$helpers.schemaParser(filter)
        },
        schemaStep3() {
            const filter = {
                id: '{type:"selectData", rules:"required", storeNamespace: "clients", objectNamespace:"A_client", itemText: "name"}',
            }
            return this.$helpers.schemaParser(filter)
        },
        schemaStep4() {
            const filter = {
                user_id: '{type:"selectData", rules:"required", storeNamespace: "clients", objectNamespace:"A_user", itemText: "name"}',
            }
            return this.$helpers.schemaParser(filter)
        },
        schemaStep5() {
            const filter = {
              user_id: '{type:"hiddenInput", rules:"required", storeNamespace: "clients", objectNamespace:"O_user", keyName: "user_id"}',
              client_id: '{type:"hiddenInput",  rules:"required", storeNamespace: "clients", objectNamespace: "O_client", keyName: "id"}',
              name: '{type:"input", rules:"required", label:"invoicemarking"}',
              reference: '{type:"input", rules:"required", label:"object"}',
              description: '{type:"input", rules:"required", label:"other"}',
            }
            return this.$helpers.schemaParser(filter)
        }
    }
}
</script>

<style>

</style>