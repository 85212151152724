<template>
  <update-card
    :tabs="tabs"
    :id="id"
    storeNamespace="pricepackages"
    objectNamespace="O_pricepackage"
    @visitedTabs="visitedTabs"
  >

  </update-card>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import UpdateCard from '@/components/card/UpdateCard'
import PricepackagePricelists from '@/modules/Service/components/Pricepackage/PricepackagePricelists'
export default {
    components: {
        UpdateCard,
    },
    props: {
        id: {
            type: String
        },
    },
    data() {
        return {
            tabsVisited: []
        }
    },
    computed: {
        tabs() {
            return [
                {
                    name: 'Grunduppgifter', 
                    icon: 'mdi-tray-full',
                    props: {
                        id: this.id,
                        initialLoad: this.tabsVisited.includes(0) ? true : false
                    }
                },
                {
                    name: 'Prislistor', 
                    icon: 'mdi-tray-full',
                    component: PricepackagePricelists
                }
            ]
        }
    },
    methods: {
        visitedTabs(value) {
            this.tabsVisited = value
        }
    },
}
</script>

<style>

</style>