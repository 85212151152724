<template>

    <base-cta-table
        title="Priceperiods"
        :headers="headers"
        storeNamespace="services"
        listNamespace="A_priceperiod"
        buttonText="New priceperiod"
        :hideTitle="true"
    >

        <template v-slot:row.price_normal="{ entity }">
            {{entity.price_normal}} {{entity.price_currency}}
        </template>

         <template #dialog-content="{setDialog}"> 
         

             <priceperiod-form
                :listOnSave="true"
                @requestIsDone="setDialog(false)"
             ></priceperiod-form>

         </template>

    </base-cta-table>



</template>

<script>
import PriceperiodForm from "./PriceperiodForm.vue"
export default {
    name: "PricelistPricePeriod",
    metaInfo: {
        title: 'Pricelist price period'
    },
    components: {
       PriceperiodForm
    },
    data() {
      return {
        headers: [
            {
                text: 'Valid from',
                align: 'start',
                sortable: true,
                value: 'date_valid_from',
            },
            {
                text: 'Valid to',
                align: 'start',
                sortable: true,
                value: 'date_valid_to',
            },
            {
                text: 'Price',
                align: 'start',
                sortable: true,
                value: 'price_normal',
                slot: true
     
            },

        ]
      }
    },
    methods: {

    },
    computed: {
       
    },
    created() {
      
    }
}
</script>

<style>

</style>