
let frontendUrl = window.location.origin + '/' + 'reset';
frontendUrl = frontendUrl.replaceAll(':', '^:')

export const clientUserSchema = {
    username: '{type:"input", exclude:["save"], hide:["save"]}',
    name_first: '{type:"input", rules:"required"}',
    name_last: '{type:"input", rules:"required"}',
    email: '{type:"input", rules:"required|email"}',
    phone: '{type:"input", rules:"required"}',
    frontend_url: '{type:"hiddenInput", rules:"required", exclude:["update"], initialValue:"'+ frontendUrl +'"}'
}