import BaseStore from "@/modules/baseStore.js";
import BaseApi from "@/modules/baseApi.js";

const state = {
    manualForm: {},

    O_manual: {},
    O_inner: {},

    stateRules: {
      inner: {
        endpointProps: ['manual']
      }
    }

}

const api = BaseApi(state, "sample/arrival")


export default BaseStore.extend({
  state,
  api
})