<template>
    <v-row>
        <v-col v-if="A_stats.stats_samples" cols="6">
            <base-card style="height:100%">
                <v-card-title>Daglig statistik</v-card-title>
                <v-card-text>
                    <apexchart
                        type="bar"
                        width="100%"
                        :options="testChartOptions"
                        :series="testSeries"
                    />
                </v-card-text>
            </base-card>
        </v-col>
    </v-row>
</template>
<script>

import VueApexCharts from 'vue-apexcharts'
import {mapGetters, mapActions} from 'vuex'
import {
    gradientLineChart,
    salesByCountries,
    visitorsAnalytic
} from '../data/sales2'
export default {
    name: 'AdminDashboard',
    components: {
        'apexchart': VueApexCharts
    },
    data() {
        return {
            gradientLineChart,
            salesByCountries,
            visitorsAnalytic,
            loading: false,
            series: [
                {
                    name: 'Daglig',
                    data: [0, 0, 0, 0, 0, 0, 0, 0]
                }
            ],
            testChartOptions: {
          chart: {
            id: 'vuechart-example'
          },
          xaxis: {
            categories: [1, 2, 3, 4, 5, 6, 7, 8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
          }
        },
            desserts: [
                {
                    orderId: '#0032',
                    img: require('@/assets/images/faces/1.jpg'),
                    customer: 'Telia AB',
                    date: '22 Mar 21, 6:30 PM',
                    price: 'Bas',
                    status: 'Registred',
                    actions: 'asd'
                },
                {
                    orderId: '#0033',
                    img: require('@/assets/images/faces/2.jpg'),
                    customer: 'Izytech AB',
                    date: '22 Mar 21, 6:30 PM',
                    price: 'Express',
                    status: 'Registred',
                    actions: 'asd'
                },
                {
                    orderId: '#0034',
                    img: require('@/assets/images/faces/3.jpg'),
                    customer: 'Izytech AB',
                    date: '22 Mar 21, 6:30 PM',
                    price: 'Answer Direct',
                    status: 'Registred',
                    actions: 'asd'
                },
                {
                    orderId: '#0035',
                    img: require('@/assets/images/faces/4.jpg'),
                    customer: 'BondeNet AB',
                    date: '22 Mar 21, 6:30 PM',
                    price: 'Bas',
                    status: 'Registred',
                    actions: 'asd'
                }
            ],
            items: [
                {
                    img: require('@/assets/images/faces/1.jpg'),
                    title: 'Dan Fox',
                    subtitle: 'ID: #02298',
                    text: '3200'
                },
                {
                    img: require('@/assets/images/faces/2.jpg'),
                    title: 'Jamie Fox',
                    subtitle: 'ID: #02298',
                    text: '3200'
                },
                {
                    img: require('@/assets/images/faces/3.jpg'),
                    title: 'Jhon Fox',
                    subtitle: 'ID: #02298',
                    text: '3200'
                }
            ]
        }
    },
    computed: {
       ...mapGetters('samples', ['A_stats']),
       testSeries() {
        return [{
          name: 'series-1',
          data: this.A_stats ? this.A_stats.stats_samples.hour.series.data : 0
        }]
       }
    },
    methods: {
        ...mapActions('samples', ['list_A_stats']),
        async getSampleStats() {
            this.loading = true;
            await this.list_A_stats();
            this.loading = false;
        },
    },
    created () {
        this.getSampleStats();
    }
}
</script>