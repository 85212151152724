var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-card',{staticClass:"px-6 py-4 mb-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('apexchart',{attrs:{"type":"radialBar","height":"250","options":_vm.customChartSeventeen.chartOptions,"series":_vm.percentageRegistred}})],1),_c('v-col',{attrs:{"cols":"12","md":"5","align-self":"center"}},[_c('div',{staticClass:"d-flex align-start justify-space-between"},[_c('div',{staticClass:"mb-3"},[_c('h5',{staticClass:"mr-2 mb-0"},[_vm._v(" OCAB "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"body-1",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-decagram")])]}}])},[_c('span',[_vm._v("Verified")])])],1),_c('p',{staticClass:"text--disabled mb-0"},[_vm._v("Emballage: "+_vm._s(_vm.id))])])]),_c('div',{staticClass:"d-flex mb-2"},[_c('p',{staticClass:"ma-0 mr-8"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.items.length)+" provpåsar")])]),_c('p',{staticClass:"ma-0 mr-8"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Vasagatan 2 ")])]),_c('p',{staticClass:"ma-0 mr-8"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("906 56 Umeå")])])]),_c('p',{staticClass:"text--disabled"},[_vm._v(" Provet skickades in av Jane Doe, kl.13.20 den 3 dec 2021. ")])]),_c('v-col',{attrs:{"md":"4"}},[_c('div',{staticClass:"text-right d-flex flex-column",staticStyle:{"height":"100%"}},[_c('div',[(_vm.allDone)?_c('v-chip',{staticClass:"green--text green lighten-5"},[_vm._v(" Allt klart! ")]):_c('v-chip',{staticClass:"red--text red lighten-5"},[_vm._v(" Ej klar ")])],1),_c('div',{staticClass:"d-flex flex-1 align-end justify-end"},[_c('v-btn',{staticClass:"primary--text",attrs:{"depressed":"","color":"lighten-5 primary","disabled":_vm.allScanned},on:{"click":function($event){_vm.qrCodeStreamEnable = true}}},[_vm._v(" Registrera provpåsar ")])],1)])])],1)],1),_c('base-card',{attrs:{"padding":true}},[_c('v-card-title',[_vm._v("Samples")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 eg-table-bnone eg-table-td-py-md",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.registred",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":item.registred ? 'green lighten-5' : 'red lighten-5',"size":"28"}},[_c('v-icon',{attrs:{"small":"","color":item.registred ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.registred ? 'mdi-check' : 'mdi-minus')+" ")])],1)]}},{key:"item.room",fn:function(ref){
var item = ref.item;
return [_c('h6',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.room)+" ")])]}},{key:"item.answer",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.answer ? 'green--text' : 'orange--text',attrs:{"color":item.answer ? 'green lighten-5' : 'orange lighten-5',"small":""}},[_vm._v(" "+_vm._s(item.answer ? 'Lämnat' : 'Ej lämnat')+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":""}},on),[_c('v-icon',{},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":!item.registred},on:{"click":function($event){return _vm.openItem(item)}}},[_c('v-icon',{staticClass:"body-1 mr-2"},[_vm._v("mdi-test-tube")]),_c('v-list-item-title',[_vm._v("Open sample")])],1)],1)],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.qrCodeStreamEnable),callback:function ($$v) {_vm.qrCodeStreamEnable=$$v},expression:"qrCodeStreamEnable"}},[_c('transition',{attrs:{"duration":300}},[(_vm.qrCodeStreamEnable)?_c('qr-scanner',{attrs:{"validationResult":_vm.validationResult,"numOfScanned":_vm.numOfScanned,"numOfMaxScan":_vm.numOfMaxScan,"allScanned":_vm.allScanned},on:{"closeScanner":function($event){_vm.qrCodeStreamEnable = false},"onResult":_vm.handleResult}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }