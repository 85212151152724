<template>
    <div>
           <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="primary"
      />
        <v-row no-gutters>
            <v-col cols="12" md="7" class="ma-0 pa-0 d-none d-md-flex">
                <div class="sign-three relative">
                    <div class="sign-three-overlay"></div>
                </div>
            </v-col>
            <v-col cols="12" md="5" class="ma-0 pa-0">
              <div class="d-flex justify-center align-center text-center  md:h-screen h-full">
                <v-row class="justify-center" no-gutters>
                  <v-col cols="8" lg="6" md="7" sm="5" class="pa-4">
                    <v-avatar class="mb-8" size="200" tile>
                        <img
                            src="@/assets/images/illustrations/breaking_barriers.svg"
                        />
                    </v-avatar>

                    <v-form v-model="isFormValid" @submit.prevent="signIn">
                        <v-text-field
                            prepend-icon="mdi-account"
                            label="Email"
                            autocapitalize="off"
                            v-model="email"
                            :rules="emailRules"
                            type="email"
                            required
                            tabindex="1"
                        />
                        <v-text-field
                            prepend-icon="mdi-account-lock"
                            label="Password"
                            :append-icon="
                                show ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="show ? 'text' : 'password'"
                            value=""
                            :rules="passwordRules"
                            v-model="ePassword"
                            @click:append="show = !show"
                             tabindex="2"
                        ></v-text-field>
                        <div class="my-4">
                          <v-divider></v-divider>
                        </div>
                        <v-btn
                          color="primary"
                          :disabled="!isFormValid"
                          type="submit"
                          >Sign In
                        </v-btn>

                    </v-form>
                    <div class="d-flex mt-8 justify-center">
                      <router-link 
                        to="/forgot"
                      >Forgot password?</router-link>
                    </div>

                  </v-col>
                </v-row>
              </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import eventBus from '@/plugins/eventbus.js'

export default {
    name: 'SignIn',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'SignIn'
    },
    data() {
        return {
            show: false,
            email: '',
            ePassword: '',
            isFormValid: false,
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            passwordRules: [
                (v) => !!v || 'Password is required',
                //(v) =>
                    //(v && v.length >= 6) ||
                    //'Name must be greater than 6 characters'
            ]
        }
    },
    computed: {
      ...mapGetters('auth', ['loggedInUser', 'error', 'isLoggedIn', 'loading'])
    },
    methods: {
       ...mapActions('auth', ['login']),
      signIn() {
        this.login({
          'username': this.email, 
          'password': this.ePassword
        })
      }
    },
    watch: {
      loggedInUser: function(val) {
        if (val) {
            // this.makeToast("success", "Successfully Logged In");
            this.$router.push('/dashboard')
            eventBus.$emit.snackMsg({
              "text": "Welcome " + val.userNameFirst + " " + val.userNameLast,
              "type": "success"
            });

        }
      },
      error(val) {
          if (val != null) {
            eventBus.$emit.snackMsg({
              "text": val,
              "type": "danger"
            });
          }
      }
    },
    created() {
      if(this.isLoggedIn) {
         this.$router.push('/dashboard')
      }
    }
}
</script>
<style lang="scss" scoped>
* {
    overflow-x: hidden;
}

.sign-three {
    color: #fff;
    width: 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 2rem;
    background: url('../../../assets/images/signin-left.jpg');
    position: relative;
    .sign-three-overlay {
        content: '';
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        background: rgba(0, 0, 0, 0.65);
        z-index: 1;
    }
}
</style>
