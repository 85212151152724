<template>
    <div>
        <v-autocomplete
            :placeholder="'Select ' + $t('fields.'+`${item.objectNamespaceOne}`)"
            :error-messages="errors"
            :label="$helpers.capFirstLetter($t('fields.'+`${item.objectNamespaceOne}`))"
            :value="selectedOne"
            :items="itemsOne"
            :item-text="item.itemTextOne"
            :item-value="item.itemValueOne ? item.itemValueOne : 'id'"
            :loading="isLoading"
            :disabled="isLoading"
            :multiple="item.multipleOne ? !!item.multipleOne : false"
            @change="build($event, 'one')"
            outlined
            clearable
            deletable-chips
            chips
        ></v-autocomplete>
        <v-autocomplete
            :placeholder="'Select ' + $t('fields.'+`${item.objectNamespaceTwo}`)"
            :error-messages="errors"
            :label="$helpers.capFirstLetter($t('fields.'+`${item.objectNamespaceTwo}`))"
            :value="selectedTwo"
            :items="itemsTwo"
            :item-text="item.itemTextTwo"
            :item-value="item.itemValueTwo ? item.itemValueTwo : 'id'"
            :loading="isLoading"
            :disabled="isLoading || selectedOne.length == 0"
            :multiple="item.multipleTwo ? !!item.multipleTwo : false"
            @change="build($event, 'two')"
            outlined
            clearable
            deletable-chips
            chips
        ></v-autocomplete>
        
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    name: "CrossSelectionData",
    props: {
        item: {
            type: Object,
            default: function() {
                return {}
            }
        },
        errors: {
            type:[String, Array],
            default: function() {
                return []
            }
        },
        value: {
            type:[String, Array],
            default: ''
        },
    },
    beforeCreate() {
        let storeNamespaceOne = this.$options.propsData.item.storeNamespaceOne;
        let objectNamespaceOne = this.$options.propsData.item.objectNamespaceOne;
   
        let storeNamespaceTwo = this.$options.propsData.item.storeNamespaceTwo;
        let objectNamespaceTwo = this.$options.propsData.item.objectNamespaceTwo;


        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters(storeNamespaceOne, [objectNamespaceOne]),
            ...mapGetters(storeNamespaceTwo, [objectNamespaceTwo]),

        }
        this.$options.methods = {
            ...this.$options.methods,
            
            ...mapActions(storeNamespaceOne, ['list_'+ objectNamespaceOne]),
            ...mapActions(storeNamespaceTwo, ['list_'+ objectNamespaceTwo])
        }
    },
    data () {
        return {
            isLoading: false,
        }
    },
    methods: {
   
        async getListData() {
            return Promise.all([
                this['list_'+this.item.objectNamespaceOne]({toggleLoad: false}),
                this['list_'+this.item.objectNamespaceTwo]({toggleLoad: false})
            ])
        },
        nsaBuilder(one, two) {
            
            let nsa = ["n", "n"];

            if(Array.isArray(one) && one.length > 0) {
                nsa[0] = "a"
            }
            else if(one && one.length > 0) {
                nsa[0] = "s"
            }
            if(Array.isArray(two) && two.length >0) {
                nsa[1] = "a"
            }
            else if(two && two.length > 0) {
                nsa[1] = "s"
            }

            return nsa.join("");

        },
        singleLoop(array, key, obj=false) {
            let arr = [];
            if(!obj) {
                for (let i = 0; i < array.length; i++) {
                    arr.push({
                        [key]:  array[i]
                    })
                }
            }
            else {
                for (let i = 0; i < array.length; i++) {
                    arr.push({
                        [key]:  array[i],
                        ...obj
                    })
                } 
            }
            return arr;
        },
        doubleLoop(one, two, keyOne, keyTwo) {
            let arr = [];
            for (let io = 0; io < one.length; io++) {
                for (let it = 0; it < two.length; it++) {
                    arr.push({
                        [keyOne]:  one[io],
                        [keyTwo]: two[it]
                    })
                }
            }
            return arr;
        },
        build(value, key) {

            let selectOne, selectTwo;
            let arr = [];

            if(key === 'one') {
                selectOne = value;
                selectTwo = this.selectedTwo;
    
            }
            else if(key === 'two') {
                selectOne = this.selectedOne;
                selectTwo = value;
            }

            //NSA
            let nsa = this.nsaBuilder(selectOne, selectTwo);

            switch (nsa) {
                case "an":
                    arr = this.singleLoop(selectOne, this.item.fieldItemOne)
                    break;
                case "sn":
                    arr.push({
                        [this.item.fieldItemOne]: selectOne
                    })
                    break;
                case "na":
                    arr = this.singleLoop(selectTwo, this.item.fieldItemTwo)

                    break;
                case "ns":
                    arr.push({
                        [this.item.fieldItemTwo]: selectTwo
                    })

                    break;
                case "aa":
                    arr = this.doubleLoop(selectOne, selectTwo, this.item.fieldItemOne, this.item.fieldItemTwo)

                    break;
                case "as":
                    arr = this.singleLoop(selectOne, this.item.fieldItemOne, {[this.item.fieldItemTwo]: selectTwo})

                    break;
                case "sa":
                    arr = this.singleLoop(selectTwo, this.item.fieldItemTwo, {[this.item.fieldItemOne]: selectOne})

                    break;
                case "ss":
                    arr.push({
                        [this.item.fieldItemOne]: selectOne,
                        [this.item.fieldItemTwo]: selectTwo
                    })

                    break;
                default:
                    break;
            }
            
            //SEND TO PARENT
            this.$emit('selection', arr)

        }
 
    },
    computed: {
        
        itemsOne() {
            return this[this.item.objectNamespaceOne];
        },
        itemsTwo() {
            return this[this.item.objectNamespaceTwo];
        },
        selectedOne() {
            const one = this.$helpers.removeDuplicates(this.value, this.item.fieldItemOne)
            if(this.item.multipleOne) {
                return one;
            }
            else if(one.length > 0) {
                return one[0]
            }
            else {
                return "";
            }
        },
        selectedTwo() {
            const two = this.$helpers.removeDuplicates(this.value, this.item.fieldItemTwo)
            if(this.item.multipleTwo) {
                return two;
            }
            else if(two.length > 0) {
                return two[0]
            }
            else {
                return "";
            }
        },
       
    },
    async created() { 
       
        this.isLoading = true;
        await this.getListData();
        this.isLoading = false;

       
    }
}
</script>

<style>

</style>