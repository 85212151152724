<template>
    <div class="page-wrap">
        <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="primary"
      />
        <v-row no-gutters>
            <v-col cols="12" class="ma-0 pa-0">
              <div class="d-flex justify-center align-center text-center  md:h-screen h-full">
                    <div class="session-form-hold">
                    <v-card
                        elevation="2"
                        class="pa-4"
                    >
                        <v-avatar class="mb-8" size="200" tile>
                            <img
                                src="@/assets/images/illustrations/breaking_barriers.svg"
                            />
                        </v-avatar>
                        <h1 class="h5 text--disabled font-weight-medium mb-0">Sätt ditt lösenord</h1>
                        <p class="text--disabled font-weight-medium mb-10">Ange e-postadress för kontot och nytt lösenord</p>
                        <v-form v-model="isFormValid" @submit.prevent="reset">
                            <v-text-field
                                prepend-icon="mdi-account"
                                label="Email"
                                v-model="email"
                                autocapitalize="off"
                                type="email"
                                :rules="emailRules"
                                required
                                tabindex="1"
                            />
                            <v-text-field
                                prepend-icon="mdi-account-lock"
                                label="New password"
                                :append-icon="
                                    showEPassword ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                :type="showEPassword ? 'text' : 'password'"
                                value=""
                                :rules="passwordRules"
                                v-model="ePassword"
                                @click:append="showEPassword = !showEPassword"
                                tabindex="2"
                            ></v-text-field>
                            <v-text-field
                                prepend-icon="mdi-account-lock"
                                label="Confirm password"
                                :append-icon="
                                    showEPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                :type="showEPasswordConfirm ? 'text' : 'password'"
                                value=""
                                :rules="passwordConfirmRules.concat(passwordConfirmationRule)"
                                v-model="ePasswordConfirm"
                                @click:append="showEPasswordConfirm = !showEPasswordConfirm"
                                tabindex="2"
                            ></v-text-field>
                            <div class="my-4">
                            <v-divider></v-divider>
                            </div>
                            <v-btn
                            color="primary"
                            :disabled="!isFormValid"
                            type="submit"
                            >Submit
                            </v-btn>

                        </v-form>
                    </v-card>
                    </div>
              </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import eventBus from '@/plugins/eventbus.js'
import authService from '../api/authService.js'

export default {
    name: 'Reset',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Reset password'
    },
    props: {
        token: {
            type: String
        },
    },
    data() {
        return {
            showEPassword: false,
            showEPasswordConfirm: false,
            email: '',
            ePassword: '',
            ePasswordConfirm: '',
            isFormValid: false,
            loading: false,
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            passwordRules: [
                (v) => !!v || 'Password is required',
                //(v) =>
                    //(v && v.length >= 6) ||
                    //'Name must be greater than 6 characters'
            ],
            passwordConfirmRules: [
                (v) => !!v || 'Password is required',
                //(v) =>
                    //(v && v.length >= 6) ||
                    //'Name must be greater than 6 characters'
            ],
            
        }
    },
    computed: {
        passwordConfirmationRule() {
            return () =>
                this.ePassword === this.ePasswordConfirm || "Password must match";
        }
            
    },
    methods: {
      reset() {
        this.loading = true;
        const data = {
            "username": this.email,
            "password": this.ePassword,
        }  
        authService.reset(data, this.token)
        .then(() => {
            this.$router.push('/sign-in')
        })
        .catch(() => {
            eventBus.$emit.snackMsg({
              "text": "Couldn't reset password. Please try again",
              "type": "danger"
            });
        })
        .finally(() => {
            this.loading = false;
        })
      }
    },

}
</script>
<style lang="scss" scoped>
    .session-form-hold {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
    }
    .page-wrap {
        background-color: #242939 !important;
        display: flex;
        align-items: center;
        padding: 40px 1rem;
        height: 100%;
        min-height: 100vh;
    }

</style>
