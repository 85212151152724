<template>
    <div>

        <base-action-list
            title="Roller"
            subtitle="Här ser du vilka roller som finns upplagda i Labflow service."
            :headers="headers"
            storeNamespace="aaa"
            listNamespace="A_role"
            :icon="rolesLogo"
            :newEnabled="false"
            :editEnabled="false"
        >
                        

        </base-action-list>
    </div>
</template>

<script>


export default {
    name: "Roles",
    metaInfo: {
        title: 'Roler'
    },
    data() {
      return {
        headers: [
            {
                text: 'Roll namn',
                align: 'start',
                sortable: false,
                value: 'name'
            },
        ],
        rolesLogo: require('@/assets/images/svg/secret.svg')
      }
    },
}
</script>

<style>

</style>