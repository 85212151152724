import Auth from './pages/Auth.vue'
import SignIn from './pages/SignIn.vue'
import Forgot from './pages/Forgot.vue'
import Reset from './pages/Reset.vue'
import AuthStore from './store/auth.js'


// eslint-disable-next-line no-unused-vars
export function authModule({ app, router, store, eventBus }) {

	/**
	 * Register routes
	 */
	router.addRoutes([{
		path: '/auth',
		component: Auth,
		children: [
			{
				name: 'sign-in',
				path: '/sign-in',
				component: SignIn 
			},
			{
				name: 'forgot',
				path: '/forgot',
				component: Forgot 
			},
			{
				name: 'reset',
				path: '/reset/:token',
				component: Reset,
				props: true 
			}
		]
	}]);  

	/**
	 * Register store
	 */
	store.registerModule('auth', AuthStore)


	/**
	 * Register events
	 */
	eventBus.$on.logIn(function() {
		console.log("log in event happend");
	})

	eventBus.$on.doSignOut(function() {
		store.dispatch('auth/signOut');
	})

}





