<template>
    <div>
        <v-row justify="center">
            <v-col cols="10">

                <edit-form
                    title="Edit contact"
                    subTitle="Here you set the contact to a client"
                    :schema="schema"
                    storeNamespace="clients"
                    objectNamespace="O_permission"
                    listNamespace="A_permission"
                    :sectionTitles="sectionTitles"
                    :objectId="id"
                    :arrayMode="true"
                >
                    <template v-slot:headerSlot>
                        <div class="text-center mb-10">
                            <h3 class="mb-4">Contact</h3>
                            <p>
                                Here you can create/-or edit a contact
                            </p>
                        </div>

                    </template>
                </edit-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import EditForm from '@/components/form/EditForm'

export default {
    name: "ContactForm",
    components: {
        EditForm,
    },
    metaInfo: {
        title: 'Contact'
    },
    props: {
        id: {
            type: String
        }
    },
    data() {
      return {
        sectionTitles: [
            'Pick organisation',
            'Title 2',
            'Title 3'
        ]
      }
    },
    methods: {
  
    },
    computed: {

        
       schema() {
            /*
            const filter = {
                name_first: ['type:input', 'rules:required'],
                name_last: ["type:input", 'rules:required'],
            }
            */
            const filter = {
                name_first: ['type:input', 'rules:required'],
                permissions: ['type:crossSelectionData', 'rules:required', 'storeNamespaceOne: users', 'objectNamespaceOne: A_user', 'itemTextOne: name', 'fieldItemOne: role', 'storeNamespaceTwo: roles', 'objectNamespaceTwo: A_role', 'itemTextTwo: name', 'fieldItemTwo: id', 'multipleTwo: true' ],
                numbers: '{ type:"input", rules:"required|numeric|min_value^:1", inputType:"number" }',
                //user: ['type:selectData', 'rules:required', 'items:[person,organisation]', 'storeNamespace: users', 'objectNamespace: users', 'itemText: name'],
                //roles: ['type:selectData', 'rules:required', 'items:[person,organisation]', 'storeNamespace: roles', 'objectNamespace: roles', 'itemText: name', 'multiple:true'],
            }
            return this.$helpers.filterAndAssign([], filter)

            /*
            const filter = {
              type: ["type:buttons", 'rules:required', 'items:[person,organisation]', 'section:1', 'order:1'],
              name_first: ['type:input', 'rules:required', 'conditions:type.person', 'section:2', 'order:1'],
              name_last: ["type:input", 'rules:required', 'conditions:type.person', 'section:2', 'order:2'],
              name_organisation: ["type:input", 'rules:required', 'conditions:type.organisation', 'section:2', 'order:1'],
              authority_id: ["type:input", 'rules:required', 'conditions:type.organisation|person', 'section:2', 'order:3'],
              vat_id: ["type:input", 'rules:required', 'conditions:type.organisation', 'section:2', 'order:2'],
            }

            //permission: '{type:"arrayData", storeNamespace:"clients", objectNamespace:"O_permission", rules:"required", listNamespace:"A_permission", schema:[{field: "person", type: "input", rules:"required"},{field: "name", type: "input", rules:"required"},{field:"select", type:"select", items: ["one", "two", "three"], itemText: "name", multiple: "true", rules:"required"},{field:"select2", type:"select", items: ["a", "b", "z"], multiple: "true", rules:"required"}]}',
            //permissions: '{type:"arrayData", storeNamespace:"clients", objectNamespace:"O_permission", rules:"required", listNamespace:"A_permission", schema:[{field:"id_group", type:"selectData", rules:"required", storeNamespace: "clients", objectNamespace: "A_group", itemText: "name", multiple:"true"},{field:"id_user", type:"selectData", rules:"required", storeNamespace: "users", objectNamespace: "A_user", itemText: "name", multiple:"true"}, {field: "id_client", type: "hiddenInput", rules: "required", storeNamespace: "clients", objectNamespace: "O_client", keyName: "id"}]}'


            //  type: '{ type:"buttons", rules:"required", exclude:["save", "update"], saveOnContinue:["new"], buttonStyle:"compact", items:[{value: "new", icon:"mdi-account-plus", caption:"Create a new user account"}, {value: "load", icon:"mdi-upload", caption:"Assign an existing user account to the client"}] }',
            */

        },
    },
    created() {
      
    }
}
</script>

<style>

</style>