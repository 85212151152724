import eventBus from '@/plugins/eventbus.js'
import profileService from '../api/profileService.js'
import storageService from '@/services/storageService.js';


const state = {


  userProfile: null,
  organization: 
    storageService.getOrganization() != null &&
    storageService.getOrganization().length > 0 
    ? storageService.getOrganization()
    : null,
  dashboardView: 
    storageService.getDashboardView() != null
    ? storageService.getDashboardView()
    : null,
    loading: false,
    error: null,

}


const getters = {
    userProfile: state => state.userProfile,
    organization: state => state.organization,
    dashboardView: state => state.dashboardView,

    userProfileExist: state => state.userProfile ? true : false,
    dashAndOrgExist: state => (state.dashboardView && state.organization) ? true : false,
    //dashboardIsValid: () => true,
 
    dashboardIsValid: state => {
        if(state.userProfile && state.dashboardView && state.organization) {
            //Check if organization exist in userProfile
            const org =  state.userProfile.groups.filter(o => o.client_id === state.organization);
            
            if(org.length > 0) {
                //and check that dashboardview exist in picked organization
                if(org.some(el => el.id === state.dashboardView.id)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
            
        }
        else {
            return false;
        }
    },

    loading: state => state.loading,
    error: state => state.error,
  
}

const actions = {
  
    async getUser({commit}) {
        try {
            const { data } = await profileService.getUser();
            commit("SET_USER_PROFILE", data);
        }
        catch(err) {
            eventBus.$emit.doSignOut();
        }
    },
    // eslint-disable-next-line no-unused-vars
    async updateUser({commit, dispatch}, data) {
        dispatch('changeThemeLoadingState', true, {root:true})
        try {
            await profileService.updateUser(data);
            await dispatch('getUser')
        }
        catch(err){
            console.error(err);
        }
        finally {
            setTimeout(() => dispatch('changeThemeLoadingState', false, {root:true}), 500);
        }
    },

    setOrganization({commit}, data) {
        storageService.setOrganization(data)
        commit("SET_ORGANIZATION", data)
    },
    setDashboardView({commit}, data) {
        storageService.setDashboardView(data)
        commit("SET_DASHBOARDVIEW", data)
    },
    clearAll({commit}) {
        commit("CLEAR_ALL")
    }
}

const mutations = {
    SET_USER_PROFILE(state, data) {
        state.userProfile = data;
    },
    SET_ORGANIZATION(state, data) {
        state.organization = data;
        state.dashboardView = null;
    },
    SET_DASHBOARDVIEW(state, data) {
        state.dashboardView = data;
    },
    SET_LOADING(state, data) {
        state.loading = data;
        state.error = null;
    },
    SET_ERROR(state, data) {
        state.error = data;
        state.loggedInUser = null;
        state.loading = false;
    },
    CLEAR_ERROR(state) {
        state.error = null;
    },
    CLEAR_ALL(state) {
        state.organization = null;
        state.dashboardView = null;
        state.userProfile = null;
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}