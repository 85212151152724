<template>
    <v-select
        :placeholder="'Select ' + $t('fields.'+`${item.field}`)"
        :error-messages="errors"
        :label="$helpers.capFirstLetter($t('fields.'+`${item.field}`))"
        :value="value"
        :items="items"
        :item-text="item.itemText"
        :item-value="item.itemValue ? item.itemValue : 'id'"
        :multiple="item.multiple ? !!item.multiple : false"
        v-on="$listeners"
        outlined
        :clearable="false"
        :deletable-chips="item.multiple ? !!item.multiple : false"
        :chips="item.multiple ? !!item.multiple : false"
        :return-object="item.returnObject ? true : false"
        :readonly="true"
        class="disabled-input"
    ></v-select>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: "ReadOnly",
    props: {
        item: {
            type: Object,
            default: function() {
                return {}
            }
        },
        errors: {
            type:[String, Array],
            default: function() {
                return []
            }
        },
        value: {
            type:[String, Array],
            default: ''
        },
        triggerReload: {
            type: Number,
            default: 0
        },
    },
    beforeCreate() {
        let storeNamespace = this.$options.propsData.item.storeNamespace;
        let objectNamespace = this.$options.propsData.item.objectNamespace;

        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters(storeNamespace, [objectNamespace]),

        }
    },
    methods: {
        setValue() {

            const key = this.item.itemValue ? this.item.itemValue : 'id'
            this.$emit('updated', this.items[0][key]);
        }
    },
    computed: {
        items() {
            return [
                this[this.item.objectNamespace]
            ]
            
        }
    },
    watch: {
        onResetForm: {
            handler() {
                this.setValue();
            }
        },
        triggerReload: {
            handler() {
                this.setValue();
            }
        }
    },
    mounted () {
        this.setValue();
    },

}
</script>

<style>

</style>