<template>

    <base-cta-list
        title="Användarkonton"
        subtitle="Här hittar du samtliga användarkonton i plattformen"
        :headers="headers"
        storeNamespace="customer"
        listNamespace="A_users"
        :icon="userLogo"
        :editEnabled="false"
        :newEnabled="false"
        :exportData="true"
    >
        <template v-slot:row.clients="{ entity }">

                <span v-for="(client, index) in entity.clients" :key="`client-${index}`">
                    {{client.name}}
                </span>  

        </template>

        <template v-slot:row.type="{ entity }">

            <span v-for="(client, index) in entity.clients" :key="`client-${index}`">
                {{client.type}}
            </span>  

        </template>

        <template v-slot:row.permissions="{ entity }">

            <span v-for="(permission, index) in entity.permissions" :key="`permission-${index}`">
                {{permission}}
            </span>  

        </template>
        

    </base-cta-list>

</template>

<script>
export default {
    name: "ClientUsers",
    metaInfo: {
        title: 'Client Users'
    },
    data() {
      return {
        headers: [
            {
                text: 'Förnamn',
                align: 'start',
                sortable: false,
                value: 'name_first',
            },
            {
                text: 'Efternamn',
                align: 'start',
                sortable: false,
                value: 'name_last',
            },
            {
                text: 'E-post',
                align: 'start',
                sortable: false,
                value: 'email',
            },
            {
                text: 'Kunder', 
                value: 'clients',
                sortable: false,
                slot: true,
            },
            {
                text: 'Typ', 
                value: 'type',
                sortable: false,
                slot: true,
            },
            {
                text: 'Behörigheter', 
                value: 'permissions',
                sortable: false,
                slot: true,
            },
        ],
        userLogo: require('@/assets/images/svg/man.svg')
      }
    }
}
</script>

<style>

</style>