<template>
    <div>

        <zebra-qr-scanner
            @code="onQrCode"
        >
            <template #default="{isQrFocused}"> 
                <div class="d-flex items-center justify-center mt-6">
                    <h2 v-if="isQrFocused">Väntar på ny provpåse...</h2>
                </div>
            </template>  
        </zebra-qr-scanner>

        <v-dialog
            v-model="dialog"
            max-width="600px"
        >
            <edit-form
                title="New bag"
                subTitle="Enter details about the bag"
                :schema="schema"
                storeNamespace="arrivalsManual"
                objectNamespace="O_inner"
                :stateRulesIds="endpointProps"
                :clearStoreOnCreate="false"
                :resetFormEnabled="false"
                :resetOnDone="true"
                @requestIsDone="onSaved"
            >
    
            </edit-form>   
        </v-dialog>

        <izy-card
            :title="O_manual.client.name"
            :subtitle="O_manual.outerbag.registered_by.name"
            :headerColumn="true"
        >
            <template v-slot:header>
                <div class="d-flex mt-2">

                    <p class="pr-8 mb-0">Fakturamärkning: {{O_manual.outerbag.name}}</p>
                    <p class="pr-8 mb-0">Objekt: {{O_manual.outerbag.reference}}</p>
                    <p class="mb-0">Övrigt: {{O_manual.outerbag.description}}</p>
                </div>
             </template>
            <base-data-table
                title="Inner bags"
                :headers="headers"
                :list="innerBags"
            >
                <template v-slot:row.product="{ entity }">
                    {{entity.product.name}}
                </template>

            </base-data-table>
        </izy-card>

        
        


        

    </div>
</template>

<script>
import EditForm from '@/components/form/EditForm'
import {mapGetters, mapMutations} from 'vuex'
import ZebraQrScanner from '@/components/util/ZebraQrScanner'
import IzyCard from '@/components/card/IzyCard'

export default {
    data() {
        return {
            isQrFocused: false,
            dialog: false,
            innerQrCode: "",
            headers: [
                {
                    text: 'Id',
                    sortable: false,
                    value: 'id'
                },
                {
                    text: 'Produkt',
                    sortable: false,
                    value: 'product',
                    slot: true
                },
                {
                    text: 'Kö',
                    sortable: false,
                    value: 'queue',
                },
                {
                    text: 'Site',
                    sortable: false,
                    value: 'site',
                },
            ]
        }
    },
    components: {
        EditForm,
        ZebraQrScanner,
        IzyCard

    },
    computed: {
        ...mapGetters('arrivalsManual', ['O_manual']),
        endpointProps() {
            return this.O_manual && this.O_manual.outerbag ? {manual:this.O_manual.outerbag.id} : {}
        },
        innerBags() {
            return this.O_manual && this.O_manual.outerbag && this.O_manual.outerbag.inner_bags ? this.O_manual.outerbag.inner_bags : []
        },
        schema() {
            
            const filter = {
                inner_bag_id: '{type:"input", rules:"required", readonly: true}',
                price_period_id: '{type:"selectLocalData", rules:"required", storeNamespace: "arrivalsManual", objectNamespace: "O_manual", keyNamespace: "products", itemText: "name"}',
                queue_id: '{type:"selectLocalData", rules:"required", storeNamespace: "arrivalsManual", objectNamespace: "O_manual", keyNamespace: "queue_ids", keyParents:["products"], keyFilter: "price_period_id", keyFilterNamespace:"O_inner", itemText: "name"}',
                name: '{type:"input", rules:"required", label:"sample_name"}',
                room: '{type:"input", rules:"required"}',
                material: '{type:"input", rules:"required"}',
            }
            
            return this.$helpers.schemaParser(filter)
        },
    },
    methods: {
        ...mapMutations('arrivalsManual', ['SET_O_INNER', 'CLEAR_O_INNER', 'SET_O_MANUAL']),

        onQrCode(qrCode) {
            

            if(qrCode.type === 'inner') {
                this.SET_O_INNER({inner_bag_id: qrCode.id});
                this.setDialog(true)
                this.innerQrCode = "";
            }
         
        },
        setDialog(value) {
            this.dialog = value;
        },
        onSaved(res) {
            this.SET_O_MANUAL(res)
            this.setDialog(false)
        }
    },
    watch: {
        dialog: {
            handler(val) {
                if(!val) {
                    this.focusQrInput;
                }
            }
        }
    },
    mounted() {
        this.CLEAR_O_INNER()
    }
}
</script>
