import { render, staticRenderFns } from "./ClientAgreements.vue?vue&type=template&id=d55a02e4"
import script from "./ClientAgreements.vue?vue&type=script&lang=js"
export * from "./ClientAgreements.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports