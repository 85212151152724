
/*
import Envelopes from './pages/Envelopes.vue'
import Result from './pages/Result.old.vue'
import Results from './pages/Results.vue'



import SampleDemo from './pages/SampleDemo.vue'
import EnvelopeDemo from './pages/EnvelopeDemo.vue'

import ArrivalRegistry from './pages/ArrivalRegistry.vue'
import QueueRegistryDemo from './pages/QueueRegistryDemo.vue'
import EnvelopeRegistryDemo from './pages/EnvelopeRegistryDemo.vue'
import SampleRegistryDemo from './pages/SampleRegistryDemo.vue'
*/
import EnvelopeDemo from './pages/EnvelopeDemo.vue'
import SamplingStore from './store/sampling.js'
import DemoStore from './store/demo.js'
import SampleDemo from './pages/SampleDemo.vue'

// eslint-disable-next-line no-unused-vars
export function samplingModule({ app, router, store, eventBus }) {

	router.addRoute('Home', { 
		path: 'sampling', 
		name:'sampling', 
		component: () => import("@/components/ParentNode"), 
		redirect: to => {return to.path + '/list'}
	})
	/*
	router.addRoute('sampling', { path: 'list', component: Envelopes })
	router.addRoute('sampling', { path: 'results', component: Results })
	router.addRoute('sampling', { path: 'result', component: Result })





	router.addRoute('sampling', { path: 'arrival', component: ArrivalRegistry })
	router.addRoute('sampling', { path: 'queue', component: QueueRegistryDemo })
	router.addRoute('sampling', { path: 'envelope-registry', component: EnvelopeRegistryDemo })
	router.addRoute('sampling', { path: 'sample-registry', component: SampleRegistryDemo })


	router.addRoute('sampling', { name: 'envelope', path: 'envelope/:id', component: EnvelopeDemo, props: true })
	router.addRoute('sampling', { name: 'sampled', path: 'sample/:id', component: SampleDemo, props: true  })
	*/

	router.addRoute('sampling', { name: 'envelope', path: 'envelope/:id', component: EnvelopeDemo, props: true })
	router.addRoute('sampling', { name: 'sampled', path: 'sample/:id', component: SampleDemo, props: true  })




	/**
	 * Register store
	 */
	store.registerModule('sampling', SamplingStore)

	store.registerModule('demo', DemoStore)

}