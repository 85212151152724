var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-cta-list',{attrs:{"title":"Kontaktuppgifter kund","subtitle":"Här finner du kundens olika kontaktuppgifter.","headers":_vm.headers,"storeNamespace":"clients","listNamespace":"A_contact","buttonText":"Ny kontaktuppgift","inlineMode":true,"modalSize":"95%","modalScrollable":true},scopedSlots:_vm._u([{key:"row.name_organisation",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" "+_vm._s(entity.name_organisation.length > 0 ? entity.name_organisation : entity.name_first + ' ' + entity.name_last)+" ")]}},{key:"row.binding",fn:function(ref){
var entity = ref.entity;
return _vm._l((entity.binding),function(binding){return _c('span',{key:binding.id,staticClass:"contact-type"},[_c('span',[_vm._v(" "+_vm._s(_vm.$helpers.capFirstLetter(_vm.$t('fields.'+"" + (binding.type))))+", "+_vm._s(_vm.$helpers.capFirstLetter(_vm.$t('fields.'+"" + (binding.order))))+", "+_vm._s(_vm.$helpers.capFirstLetter(_vm.$t('fields.'+"" + (binding.destination))))+" ")])])})}},{key:"row.name_first",fn:function(ref){
var entity = ref.entity;
return [_vm._v(" "+_vm._s(entity.name_first + ' ' + entity.name_last)+" ")]}},{key:"dialog-content",fn:function(ref){
var setDialog = ref.setDialog;
var dataId = ref.dataId;
return [_c('client-contact-form',{attrs:{"listOnSave":true,"id":dataId},on:{"requestIsDone":function($event){return setDialog(false)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }