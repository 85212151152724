import VuePerfectScrollbar from "vue-perfect-scrollbar";

// theme Main scss
import "@/assets/scss/octavia-design-system/octavia-design-system.scss";

//Vue Navigation Bar
import "vue-navigation-bar/dist/vue-navigation-bar.css";
import VueNavigationBar from "vue-navigation-bar";

export default {
  install(Vue) {
    Vue.component("vue-navigation-bar", VueNavigationBar);
    Vue.component(
      "HorizontalBar",
      () => import("../layouts/horizontal/Index")
    );
    Vue.component(
      "VerticalSidebar",
      () => import("../layouts/vertical/Index")
    );

    Vue.component(
      "VerticalWhiteSidebar",
      () => import("../layouts/vertical-white/Index")
    );

    Vue.component(
      "VerticalCompactSidebar",
      () => import("../layouts/vertical-compact-layout/Index")
    );
    Vue.component(
      "VerticalSaasLayout",
      () => import("../layouts/vertical-saas-layout/Index")
    );
    Vue.component("VuePerfectScrollbar", VuePerfectScrollbar);
  }
};
