<template>
    <div>
        <div v-show="!isSaving">
            <form-generator
                v-if="!isLoading"
                ref="formGenerator"
                :schema="schema"
                :form="form"
                :observerDisabled="observerDisabled"
                :arrayMode="arrayMode"
                :isUpdateMode="isUpdate"
                :sectionTitles="sectionTitles"
                :resetFormTimestamp="resetFormTimestamp"
                @is-valid="setValidStatus"
                @set-field-value="setFieldValue"
            ></form-generator>
            <div class="text-center my-4" v-else>
                <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </div>
        </div>
        <div class="text-center my-4" v-show="isSaving">
            <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
            ></v-progress-circular>
        </div>
    </div>
    
    
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
import FormGenerator from './FormGenerator';

export default {
    name: "VuexFields",
    components: {
        FormGenerator
    },
    props: {
        schema: {
            type: Array,
            default: function() {
                return []
            }
        },
        storeNamespace: {
            type:String,
            default: ''
        },
        objectNamespace: {
            type:String,
            default: ''
        },
        listNamespace: {
            type:String,
            default: null
        },
        objectId: {
            type: String,
            default: null
        },
        observerDisabled: {
            type: Boolean,
            default: false
        },
        arrayMode: {
            type: Boolean,
            default: false
        },
        sectionTitles: {
            type: Array,
            default: function() {
                return []
            }
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
        listObjectNamespace: {
            type: String
        },
        listStoreNamespace: {
            type: String
        },
        refreshObjectNamespace: {
            type: String
        },
        refreshStoreNamespace: {
            type: String
        },
        resAction: {
            type: Boolean,
            default: true
        },
        clearStoreOnCreate: {
            type: Boolean,
            default: true
        },
        stateRulesIds: {
            type: Object,
            default: function() {
                return {}
            }
        },
    },
    beforeCreate() {
        let storeNamespace = this.$options.propsData.storeNamespace;
        let objectNamespace = this.$options.propsData.objectNamespace;
        let objectNamespaceUpperCase = objectNamespace.toUpperCase();

        const splitObjectNamespace = objectNamespace.split('_');
        
        let stateRules = (this.$store.getters[storeNamespace + '/stateRules'] && splitObjectNamespace[1] in this.$store.getters[storeNamespace + '/stateRules']) ? this.$store.getters[storeNamespace + '/stateRules'][splitObjectNamespace[1]] : false;

        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters(storeNamespace, [objectNamespace]),
            stateRules: () => stateRules,
        }

        this.$options.methods = {
            ...this.$options.methods,
            ...mapMutations(storeNamespace, ['SET_'+objectNamespaceUpperCase, 'CLEAR_'+objectNamespaceUpperCase]),
            ...mapActions(storeNamespace, ['create_'+ objectNamespace, 'get_'+ objectNamespace, 'update_'+ objectNamespace])
        }

        if(this.$options.propsData.arrayMode && this.$options.propsData.listNamespace) {
            let listNamespace = this.$options.propsData.listNamespace;
            
            this.$options.computed = {
                ...this.$options.computed,
                ...mapGetters(storeNamespace, [listNamespace]),

            }
            this.$options.methods = {
                ...this.$options.methods,
                ...mapActions(storeNamespace, ['list_'+ listNamespace]),
            }
        }

    },
    data() {
        return {
            editItem: false,
            resetFormTimestamp: 0,
            isValid: false,
            isLoading: false,
            isSaving: false,
        }
    },
    methods: {
        async getItem() {
            try {
                this.isLoading = true;
                await this['get_'+this.objectNamespace]({id:this.objectId});
            } catch (error) {
                console.error(error);
            }
            this.isLoading = false;
        },
        async saveItem() {
            this.isSaving = true;
            const saveAction = this.dataId ? "update" : "save";
            const formToSave = this.$helpers.cleanForm(this.schema, this[this.objectNamespace], saveAction);

            if(!this.dataId) {

                try {
                    const res = await this['create_'+this.objectNamespace]({
                        input:formToSave, 
                        list: this.listOnSave,
                        resAction: this.resAction,
                        ...(this.listObjectNamespace && this.listObjectNamespace.length > 0 && {listObjectNamespace: this.listObjectNamespace}),
                        ...(this.listStoreNamespace && this.listStoreNamespace.length > 0 && {listStoreNamespace: this.listStoreNamespace}),
                        ...(this.refreshObjectNamespace && this.refreshObjectNamespace.length > 0 && {refreshObjectNamespace: this.refreshObjectNamespace}),
                        ...(this.refreshStoreNamespace && this.refreshStoreNamespace.length > 0 && {refreshStoreNamespace: this.refreshStoreNamespace}),
                        ...(!this.$helpers.isObjectEmpty(this.stateRulesIds) && {ids:this.stateRulesIds})
                    })
                    this.$emit('request-done', res.data)
                } catch (error) {
                    console.log(error)
                    console.log("error");
                }
    
            }
            else {
                console.log("update")
                try {
                    await this['update_'+this.objectNamespace]({
                        input:formToSave, 
                        id:this.dataId, 
                        list: this.listOnSave,
                        ...(this.refreshObjectNamespace && this.refreshObjectNamespace.length > 0 && {refreshObjectNamespace: this.refreshObjectNamespace}),
                        ...(this.refreshStoreNamespace && this.refreshStoreNamespace.length > 0 && {refreshStoreNamespace: this.refreshStoreNamespace}),
                    });
                    this.$emit('request-done')
                }
                catch (error) {
                    console.log("error");
                }
            }
            this.isSaving = false;
        },
        setFieldValue(input) {
            const {field, value} = input;

            if(field === 'obj') {
                this['SET_'+this.objectNamespace.toUpperCase()](value);
            }
            else {
                this['SET_'+this.objectNamespace.toUpperCase()]({[field]: value});
            }
        },
        async resetForm() {
            const currId = this.isUpdate && (this.form && this.form.id) ? this.form.id : false;
            this['CLEAR_'+this.objectNamespace.toUpperCase()]()
            this.resetFormTimestamp = Date.now();

            if(currId) {
                this.setFieldValue({value:currId, field:'id'})
            }
        },
        triggerReload() {
            this.$refs.formGenerator.doReload()
        },
        setValidStatus(val) {
            this.isValid = val
            this.$emit('is-valid', val);
        }
    },
    computed: {
        ...mapGetters(['getThemeMode']),

        dataId() {
            return this[this.objectNamespace].id ? this[this.objectNamespace].id : false;
        },
        listArray() {
            if(this.arrayMode && this.listNamespace) { 
                return this[this.listNamespace];
            }
            else {
                return false;
            }
        },
        isUpdate() {
            return this.dataId ? true : false
        },
        form() {
            return this[this.objectNamespace];
        }
    },
    watch: {
        dataId: function(val) {
            this.$emit('is-update', val)
        },
        objectId: function(val) {
            
            if(val.length > 0) {
                this.getItem();
            }
            else {
                this.resetForm();
            }
            
        }
    },
    async created() {
        if(this.objectId) {
            this.getItem();
        }
        else if(this.clearStoreOnCreate && !this.objectId && !this.$helpers.isObjectEmpty(this[this.objectNamespace])) {
            this['CLEAR_'+this.objectNamespace.toUpperCase()]()
        }
    },

}
</script>

<style>

</style>