<template>
    <v-autocomplete
        :placeholder="'Select ' + $t('fields.'+`${item.field}`)"
        :error-messages="errors"
        :label="$helpers.capFirstLetter($t('fields.'+`${item.field}`))"
        :value="value"
        :items="items"
        :item-text="item.itemText"
        :item-value="item.itemValue ? item.itemValue : 'id'"
        :multiple="item.multiple ? !!item.multiple : false"
        v-on="$listeners"
        outlined
        :clearable="!readOnly"
        :deletable-chips="item.multiple ? !!item.multiple : false"
        :chips="item.multiple ? !!item.multiple : false"
        :return-object="(item.returnObject || returnObject) ? true : false"
        :readonly="readOnly"
        :class="[readOnly ? 'disabled-input' : '']"
    ></v-autocomplete>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: "SelectData",
    props: {
        item: {
            type: Object,
            default: function() {
                return {}
            }
        },
        errors: {
            type:[String, Array],
            default: function() {
                return []
            }
        },
        value: {
            type:[String, Array],
            default: ''
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    beforeCreate() {
        let storeNamespace = this.$options.propsData.item.storeNamespace;
        let objectNamespace = this.$options.propsData.item.objectNamespace;
        if(this.$options.propsData.item.keyFilterNamespace) {
            let keyFilterNamespace = this.$options.propsData.item.keyFilterNamespace;
            this.$options.computed = {
                ...this.$options.computed,
                ...mapGetters(storeNamespace, [keyFilterNamespace]),
            }
        }

        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters(storeNamespace, [objectNamespace]),
        }
    },
    computed: {
        keyFilter() {
            if(this.item.keyFilterNamespace && this[this.item.keyFilterNamespace][this.item.keyFilter]) {
                return this[this.item.keyFilterNamespace][this.item.keyFilter]
            }
            else {
                return false
            }
        },
        keyFilteredItems() {
            if(this.keyFilter) {
                let baseObj = this.item.keyParents.reduce((obj, key) => {
                    // eslint-disable-next-line
                    if (obj && obj.hasOwnProperty(key)) {
                        return obj[key];
                    } else {
                        return undefined;
                    }
                }, this[this.item.objectNamespace]);
                
                const findObj = baseObj.find(x => x.id === this.keyFilter);
                if(findObj) {
                    return findObj[this.item.keyNamespace];
                }
                else {
                    return [];
                }
            }
            else {
                return []
            }
        },
        items() {
            if(!this.item.keyFilterNamespace) {
                return this[this.item.objectNamespace][this.item.keyNamespace].map(obj => {
                    const val = this.item.itemValue ? this.item.itemValue : 'id'
                    return {
                    [this.item.itemText]: obj[this.item.itemText],
                    [val]: obj[val]
                    }
                });
            }
            else {
                return this.keyFilteredItems
            }
        },
    },
    watch: {
        //On change set default value
        keyFilteredItems: function (oldValue, newValue) {
            if(JSON.stringify(oldValue) != JSON.stringify(newValue)) {
                this.$emit('input', '');
                //this.$emit('input', this.keyFilteredItems[0].id);
            }
        }
    }
}
</script>

<style>

</style>