<template>


    <base-cta-list
        title="Betalningsvilkor"
        subtitle="Här hittar du tillgängliga betalningsvilkor"
        :headers="headers"
        storeNamespace="economy"
        listNamespace="A_term"
        :icon="logo"
        buttonText="Nytt betalningsvilkor"
        :inlineMode="true"
        
    >

        <template v-slot:row.default="{ entity }">

            <v-icon color="primary" v-if="entity.default" class="mr-2">mdi-checkbox-marked-circle</v-icon>
        </template>

         <template #dialog-content="{setDialog, dataId}"> 
             <billing-term-form
                :listOnSave="true"
                @requestIsDone="setDialog(false)"
                :id="dataId"
             ></billing-term-form>
         </template>

    </base-cta-list>



</template>

<script>
import BillingTermForm from './BillingTermForm'



export default {
    name: "Billing",
    metaInfo: {
        title: 'Billing'
    },
    components: {
        BillingTermForm
    },
    data() {
      return {
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name'
            },
            {
                text: 'Default',
                align: 'start',
                sortable: true,
                value: 'default',
                slot: true
            },
            {
                text: 'Days',
                align: 'start',
                sortable: true,
                value: 'days'
            },
        ],
        logo: require('@/assets/images/svg/skycraper.svg')
      }
    },
}
</script>

<style>

</style>