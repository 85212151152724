import router from '@/router'
// store instance 
import store from '@/store'
// vue instance
import Vue from 'vue'

// event bus instance
import eventBus from '@/plugins/eventbus.js'

export async function registerModule(modules) {

	for(let key in modules) {
		modules[key]({ app: Vue, store, router, eventBus })
	}
}