<template>
     <ValidationObserver ref="observer" tag="div" :disabled="observerDisabled">
     
        <v-row>
            
            <template
                v-for="(item, i) in renderSchema"
            >
                <v-col
                     v-if="checkTitle(item, i) && sectionTitles.length > 0"
                     cols="12"
                     :key="`title-${i}`" 
                >
                    <div class="mb-2">
                        <v-divider></v-divider>
                        <h6 class="mt-6">{{sectionTitles[item.section - 1]}}</h6>
                        
                    </div>
                </v-col>

                <v-col
                    class="pb-0"
                    :class="{ 
                        'pa-0': (item.type === 'hidden' || item.type === 'hiddenInput' ),
                    }"
                    :cols="item.column ? item.column : 12" 
                    :key="`field-${i}`"
                >


                    <ValidationProvider
                        v-if="item.type != 'blank' && !hide(item)"
                        v-slot="{ errors }"
                        :rules="item.rules"
                        :name="$t('fields.'+`${item.field}`)"
                    >

                        <v-text-field 
                            :placeholder="'Start typing ' + $t('fields.'+`${item.field}`)"
                            :error-messages="errors"
                            :type="item.inputType ? item.inputType : ''"
                            :label="item.label ? $helpers.capFirstLetter($t('labels.'+`${item.label}`)) : $helpers.capFirstLetter($t('fields.'+`${item.field}`))"
                            :value="form[item.field]"
                            :readonly="item.readonly ? !!item.readonly : false"
                            @input="setFieldValue($event, item.field)"
                            outlined
                            :clearable="item.readonly ? false : true"
                            v-if="item.type === 'input'"
                        ></v-text-field>
           
                        <v-textarea
                            :placeholder="'Start typing ' + $t('fields.'+`${item.field}`)"
                            :error-messages="errors"
                            :label="$helpers.capFirstLetter($t('fields.'+`${item.field}`))"
                            :value="form[item.field]"
                            @input="setFieldValue($event, item.field)"
                            outlined
                            clearable
                            v-if="item.type === 'textarea'"
                        ></v-textarea>


                        <select-simple
                            :label="$helpers.capFirstLetter($t('fields.'+`${item.field}`))"
                            :errors="errors"
                            :value="form[item.field]"
                            :items="item.items"
                            :setDefaultValue="item.hasOwnProperty('setDefaultValue') ? item.setDefaultValue : true"
                            :multiple="item.multiple ? true : false" 
                            @input="setFieldValue($event, item.field)"
                            :onResetForm="resetFormTimestamp"
                            v-if="item.type === 'select'"
                        ></select-simple>


                        <v-radio-group
                            class="mt-0 ml-1"
                            :error-messages="errors"
                            :label="$helpers.capFirstLetter($t('fields.'+`${item.field}`))"
                            :value="form[item.field]" 
                            @change="setFieldValue($event, item.field)"
                            v-if="item.type === 'radio'"
                        >
                            <v-radio
                                v-for="(r, idx) in item.items"
                                :key="`radio-${idx}`"
                                :label="r.name"
                                :value="r.value"
                            ></v-radio>
                        </v-radio-group>

                        <input-buttons
                            :item="item"
                            :value="form[item.field]"
                            @clicked="setFieldValue"
                            v-if="item.type === 'buttons'"
                        />

                        <read-only
                            :item="item"
                            :value="form[item.field]"
                            :triggerReload="triggerReload"
                            @updated="setFieldValue($event, item.field)"
                            :readOnly="readOnlyFields.includes(item.field)"
                            v-if="item.type === 'readOnly'"
                        />

                        <select-data
                            :item="item"
                            :value="item.field === 'obj' ? form : $helpers.isObject(form[item.field]) && form[item.field].id ? form[item.field].id : form[item.field]"
                            :returnObject="arrayMode"
                            :triggerReload="triggerReload"
                            @input="setFieldValue($event, item.field)"
                            :readOnly="readOnlyFields.includes(item.field)"
                            :initialObject="!!form[item.field.split('_')[0]] ? form[item.field.split('_')[0]] : false"
                            v-if="item.type === 'selectData'"
                        />

                        <select-local-data
                            :item="item"
                            :value="form[item.field]"
                            :returnObject="arrayMode"
                            @input="setFieldValue($event, item.field)"
                            :readOnly="readOnlyFields.includes(item.field)"
                            v-if="item.type === 'selectLocalData'"
                        />

                        <select-data-slave
                            :item="item"
                            :value="form[item.field]"
                            :returnObject="arrayMode"
                            :triggerReload="triggerReload"
                            @input="setFieldValue($event, item.field)"
                            :readOnly="readOnlyFields.includes(item.field)"
                            v-if="item.type === 'selectDataSlave'"
                        />

                        <cross-selection-data
                            :item="item"
                            :value="form[item.field]"
                            @selection="setFieldValue($event, item.field)"
                            v-if="item.type === 'crossSelectionData'"
                        />

                        <hidden-input
                            :item="item"
                            :value="form[item.field]"
                            :onResetForm="resetFormTimestamp"
                            v-if="item.type === 'hiddenInput'"
                            @updated="setFieldValue($event, item.field)"
                        />
                        <!--
                        <array-data
                            :item="item"
                            :value="form[item.field]"
                            v-if="item.type === 'arrayData'"
                            :triggerReload="triggerReload"
                            @updated="setFieldValue($event, item.field)"
                        />
                        --->

                        <array-input
                            :item="item"
                            :value="form[item.field]"
                            v-if="item.type === 'arrayInput'"
                            @updated="setFieldValue($event, item.field)"
                        />

                        <date-picker 
                            :label="$helpers.capFirstLetter($t('fields.'+`${item.field}`))"
                            :error-messages="errors"
                            :value="form[item.field]"
                            @input="setFieldValue($event, item.field)"
                            v-if="item.type === 'datePicker'"
                        ></date-picker>
                        
                        <v-color-picker
                            :error-messages="errors"
                            :label="$helpers.capFirstLetter($t('fields.'+`${item.field}`))"
                            :value="form[item.field] ? form[item.field] : '#000000'"
                            @input="setFieldValue($event, item.field)"
                            mode="rgba"
                            v-if="item.type === 'colorPicker'"
                        ></v-color-picker>


                        <switch-btn
                            :label="item.label ? $t(item.label) : $helpers.capFirstLetter($t('fields.'+`${item.field}`))"
                            :error-messages="errors"
                            :value="form[item.field]"
                            v-if="item.type === 'switch'"
                            :onResetForm="resetFormTimestamp"
                            @change="setFieldValue($event, item.field)"
                        />
                    </ValidationProvider>
                </v-col>
            </template>    
        </v-row>
    </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import InputButtons from '@/components/form/fields/InputButtons'
import SelectData from '@/components/form/fields/SelectData'
import CrossSelectionData from '@/components/form/fields/CrossSelectionData'
import HiddenInput from './fields/HiddenInput.vue';
//import ArrayData from './fields/ArrayData.vue';
import ArrayInput from './fields/ArrayInput.vue';
import DatePicker from './fields/DatePicker.vue';
import SwitchBtn from './fields/SwitchBtn.vue';
import SelectSimple from './fields/SelectSimple.vue';
import SelectDataSlave from './fields/SelectDataSlave.vue';
import SelectLocalData from './fields/SelectLocalData.vue';
import ReadOnly from './fields/ReadOnly.vue';

export default {
    name: "FormGenerator",
    components: {
        ValidationProvider,
        ValidationObserver,
        InputButtons,
        SelectData,
        CrossSelectionData,
        HiddenInput,
        //ArrayData,
        ArrayInput,
        DatePicker,
        SwitchBtn,
        SelectSimple,
        SelectDataSlave,
        SelectLocalData,
        ReadOnly
    },
    props: {
        form: {
            type: [Object, Array],
            default: function() {
                return {}
            }
        },
        schema: {
            type: Array,
            default: function() {
                return []
            }
        },
        observerDisabled: {
            type: Boolean,
            default: false
        },
        arrayMode: {
            type: Boolean,
            default: false
        },
        isUpdateMode: {
            type: Boolean,
            default: false
        },
        sectionTitles: {
            type: Array,
            default: function() {
                return []
            }
        },
        resetFormTimestamp: {
            type: Number,
            default:0
        }
    },

    data() {
        return {
            refObserver: {},
            triggerReload: 0,
        }
    },
    methods: {
        setFieldValue(value, field) {
            this.$emit('set-field-value', {value, field})

        },
        checkTitle(item, i) {
            if(item.section && i == 0) {
                return true;
            }
            else if(item.section && i != 0 && (this.renderSchema[i-1].section != item.section)) {
                return true
            }
            else {
                return false;
            }
        },
        doReload() {
            this.triggerReload = Date.now();
        },
        hide(item) {

            if(item.hide) {
                if(!this.isUpdateMode && item.hide.includes('save')) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        }
    },
    computed: {
        renderSchema() {
            //Conditional rendering
            let schema = this.schema.filter(o => {

                if(!o.conditions) {
                    return true
                }
                
                let arr = o.conditions.split(".");
                let values = arr[1].split("|");

                if(values.includes(this.form[arr[0]])) {
                    return true
                }
                else {
                    return false;
                }
            });

            schema.sort((a,b) => {
                return (a.section - b.section) || (a.order - b.order);
            })
            
            return schema;
        },

        isFormValid() {
            
            if(this.refObserver && this.refObserver.flags) {

                return this.refObserver.flags.valid
            }
            else {
                return false
            }
        },
        readOnlyFields() {
            const fields = [];
            this.renderSchema.forEach(e => {
                if(this.isUpdateMode && e.exclude && e.exclude.includes('update')) {
                    fields.push(e.field)
                }
            });
            return fields;
        }
    },
    watch: {
        isFormValid: function(val) {
            this.$emit('is-valid', val)
        },
        resetFormTimestamp: async function() {
            await this.$nextTick();
            this.$refs.observer.reset();
        }
    },
    created() {
       Object.defineProperty(this.$refs, 'observer', {
            get: function () {
                return this.refObserver;
            }.bind(this),
            set: function (newValue) {
                this.refObserver = newValue;
            }.bind(this),
            enumerable: true
        });
    }
}
</script>

<style>

</style>