<template>
    <div>
        <izy-card
            title="Prover"
            subtitle="Inlämnade emballage och provsvar"
        >
            <v-row>
                <v-col cols="12" v-if="loading">
                    <v-skeleton-loader
                        type="table-heading, list-item-two-line, table-tfoot"
                    ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" v-else>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="4" class="px-6 py-0 mb-4">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Sök"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-data-table
                        :items="A_result"
                        :headers="headers"
                        :single-expand="true"
                        show-expand
                        @click:row="showRow"
                        :search="search"
                        item-key="id"
                        class="style-1"
                    >

                        <template v-slot:item.data-num-of-bags="{item}">
                            <v-chip
                                class="ma-2"
                                color="primary"
                                small
                            >
                            {{ item.inner_bags.length }}
                            </v-chip>
                        </template>
                        <template
                            v-slot:item.received_at="{ item }"
                        >
                            {{ moment(item.received_at).format('YYYY-MM-DD HH:mm:ss') }}
                        </template>

                        <template v-slot:item.data-table-expand="{isExpanded}">
                            <v-icon
                            @click="showRow"
                            >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <div class="row pa-5">
                                    <table class="simple-table">
                                        <thead>
                                            <tr>
                                                <th>Journal id</th>
                                                <th>Provnamn</th>
                                                <th>Rum</th>
                                                <th>Material</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr 
                                                v-for="(row) in item.inner_bags" :key="row.id"
                                                @click="openResultItem(row)"
                                            >
                                                <td>{{row.journal_id}}</td>
                                                <td>{{row.name_inner}}</td>
                                                <td>{{row.room}}</td>
                                                <td>{{row.material}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </template>


                    </v-data-table>

                    <v-dialog
                        v-model="dialog"
                        width="980px"
                    >
                        <journal-card 
                            :journal-item="resultItem"
                            @closeCard="dialog=false"
                            >
                        </journal-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </izy-card>
    </div>

</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import JournalCard from '@/components/card/JournalCard'
import IzyCard from '@/components/card/IzyCard'
export default {
    name: "CustomerSamples",
    metaInfo: {
        title: 'Samples'
    },
    components: {
        JournalCard,
        IzyCard
    },
    data() {
      return {
        loading: false,
        dialog: false,
        resultItem: false,
        search: "",
        headers: [
            {
                text: 'Fakturamärkning',
                align: 'start',
                sortable: true,
                value: 'name'
            },
            {
                text: 'Objekt',
                align: 'start',
                sortable: true,
                value: 'reference'
            },
            {
                text: 'Inlämnad av',
                align: 'start',
                sortable: true,
                value: 'registered_by.name',
            },
            {
                text: 'Antal provpåsar',
                align: 'start',
                value: 'data-num-of-bags',
            },
            {
                text: 'Mottagen',
                align: 'start',
                sortable: true,
                value: 'received_at',
            },
            { 
                text: '', 
                value: 'data-table-expand' 
            },
        ],
      }
    },
    methods: {
        ...mapActions('samples', ['list_A_result']),
        async getData() {
            this.loading = true;
            await this.list_A_result();
            this.loading = false;
        },
        async showRow(item, event) {
            if(event) {
                event.expand()
            }
        },
        openResultItem(row) {
            this.resultItem = row;
            this.dialog = true;
        },
    },
    computed: {
        ...mapGetters('samples', ['A_result']),

    },
    created () {
        this.getData();
    },

}
</script>

<style lang="scss" scoped>
    * >>>.style-1:hover {
        cursor: pointer !important;
    }
    table.simple-table {
        width: 100%;
        border-collapse: collapse;
        tr {
            text-align: left;
            th {
                border-bottom: 2px solid #d9d9d9;
                padding: 1px 4px;
                color: #555;
                font-size: 12px;
            }
            td {
                border-bottom: 1px solid #ededed;
                padding: 8px 4px;
            }

        }
        tbody {
            tr {
                &:hover {
                    cursor: pointer;
                    background: #ededed;
                }
            }
        }
    }
  
</style>
