<template>
  
    <v-stepper-content class="pa-0 mt-3" 
        :step="step"
    >

        <v-lazy>
            <step-card
                :action="true"
                :title="getTitle"
                :subTitle="subTitle"
            >
                <template v-slot:stepContentSlot>
    
                    <vuex-fields
                        ref="formFieldRef"
                        :schema="schema"
                        :storeNamespace="storeNamespace"
                        :objectNamespace="objectNamespace"
                        :clearStoreOnCreate="clearStoreOnCreate"
                        @is-valid="changeValidStatus"
                        @request-done="isSaved"
                        :arrayMode="arrayMode"
                        :objectId="activeId"
                        :listOnSave="listOnSave"
                        :listObjectNamespace="listObjectNamespace ? listObjectNamespace : ''"
                        :listStoreNamespace="listStoreNamespace ? listStoreNamespace : ''"
                        :resAction="resAction"
                        v-mounted="onFormMounted"
                    ></vuex-fields>

                </template>

                <template v-slot:stepActionSlot>
                    <v-btn :disabled="1 >= step" @click="prevStep()" text>Back</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!validStatus" @click="nextStep()" text>Continue</v-btn>
                    <v-btn v-show="false" text>Done</v-btn>
                </template>
                
            </step-card>
        </v-lazy>

    </v-stepper-content>
</template>

<script>
import StepCard from '@/components/card/StepCard'
import VuexFields from '@/components/form/VuexFields'
import {mapGetters} from 'vuex'

export default {
    name: "StepFormContent",
    components: {
      StepCard,
      VuexFields
    },
     props: {
        title: {
            type:String,
            default: ''
        },
        titleKey: {
            type:String,
            default: ''
        },
        prependTitleKey: {
            type:String,
            default: ''
        },
        appendTitleKey: {
            type:String,
            default: ''
        },
        subTitle: {
            type:String,
            default: ''
        },
        isLazy: {
            type: Boolean,
            default: false
        },
        lastStep: {
            type: Boolean,
            default: false
        },
        step: {
            type: Number,
            default: null
        },
        currentStep: {
            type: Number,
            default: null
        },
        schema: {
            type: Array,
            default: function() {
                return []
            }
        },
        storeNamespace: {
            type:String,
            default: ''
        },
        objectNamespace: {
            type:String,
            default: ''
        },
        objectId: {
            type: String,
            default: null
        },
        saveOnContinue: {
            type: Boolean,
            default: false
        },
        clearStoreOnCreate: {
            type: Boolean,
            default: false
        },
        arrayMode: {
            type: Boolean,
            default: false
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
        listObjectNamespace: {
            type: String
        },
        listStoreNamespace: {
            type: String
        },
        resAction: {
            type: Boolean,
            default: true
        },
        parentId: {
            type:String,
            default: ''
        }
    },
    beforeCreate() {
        let storeNamespace = this.$options.propsData.storeNamespace;
        let objectNamespace = this.$options.propsData.objectNamespace;
        
        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters(storeNamespace, [objectNamespace]),
        }
    },

    data: () => ({
        dialog: true,
        validStatus: false,
        dbId: null,
        activeId: "",
        formMounted: false
    }),
    methods: {
      nextStep() {
          if(this.saveOnContinue || this.saveOnContinueOverride) {
              this.$refs.formFieldRef.saveItem()
          }
          else {
            if(!this.lastStep) {
                this.$emit('step-change', {action: "next", curr: this.step})
            }
            else {
              this.$emit('step-done')
            }
            
          }
          
      },
      prevStep() {
          this.$emit('step-change', {action: "prev", curr: this.step})
      },
      changeValidStatus(status) {
          this.validStatus = status
      },
      isSaved() {
          if(!this.lastStep) {
              this.dbId =  this[this.objectNamespace]['id']
              this.$emit('step-change', {action: "next", curr: this.step})
          }
          else {
              this.$emit('step-done')
          }
      },
      onEnter(initial) {
            //Pass in id if exist - this will trigger load
            if(this.dbId && !this.activeId) {
                this.activeId = this.dbId
            }
            //Manual trigger load
            else if(this.activeId) {
                this.$refs.formFieldRef.getItem()
            }
            //Reset form
            else if(!initial) {
                this.$refs.formFieldRef.resetForm()
            }

            if(!initial) {
                this.$refs.formFieldRef.triggerReload()
            }
      },
      onFormMounted() {
        this.formMounted = true;
        this.onEnter(true);
      }

    },
    computed: {
        getTitle() {
            if (this.titleKey && this[this.objectNamespace][this.titleKey]) {
                return this.prependTitleKey + this[this.objectNamespace][this.titleKey] + this.appendTitleKey
            }
            else {
                return this.title;
            }
        },
        saveOnContinueItems() {
            const soc = this.schema.filter(i => {
                if("saveOnContinue" in i) {
                    return i;
                }
            })
            return soc;
        },
        saveOnContinueOverride() {
            return this.saveOnContinueItems.some(el => {
                if(el.field in this[this.objectNamespace]) {
                    if(el.saveOnContinue.includes(this[this.objectNamespace][el.field])) {
                        return true
                    }
                }
            })
        },
        isActive() {
            return this.currentStep == this.step ? true : false
        }
    },
    watch: {
        isActive: {
            handler(val) {
                if(val && this.formMounted) {
                    this.onEnter(false);
                }
            }
        }
    },
    directives: {
        mounted: {
            inserted: function(el, binding) {
                binding.value();
            }
        }
    },



}
</script>

<style>

</style>