<template>
    <div>

        <izy-card
            class="mt-8"
            title="Journals" 
        >
            <v-data-table
                :items="A_journal"
                :headers="headers"
                :single-expand="true"
                show-expand
                @click:row="showRow"
                item-key="id"
                class="style-1"
            >

                <template
                    v-slot:item.client="{ item }"
                >
                    {{ item.client.name }}
                </template>

                <template
                    v-slot:item.received_at="{ item }"
                >
                    {{ moment(item.received_at).format('YYYY-MM-DD HH:mm:ss') }}
                </template>

                <template
                    v-slot:item.site="{ item }"
                >
                    {{item.site.name}}
                </template>

                <template v-slot:item.data-table-expand="{isExpanded}">
                    <v-icon
                    @click="showRow"
                    >{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </template>

                <template v-slot:expanded-item="{ headers }">
                    <td :colspan="headers.length">
                        <div class="d-flex justify-center py-5" v-if="loadingJournal">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                        </div>
                        <div class="row pa-5" v-else>
                            <table class="simple-table">
                                <thead>
                                    <tr>
                                        <th>Journal id</th>
                                        <th>Kund</th>
                                        <th>Fakturamärkning</th>
                                        <th>Objekt</th>
                                        <th>Rum</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr 
                                        v-for="(row, idx) in O_journal.journalrows" :key="row.id"
                                        @click="openJournalItem(idx)"
                                    >
                                        <td>{{row.journal_id}}</td>
                                        <td>{{row.client.name}}</td>
                                        <td>{{row.name_outer}}</td>
                                        <td>{{row.reference}}</td>
                                        <td>{{row.room}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </td>
                </template>

            </v-data-table>

        </izy-card>
        <v-dialog
            v-model="dialog"
            width="980px"
        >
            <journal-card 
                :journal-item="journalItem"
                @closeCard="dialog=false"
                >
            </journal-card>
        </v-dialog>
    </div>

</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import IzyCard from '@/components/card/IzyCard'
import JournalCard from '@/components/card/JournalCard'
export default {
    name: "LabJournals",
    metaInfo: {
        title: 'Lab Journals'
    },
    components: {
        IzyCard,
        JournalCard
    },
    data() {
      return {
        loading: false,
        loadingJournal: false,
        dialog: false,
        journalItem: false,
        expanded: [],
         headers: [
            {
                text: 'Journal id',
                align: 'start',
                sortable: true,
                value: 'id_journal',
            },
            {
                text: 'Site',
                align: 'start',
                sortable: true,
                value: 'site'
            },
            {
                text: 'Bags',
                align: 'start',
                sortable: true,
                value: 'bags_count'
            },
            {
                text: 'Stored at',
                align: 'start',
                sortable: true,
                value: 'date'
            },
            { 
                text: '', 
                value: 'data-table-expand' 
            },
        ],
      }
    },
    methods: {
        ...mapActions('lab', ['list_A_journal', 'get_O_journal']),
        async getData() {
            this.loading = true;
            await this.list_A_journal();
            this.loading = false;
        },
        async showRow(item, event) {
            this.loadingJournal = true;
            if(event) {
                event.expand()
            }
            await this.get_O_journal({id:item.id})
            this.loadingJournal = false;
        },
        openJournalItem(idx) {
            this.journalItem = this.O_journal.journalrows[idx];
            this.dialog = true;
        }
    },
    computed: {
        ...mapGetters('lab', ['A_journal', 'O_journal']),
    },
    created () {
        this.getData();
    },

}
</script>

<style lang="scss" scoped>
    * >>>.style-1:hover {
        cursor: pointer !important;
    }
    table.simple-table {
        width: 100%;
        border-collapse: collapse;
        tr {
            text-align: left;
            th {
                border-bottom: 2px solid #d9d9d9;
                padding: 1px 4px;
                color: #555;
                font-size: 12px;
            }
            td {
                border-bottom: 1px solid #ededed;
                padding: 8px 4px;
            }

        }
        tbody {
            tr {
                &:hover {
                    cursor: pointer;
                    background: #ededed;
                }
            }
        }
    }
  
</style>
