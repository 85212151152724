import BaseStore from "@/modules/baseStore.js";
import BaseApi from "@/modules/baseApi.js";

const state = {
  A_group: [],
  O_group: {},
  A_user: [],
  O_user: {},
  A_role: [],
  O_role: {},
}

const api = BaseApi(state, "aaa/")


export default BaseStore.extend({
  state,
  api
})