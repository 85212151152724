export const clientContactSchema = {
   
    name_organisation: '{type:"input", column:"12", section:"1"}',
    name_first: '{type:"input", column:"6", rules:"required"}',
    name_last: '{type:"input", column:"6", rules:"required"}',
    street_name: '{type:"input", rules:"required", column:"8"}',
    street_number: '{type:"input", column:"2"}',
    street_littera: '{type:"input", column:"2"}',
    street_apartment: '{type:"input", column:"6"}',
    street_ascent: '{type:"input", column:"6"}',
    postal_code: '{type:"input", rules:"required", column:"4"}',
    postal_city: '{type:"input", rules:"required", column:"8"}',
    postal_country: '{type:"input", rules:"required", column:"12"}',
    phone_sms: '{type:"input", column:"6"}',
    phone_voice: '{type:"input", column:"6"}',
    email: '{type:"input", column:"12"}',
    binding: '{type:"arrayInput", section:"2", schema:[{field:"type", type:"select", items: [{"text":"invoice", "value":"invoice"},{"text":"visiting_address", "value":"company-registered-office"},{"text":"lab_reports", "value":"reports"}], rules:"required", column:"4"}, {field:"order", type:"select", items: [{"text":"primary", "value":"primary"}, {"text":"secondary", "value":"secondary"}], rules:"required", column:"4"}, {field:"destination", type:"select", items: [{"text":"mail", "value":"mail"},{"text":"email", "value":"email"},{"text":"sms", "value":"sms"}], rules:"required", column:"4"},{field:"id", type:"blank"}]}'


}



