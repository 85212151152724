<template>

    <base-cta-list
        title="Användare"
        subtitle="Här hittar du användare som har administrationsbehörighet i plattformen"
        :headers="headers"
        storeNamespace="aaa"
        listNamespace="A_user"
        :icon="userLogo"
        buttonText="Ny användare"
    >
        <template v-slot:row.groups="{ entity }">
    
            <div v-for="(group, index) in entity.groups" :key="`group-${index}`">
                {{ group.prefix }} {{ group.prefix ? group.name : '' }}
                
     
            </div>

        </template>

         <template #dialog-content="{setDialog, dataId}"> 
             <user-form
                :listOnSave="true"
                @requestIsDone="setDialog(false)"
                :id="dataId"
                storeNamespace="aaa"
                objectNamespace="O_user"
             ></user-form>
         </template>

    </base-cta-list>

</template>

<script>
import UserForm from '@/components/form/forms/UserForm.vue'


export default {
  components: { UserForm },
    name: "Users",
    metaInfo: {
        title: 'Users'
    },
    data() {
      return {
        headers: [
            {
                text: 'Användarnamn',
                align: 'start',
                sortable: false,
                value: 'login',
            },
            {
                text: 'Namn',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            {
                text: 'Grupper', 
                value: 'groups',
                slot: true,
            }
        ],
        userLogo: require('@/assets/images/svg/man.svg')
      }
    }
}
</script>

<style>

</style>