<template>
    
    <edit-form
        :title="title"
        :subTitle="subTitle"
        :schema="schema"
        storeNamespace="economy"
        objectNamespace="O_method"
        :objectId="id"
        :listOnSave="listOnSave"
    >

    </edit-form>

</template>

<script>
import EditForm from '@/components/form/EditForm'
import {billingMethodSchema} from '@/modules/Settings/schema/billingMethodSchema.js'

export default {
    name: "BillingMethodForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
    },
    data() {
      return {

      }
    },
    computed: {
        schema() {
          return this.$helpers.schemaParser(billingMethodSchema)

        },
        title() {
            return this.id ? "Uppdatera betalningsmetod" : "Ny betalningsmetod";
        },
        subTitle() {
            return this.id ? "Här kan du redigera en betalningsmetod" : "Här fyller du i information om den nya betalningsmetoden";
        },
        
    },
}
</script>

<style>

</style>