<template>
  <div>
    <zebra-qr-scanner
      @code="onQrCode"
      v-if="!isExamination"
    ></zebra-qr-scanner>

    <div class="d-flex" v-if="resultsList.length > 0">
      <v-spacer></v-spacer>
      <v-btn 
          class="izy-btn mb-8" 
          color="primary" 
          large
          @click="resetExamination"
      >
        Lämna svar på ny provpåse
      </v-btn>
    </div>


    <div v-if="isExamination">
      <base-card class="px-6 py-4 mb-4">
        <v-row>
          <v-col cols="12" md="8" align-self="center">
              <div class="d-flex align-start justify-space-between">
                  <div class="mb-3">
                      <h5 class="mr-2 mb-0">
                          {{O_examination.client.name}}

                          <v-tooltip top>
                              <template
                                  v-slot:activator="{on, attrs}"
                              >
                                  <v-icon
                                      class="body-1"
                                      color="primary"
                                      v-bind="attrs"
                                      v-on="on"
                                  >
                                      mdi-check-decagram</v-icon
                                  >
                              </template>
                              <span>Verified</span>
                          </v-tooltip>
                      </h5>
                      <p class="text--disabled mb-0">Provpåse: {{O_examination.inner_bag.id}}</p>
                  </div>
              </div>
              <div class="d-flex mb-2">
                  <p class="ma-0 mr-8">
                      Provnamn: <span class="font-weight-medium">{{O_examination.inner_bag.name}}</span
                      >
                  </p>
                  <p class="ma-0 mr-8">
                      Rum: <span class="font-weight-medium">{{O_examination.inner_bag.room}}</span
                      >
                  </p>
                  <p class="ma-0 mr-8">
                      Material: <span class="font-weight-medium">{{O_examination.inner_bag.material}}</span>
                  </p>
              </div>
              <div class="d-flex mb-2">
                  <p class="ma-0 mr-8">
                      Produkt: <span class="font-weight-medium">{{O_examination.inner_bag.product.name}}</span
                      >
                  </p>
                  <p class="ma-0 mr-8">
                      Kö: <span class="font-weight-medium">{{O_examination.inner_bag.queue_full_name}}</span
                      >
                  </p>
              </div>
              <div class="d-flex flex-column mb-0 mt-4 blue lighten-5 py-2 px-3 rounded-sm">
                <p class="text--disabled mb-0">
                    Provet skickades in av {{O_examination.registered.name}}, {{O_examination.registered.date ? moment(O_examination.registered.date).format('YYYY-MM-DD HH:mm:ss') : ''}}.
                </p>
                <p class="text--disabled mb-0">
                    Provet ankomstregistrerades av {{O_examination.received.name}}, {{moment(O_examination.received.date).format('YYYY-MM-DD HH:mm:ss')}}.
                </p>
              </div>
          </v-col>
          <v-col md="4">
              <div class="text-right d-flex flex-column" style="height: 100%">
                  <div>
                      <v-chip class="red--green green lighten-5" v-if="resultsList.length > 0">
                          Svar lämnat
                      </v-chip>
                      <v-chip class="red--text red lighten-5" v-else>
                          Svar ej lämnat
                      </v-chip>
                  </div>
                  <div class="d-flex flex-1 align-end justify-end">
                      <v-btn
                          depressed
                          class="primary--text"
                          color="lighten-5 primary"
                          @click="sendAnswer"
                          :disabled="!allDone"
                      >
                          Registrera provsvar
                      </v-btn>
                  </div>
              </div>
          </v-col>
        </v-row>
      </base-card>
      <base-card :padding="true" v-if="sampleList.length > 0">
        <v-card-title>Provsvar</v-card-title>
        <v-card-text>
            <v-data-table
                hide-default-footer
                :headers="headers"
                :items="sampleList"
                class="elevation-1 eg-table-bnone eg-table-td-py-md"
            >
                <template v-slot:item.id="{item}">
                  <v-avatar
                      :color="item.value ? 'green lighten-5' : 'red lighten-5'"
                      size="28"
                  >
                      <v-icon 
                          small 
                          :color="item.value ? 'green' : 'red'"
                      >
                          {{item.value  ? 'mdi-check' : 'mdi-minus'}}
                      </v-icon>
                  </v-avatar>
                </template>

                <template v-slot:item.method="{item}">

                  {{item.method.name}}
                </template>

                <template v-slot:item.value="{item}">
                  <span v-if="item.value">
                    <span v-if="item.type === 'bool'">{{item.value === '1' ? 'Ja' : 'Nej'}}</span>
                    <span v-else>{{item.value}}</span> <sup>{{item.unit}}</sup>
                  </span>
                </template>


                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>

            </v-data-table>
          </v-card-text>
        </base-card>
      <base-card :padding="true" v-if="resultsList.length > 0">
        <v-card-title>Lämnade svar</v-card-title>
        <v-card-text>
            <v-data-table
                hide-default-footer
                :headers="headersResults"
                :items="resultsList"
                class="elevation-1 eg-table-bnone eg-table-td-py-md"
            >
                <template v-slot:item.id>
                  <v-avatar
                      color="green lighten-5"
                      size="28"
                  >
                      <v-icon 
                          small 
                          color="green"
                      >
                        mdi-check

                      </v-icon>
                  </v-avatar>
                </template>

                <template v-slot:item.substance="{item}">

                  {{item.substance.name}}
                </template>
                <template v-slot:item.method="{item}">

                  {{item.substance.method.name}}
                </template>

                <template v-slot:item.presence_text="{item}">
       
                  <span v-if="item.presence_text">{{$t('fields.' + `${item.presence_text}`)}} <sup>{{item.substance.unit}}</sup></span>
                </template>


            </v-data-table>
          </v-card-text>
        </base-card>
        
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <v-card>
          <v-card-title class="flex-column justify-start align-start">
            <span class="text-h5">{{editedItem.name}}</span>
            <span class="subtitle-1">Metod: {{editedItem.method ? editedItem.method.name : ''}}</span>
          </v-card-title>
          
          <v-card-text>
            <v-container>
              <v-row>

                <v-col
                  cols="12"
                >
                  <v-select
                    :items="selectBoolValues"
                    v-model="editedItem.value"
                    :label="'Provsvar (' + editedItem.unit + ')'"
                    v-if="editedItem.type === 'bool'"
                  >
                  </v-select>

                  <v-text-field
                    v-else
                    v-model="editedItem.value"
                    :label="'Provsvar (' + editedItem.unit + ')'"
                  ></v-text-field>
                </v-col>


                <v-col
                  cols="12"
                >
                  <v-textarea
                    v-model="editedItem.comment"
                    label="Kommentar"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeEdit"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="saveEditItem"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import ZebraQrScanner from "@/components/util/ZebraQrScanner"
import {mapGetters, mapActions, mapMutations} from 'vuex'

export default {
  name: 'Examination',
  components: {
    ZebraQrScanner
  },
  data() {
    return {
      selectBoolValues: [{"text": "Ja", "value": "1"}, {"text": "Nej", "value": "0"}],
      editedIndex: -1,
      editedItem: {},
      dialog: false,
      answers: [],
      headers: [
        {
            text: 'Svar ifyllt',
            sortable: false,
            value: 'id',
            slot: true
        },
        {
            text: 'Substans',
            sortable: false,
            value: 'name',
        },
        {
            text: 'Metod',
            sortable: false,
            value: 'method',
            slot: true
        },
        {
            text: 'Provsvar',
            sortable: false,
            value: 'value',
            
        },
        {
            text: 'Kommentar',
            sortable: false,
            value: 'comment',
        },
        {
            text: 'Åtgärder',
            sortable: false,
            value: 'actions',
            slot: true
        },
      ],
      headersResults: [
        {
            text: 'Svar ifyllt',
            sortable: false,
            value: 'id',
            slot: true
        },
        {
            text: 'Substans',
            sortable: false,
            value: 'substance',
            slot: true
        },
        {
            text: 'Metod',
            sortable: false,
            value: 'method',
            slot: true
        },
        {
            text: 'Provsvar',
            sortable: false,
            value: 'presence_text',
        },
        {
            text: 'Kommentar',
            sortable: false,
            value: 'comment',
        }
      ],
    }
  },
  computed: {
    ...mapGetters('lab', ['O_examination']),
    isExamination() {
      return !this.$helpers.isObjectEmpty(this.O_examination);
    },
    sampleList() {
      return this.isExamination ? this.O_examination.substances.filter(x => !this.resultsIds.includes(x.id)) : [];
    },
    resultsList() {
      return this.isExamination && this.O_examination.results ? this.O_examination.results : [];
    },
    resultsIds() {
      if(this.isExamination && this.O_examination.results) {
        return this.O_examination.results.map(item => {
          return item.substance.id
        })
      }
      else {
        return []
      }
    },
    allDone() {
      if(this.sampleList.length > 0) {
        return this.sampleList.every(item => item.value)
      }
      else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions('lab', ['create_O_examination', 'patch_O_examination']),
    ...mapMutations('lab', ['CLEAR_O_EXAMINATION']),
    async onQrCode(data) {

      if(data.type === 'inner' && data.id) {
        const res = await this.create_O_examination({
           input:{innerBagId: data.id}
        })
        if(res) {
          console.log("success")
        }
      }
    },
    async sendAnswer() {

      const toSend = this.sampleList.reduce((acc, curr) => {
        const {id, value, comment} = curr;
        acc.push({id, value,comment})
        return acc;
      }, [])

      const res = await this.patch_O_examination({
          input:{results: toSend},
          id: this.O_examination.inner_bag.id
      })

      console.log(res)

    },
    resetExamination() {
      this.CLEAR_O_EXAMINATION();
    },
    editItem(item) {
      this.editedIndex = this.sampleList.indexOf(item)
      this.editedItem = {...item, ...this.editedItem};
    
      this.dialog = true;
    },
    saveEditItem() {
      if(this.editedIndex != -1) {
        this.$set(this.O_examination.substances, this.editedIndex, this.editedItem);
      }
      this.closeEdit();
    },
    closeEdit() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1
        this.editedItem = {}
      })
    },
  },
  
}
</script>

<style>

</style>