<template>
  <div>
    <div class="d-flex justify-center flex-column text-center" v-if="title && !hideTitle">

      <h3 class="mb-4">{{title}}</h3>
      <p>
          {{subtitle}}
      </p>

    </div>

    <div class="d-flex justify-center">

      <v-stepper 
        alt-labels 
        v-model="currentStep"
        max-width="700px"
        width="100%"
      >
        <v-stepper-header v-if="!hideNavigator">
          <template
            v-for="index in numOfSteps" 
          >
            <v-stepper-step 
              :step="index"
              :key="`step-head-${index}`"
            ></v-stepper-step>
            <v-divider
              :key="`step-head-divider-${index}`"
              v-if="index > 0 && numOfSteps > index"></v-divider>
             
          </template>
 
        </v-stepper-header>
          <slot 
            :changeStep="changeStep" 
            :currentStep="currentStep"
            :previousStep="previousStep"
            :stepperId="componentId"
          ></slot>
        
      </v-stepper>

    </div>
  </div>
</template>

<script>

export default {
    name: "Stepper",
    props: {
        title: {
            type: String,
            default: '' 
        },
        subtitle: {
            type: String,
            default: ''
        },
        numOfSteps: {
          type: Number,
          default: 0
        },
        initialStep: {
          type: Number,
          default: 1
        },
        hideNavigator: {
          type: Boolean,
          default: true //SHOULD BE FALSE
        },
        hideTitle: {
          type: Boolean,
          default: false
        }
    },
    data() {
      return {
        currentStep: this.initialStep,
        previousStep: 0,
      }
      
    },
    methods: {
      changeStep(event) {
        const {action, curr} = event;
        
        switch(action) {
          case "next":
            this.previousStep = this.currentStep
            this.currentStep = curr+1;
            break;
          case "prev":
            this.previousStep = this.currentStep
            this.currentStep = curr-1;
            break;
          default:
        }
      }
    },
    watch: {
      currentStep: {
        immediate: true, 
        handler(val) {
            this.$emit('stepUpdated', val);
        }
      },

    },
}
</script>

<style>

</style>