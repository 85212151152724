<template>
    <div>
        <base-card class="px-6 py-4 mb-4">
            <v-row>
                <v-col cols="12" md="3">
                    <apexchart
                        type="radialBar"
                        height="250"
                        :options="
                            customChartSeventeen.chartOptions
                        "
                        :series="percentageRegistred"
                    />
                </v-col>
                <v-col cols="12" md="5" align-self="center">
                    <div class="d-flex align-start justify-space-between">
                        <div class="mb-3">
                            <h5 class="mr-2 mb-0">
                                OCAB

                                <v-tooltip top>
                                    <template
                                        v-slot:activator="{on, attrs}"
                                    >
                                        <v-icon
                                            class="body-1"
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-check-decagram</v-icon
                                        >
                                    </template>
                                    <span>Verified</span>
                                </v-tooltip>
                            </h5>
                            <p class="text--disabled mb-0">Emballage: {{id}}</p>
                        </div>
                    </div>
                    <div class="d-flex mb-2">
                        <p class="ma-0 mr-8">
                            <span class="font-weight-medium">{{items.length}} provpåsar</span>
                        </p>
                        <p class="ma-0 mr-8">
                            <span class="font-weight-medium">Vasagatan 2 </span
                            >
                        </p>
                        <p class="ma-0 mr-8">
                            <span class="font-weight-medium">906 56 Umeå</span>
                        </p>
                    </div>
                    <p class="text--disabled">
                        Provet skickades in av Jane Doe, kl.13.20 den 3 dec 2021.
                    </p>
                </v-col>
                <v-col md="4">
                    <div class="text-right d-flex flex-column" style="height: 100%">
                        <div>
                            <v-chip class="green--text green lighten-5" v-if="allDone">
                                Allt klart!
                            </v-chip>
                            <v-chip class="red--text red lighten-5" v-else>
                                Ej klar
                            </v-chip>
                        </div>
                        <div class="d-flex flex-1 align-end justify-end">
                            <v-btn
                                depressed
                                class="primary--text"
                                color="lighten-5 primary"
                                @click="qrCodeStreamEnable = true"
                                :disabled="allScanned"
                            >
                                Registrera provpåsar
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </base-card>

        <base-card :padding="true">
            <v-card-title>Samples</v-card-title>
            <v-card-text>
                <v-data-table
                    hide-default-footer
                    :headers="headers"
                    :items="items"
                    class="elevation-1 eg-table-bnone eg-table-td-py-md"
                >

                    <template v-slot:item.registred="{item}">
                        <v-avatar
                            :color="item.registred ? 'green lighten-5' : 'red lighten-5'"
                            size="28"
                        >
                            <v-icon 
                                small 
                                :color="item.registred ? 'green' : 'red'"
                            >
                                {{item.registred ? 'mdi-check' : 'mdi-minus'}}
                            </v-icon>
                        </v-avatar>
                    </template>
                    <template v-slot:item.room="{item}">
                        <h6 class="ma-0 font-weight-medium">
                            {{ item.room }}
                        </h6>
                    </template>
                    <template v-slot:item.answer="{item}">
                        <v-chip
                            :class="item.answer ? 'green--text' : 'orange--text'"
                            :color="item.answer ? 'green lighten-5' : 'orange lighten-5'"
                            small
                        >
                            {{item.answer ? 'Lämnat' : 'Ej lämnat'}}
                        </v-chip>
                    </template>
                    <template v-slot:item.action="{item}">
                        <v-menu left bottom>
                            <template v-slot:activator="{on}">
                                <v-btn icon color v-on="on">
                                    <v-icon class=""
                                        >mdi-dots-horizontal</v-icon
                                    >
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item 
                                    @click="openItem(item)"
                                    :disabled="!item.registred"
                                >
                                    <v-icon class="body-1 mr-2"
                                        >mdi-test-tube</v-icon
                                    >
                                    <v-list-item-title
                                        >Open sample</v-list-item-title
                                    >
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-card-text>
        </base-card>
        <v-dialog
            v-model="qrCodeStreamEnable"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <transition :duration="300">
                <qr-scanner
                    :validationResult="validationResult"
                    :numOfScanned="numOfScanned"
                    :numOfMaxScan="numOfMaxScan"
                    :allScanned="allScanned"
                    v-if="qrCodeStreamEnable"
                    @closeScanner="qrCodeStreamEnable = false"
                    @onResult="handleResult"
                ></qr-scanner>
            </transition>
        </v-dialog>
    </div>
</template>

<script>
import QrScanner from '../components/QrScanner.vue'
import {mapGetters, mapActions} from 'vuex'
import VueApexCharts from 'vue-apexcharts'

import {
    customChartSeventeen,
} from '../data/apexChart'
export default {
    name: "Envelope",
    metaInfo: {
        title: 'Envelope'
    },
    components: {
        'apexchart': VueApexCharts,
        QrScanner
    },
    props: {
        id: {
            type: String
        },
    },
    data() {
        return {
            customChartSeventeen,
            validationResult: {},
            qrCodeStreamEnable: false,
            headers: [
                    {
                    text: 'Registered',
                    align: 'start',
                    value: 'registred'
                },
                {text: 'Room', value: 'room'},
                {text: 'Bagid', value: 'id'},
                {text: 'Answer', value: 'answer'},
                {text: 'Action', value: 'action'}
            ]
        }
    },
    methods: {
        ...mapActions('demo', ['registerBag', 'pushQueue', 'setEnvelope']),

        getBag(id) {
            id = this.envelope['bags'][this.numOfScanned]["id"];
            console.log(id);
            return this.envelope['bags'].find(o => o.id == id)
        },

        async handleResult(res) {
           
            await this.$helpers.timeout(1000)
            const myBag = this.getBag(res.id)

            if(myBag) {
                this.pushQueue({key:myBag.product, content:myBag})
                this.registerBag(myBag.id)
                if(this.allScanned) {
                    this.validationResult = {
                        status: true,
                        title: "Provpåse registrerat.",
                        color: myBag.color,
                        message: "Lägg påsen i "+ myBag.color +"<br> Hela emballaget är nu registrerat.",
                        actions: ["close"],
                    }
                }
                else {

                    this.validationResult = {
                        status: true,
                        title: "Provpåse registrerat.",
                        color: myBag.color,
                        message: "Lägg påsen i " + myBag.color + "<br> Fortsätt sedan med att registrera nästa provpåse.",
                        actions: ["continue"]
                    }

                }

            }
            else {
                this.validationResult = {
                    status: true,
                    title: "Provpåse kunde inte hittas.",
                    message: "Vänligen prova igen",
                    actions: ["continue"]
                }
            }

        },
        openItem(item) {
            this.$router.push({path: '/sampling/sample/' + item.id})
        }
    },
    computed: {
        ...mapGetters('demo', ['envelopes']),
        envelope() {
            return this.envelopes.find(o => o.id = this.id);
        },
        items() {
            return this.envelope ? this.envelope.bags : []
        },
        numOfScanned() {
            return this.items.filter(i => i.registred == true).length;
        },
        numOfMaxScan() {
            return this.items.length
        },
        percentageRegistred() {
            const percentage = (100 * this.numOfScanned) / this.numOfMaxScan;
            return [percentage];
        },
        allScanned() {
            return (this.numOfMaxScan - this.numOfScanned) === 0 ? true : false
        },
        allDone() {
            return this.items.some(o => o.answer != false)
        }
    },
    mounted() {
        const env = this.envelopes.find(o => o.id = this.id);
        this.setEnvelope(env)
    }
    
}
</script>

<style>

</style>