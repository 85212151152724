import axios from "axios";

const instance = axios.create({
  //baseURL: process.env.VUE_APP_API,
  baseURL: config.CONFIG_VUE_APP_API,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;