var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-chip-group',{attrs:{"mandatory":"","active-class":"primary--text"},model:{value:(_vm.selectedSite),callback:function ($$v) {_vm.selectedSite=$$v},expression:"selectedSite"}},_vm._l((_vm.A_site),function(site){return _c('v-chip',{key:site.id},[_vm._v(" "+_vm._s(site.name)+" ")])}),1)],1)],1),_c('izy-card',{attrs:{"title":_vm.site ? 'Labbköer ' + _vm.site.name : ''}},[_c('div',{staticClass:"mt-2"},[_c('apexchart',{attrs:{"type":"bar","height":"350","width":"100%","options":_vm.chartOptions,"series":_vm.series}})],1)]),_vm._l((_vm.queues),function(queue){return _c('div',{key:queue.id},[(queue.inner_bags && queue.inner_bags.length > 0)?_c('izy-card',{staticClass:"mt-8",attrs:{"title":queue.name}},[_c('v-data-table',{staticClass:"style-1",attrs:{"items":queue.inner_bags,"headers":_vm.headers,"single-expand":true,"show-expand":"","item-key":"id"},on:{"click:row":_vm.showRow},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.name)+" ")]}},{key:"item.received_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.received_at).format('YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"mb-0 font-weight-bold grey--text darken-2"},[_vm._v("Inlämnad av")]),_vm._v(" "+_vm._s(item.registerd_by.name)+" ")]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"mb-0 font-weight-bold grey--text darken-2"},[_vm._v("Provnamn")]),_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"mb-0 font-weight-bold grey--text darken-2"},[_vm._v("Rum")]),_vm._v(" "+_vm._s(item.room)+" ")]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"mb-0 font-weight-bold grey--text darken-2"},[_vm._v("Material")]),_vm._v(" "+_vm._s(item.material)+" ")]),_c('div',{staticClass:"col-4"},[_c('p',{staticClass:"mb-0 font-weight-bold grey--text darken-2"},[_vm._v("Övrigt")]),_vm._v(" "+_vm._s(item.description ? item.description : '-')+" ")])])])]}}],null,true)})],1):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }