<template>
    <!-- box-shadow: 0 2px 4px 0 hsl(198deg 45% 10% / 12%) -->
    <v-card
        style="box-shadow: 0px 2px 2px #00000012; border-radius: 5px"
        max-width="100%"
    >   
        <div 
            :class="[inlineMode ? 'inline-header px-2': 'card-header px-7 py-6', '']"
        >
            <div 
                class="d-flex justify-space-between"
                :class="[headerColumn ? 'flex-column' : 'align-center', inlineMode ? 'pa-5': '']"
            >
                <div class="d-flex align-center">
                    <v-avatar 
                        color="grey lighten-4"
                        size="72"
                        class="mr-4"
                        v-if="icon"
                    >
                        <v-img
                            :src="icon"
                            contain
                        />
                    </v-avatar>
                    <div>
                        <h2 class="h5 mb-0">{{title}}</h2>
                        <span>{{subtitle}}</span>
                    </div>
                </div>
                <slot name="header"></slot>                
            </div>

        </div>
        <div class="card-body pb-6">
            <slot />
        </div>
    </v-card>
</template>

<script>
export default {
    name: 'IzyCard',
    props: {
        title: {
            type: String,
        },
        subtitle: {
            type: String
        },
        icon: {
            type: String
        },
        inlineMode: {
            type: Boolean,
            default: false,
        },
        headerColumn: {
            type: Boolean,
            default: false,
        }
    },
}
</script>
<style lang="scss" scoped>
    .card-header {
        border-radius: 5px 5px 0 0;
        border-bottom: 2px solid #F5F5F5;
        background: #FBFBFB;
    }
    .inline-header {
        > div {
            border: 1px dashed #cacaca;
            border-radius: 5px;
        }


        h2, p {
           //color: var(--v-primary-base) !important
        }
    }
</style>