<template>
    <v-card v-if="journalItem" class="pa-4">
        <v-card-title class="align-start">
            <div class="d-flex flex-column">
                <div class="d-flex align-center" v-if="journalItem.client">
                    <span class="h5 text-uppercase font-weight-bold mb-0">{{journalItem.client.name}}</span>
                </div>
                
                <span class="body-1 mb-0">Inlämnad av: {{journalItem.registerd_by.name}}</span>
                
                <span class="body-1 mt-4">Produkt: {{journalItem.product_full_name}}</span>
                <span class="body-1">Fakturamärkning: {{journalItem.name_outer}}</span>
                <span class="body-1">Objekt: {{journalItem.reference}}</span>
                
            </div>
            
            <v-spacer></v-spacer>
            <div class="d-flex align-center" v-if="journalItem.journal_id">
                <v-icon
                        class="heading-6 mr-2"
                        color="primary"
                    >mdi-check-decagram</v-icon>
                <span class="text-h5">{{journalItem.journal_id}}</span>

            </div>
            
        </v-card-title>
        <v-card-text>
            <div class="d-flex card-flex-content mt-8 mb-8">
                <div class="d-flex flex-column">
                    <span class="body-1 font-weight-bold">Registrerad</span>
                    <span class="body-2">{{ journalItem.registerd_at ? moment(journalItem.registerd_at).format('YYYY-MM-DD HH:mm:ss') : '-' }}</span>
                </div>
                <div class="d-flex flex-column">
                    <span class="body-1 font-weight-bold">Mottagen</span>
                    <span class="body-2">{{ journalItem.received_at ? moment(journalItem.received_at).format('YYYY-MM-DD HH:mm:ss') : '-' }}</span>
                </div>
                <div class="d-flex flex-column">
                    <span class="body-1 font-weight-bold">Slutförd</span>
                    <span class="body-2">{{ journalItem.ended_at ? moment(journalItem.ended_at).format('YYYY-MM-DD HH:mm:ss') : '-' }}</span>
                </div>
                <div class="d-flex flex-column">
                    <span class="body-1 font-weight-bold">Svar skickat</span>
                    <span class="body-2">{{ journalItem.sent_at ? moment(journalItem.sent_at).format('YYYY-MM-DD HH:mm:ss') : '-' }}</span>
                </div>
            </div>
            <div class="d-flex card-flex-content">
                <div class="d-flex flex-column">
                    <span class="body-1 font-weight-bold">Provnamn</span>
                    <span class="body-2">{{journalItem.name_inner}}</span>
                </div>
                <div class="d-flex flex-column">
                    <span class="body-1 font-weight-bold">Rum</span>
                    <span class="body-2">{{journalItem.room}}</span>
                </div>
                <div class="d-flex flex-column">
                    <span class="body-1 font-weight-bold">Material</span>
                    <span class="body-2">{{journalItem.material}}</span>
                </div>
                <div class="d-flex flex-column">
                    <span class="body-1 font-weight-bold">Övrigt</span>
                    <span class="body-2">{{journalItem.description}}</span>
                </div>
            </div>
            <div class="mt-12 result-container">
                <h2 class="mb-4">Resultat</h2>
                <table class="result-table">
                    <thead>
                        <tr>
                            <th>Substans</th>
                            <th>Svar</th>
                            <th>Kommentar</th>
                            <th>Utförd av</th>
                            <th>Datum</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="journalResult in journalItem.results" :key="journalResult.id">
                            <td>{{ journalResult.substance.name }}</td>
                            <td>
                                <span 
                                    v-if="journalResult.substance.type === 'bool'"
                                    :class="[journalResult.presence === '1' ? 'negative-badge' : 'positive-badge']"
                                >
                                    {{journalResult.presence === '1' ? 'Ja' : 'Nej'}}</span>
                                <span v-else>{{journalResult.presence}}</span> <sup v-if="journalResult.substance.type != 'bool'">{{journalResult.substance.unit}}</sup>

                            </td>
                            <td>{{journalResult.comment}}</td>
                            <td>{{journalResult.handled.name}}</td>
                            
                            <td>{{ moment(journalResult.handled.date).format('YYYY-MM-DD HH:mm:ss') }}</td>
                        </tr>
                    </tbody>

                </table>


            </div>
        </v-card-text>
        <v-card-actions>

        <v-spacer></v-spacer>

        <v-btn
            color="green darken-1"
            text
            @click="close"
        >
            Close
        </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'JournalCard',
    props: {
        journalItem: {
            type: [Object, Boolean],
            default: () => {},
        }
    },
    methods: {
        close() {
            this.$emit('closeCard', true);
        }
    },
}
</script>
<style lang="scss" scoped>
  .result-container {
        h2 {
            font-size: 21px;
        }
    }
    table.result-table {
        width: 100%;
        border-collapse: collapse;
        tr {
            text-align: left;
            th {
                border-bottom: 2px solid #d9d9d9;
                padding: 1px 4px;
                color: #333;
                font-size: 13px;
            }
            td {
                padding: 12px 4px;
                width: 20%;
            }

        }

    }
    .card-flex-content {
        > div {
            width: 25%;
        }
    }
    .negative-badge {
        background: #ffe8e8;
        color: #b50000;
        border: 1px solid #ffafaf;
        padding: 6px 12px;
        border-radius: 4px;
    }
    .positive-badge {
        background: #E8FFEE;
        color: #00B54B;
        border: 1px solid #AFFFC4;
        padding: 6px 12px;
        border-radius: 4px;
    }
</style>