import helpers from '@/services/helpers.js';


const endpointIds = (endpointProps, state) => {
    let endpointIds = {};
    endpointProps.forEach(x => {
        if(state["O_" + x] && "id" in state["O_"+x]) {
            endpointIds[x] = state["O_"+x]["id"]
        }
    })
    if(Object.keys(endpointIds).length === 0) {
        return false; 
    }
    else {
        return endpointIds
    }
}

const arrayApiActions = (name, nameCap, api, strippedName, rules) => {

    let actions = {};

    actions[`list_${name}`] = async ({commit, dispatch, state}, payload = {}) => {
        // eslint-disable-next-line no-unused-vars
        let {ids = false, params = {}} = payload;

        if(rules[strippedName] && "endpointProps" in rules[strippedName] && !ids) {
            ids = endpointIds(rules[strippedName]["endpointProps"], state)
        }
        
        dispatch('changeThemeListLoadingState', true, {root:true})

        try {
            const { data } = await api[`list_${name}`](ids, params);
            commit(`SET_${nameCap}`, data.data ? data.data : data);
            return Promise.resolve();
        }
        catch(err) {
         
            return Promise.reject();
        }
        finally {
            
            await new Promise(resolve => setTimeout(() => { resolve(dispatch('changeThemeListLoadingState', false, {root:true})) }, 500))
           
        }
        
    };

    return actions;

}

const objectApiActions = (name, nameCap, api, strippedName, rules) => {
   
 
    let actions = {};

    actions[`get_${name}`] = async ({commit, dispatch, state}, payload) => {
        dispatch('changeThemeLoadingState', true, {root:true})
        let {id, ids = false, params = {}} = payload;

        if(rules[strippedName] && "endpointProps" in rules[strippedName] && !ids) {
            ids = endpointIds(rules[strippedName]["endpointProps"], state)
        }

        try {
            const {data} = await api[`get_${name}`](id, ids, params);
            commit(`SET_${nameCap}`, data);
            return Promise.resolve();
        }
        catch(err){
            console.error(err);
            return Promise.reject();
        }
        finally {
            await new Promise(resolve => setTimeout(() => { resolve(dispatch('changeThemeLoadingState', false, {root:true})) }, 100))
        }
    };
    //eslint-disable-next-line no-unused-vars
    actions[`create_${name}`] = async ({commit, dispatch, state}, payload) => {
        dispatch('changeThemeLoadingState', true, {root:true})
        //eslint-disable-next-line no-unused-vars
        let {
            input, 
            ids = false, 
            list = false,
            listObjectNamespace = '', 
            listStoreNamespace = '', 
            refreshObjectNamespace = '',
            refreshStoreNamespace = '',
            store=true, 
            resAction = true
        } = payload;
        
        if(rules[strippedName] && "endpointProps" in rules[strippedName] && !ids) {
            ids = endpointIds(rules[strippedName]["endpointProps"], state)
        }
        
        try {
            const res = await api[`create_${name}`](input, ids);
            if(resAction) {
                if(res.headers['content-location']) {
                    
                    if(list) {
                        
                        if(listStoreNamespace.length > 0 && listObjectNamespace.length > 0) {
                            dispatch(listStoreNamespace + "/list_" + listObjectNamespace, {}, {root:true})
                        }
                        else {
                            const listNameCap = listObjectNamespace.length > 0 ? helpers.capAllLetters(listObjectNamespace) : "A_" + helpers.capAllLetters(strippedName)
                            const listName = listObjectNamespace.length > 0 ? listObjectNamespace : "A_" + strippedName;
    
                            const { data } = await api[`list_${listName}`](ids);
                            commit(`SET_${listNameCap}`, data);
                        }

                    }
                    else {
                        if(refreshStoreNamespace.length > 0 && refreshObjectNamespace.length > 0) {
                            dispatch(refreshStoreNamespace + "/get_" + refreshObjectNamespace, {id:state[refreshObjectNamespace]["id"]}, {root:true})
                            if(listStoreNamespace.length > 0 && listObjectNamespace.length > 0) {
                                dispatch(listStoreNamespace + "/list_" + listObjectNamespace, {}, {root:true})
                            }
                        }
                        else {
                            const contentLocation = res.headers['content-location'].split(",");
                            
                            if(contentLocation.length <= 1 ) {
                                const id = res.headers['content-location'].split('/').pop();
    
                                const { data } = await api[`get_${name}`](id, ids);
                                commit(`SET_${nameCap}`, data);      
                            }
                        }
                    } 
                }
                else {
                    if(store) {
                        commit(`SET_${nameCap}`, res.data);  
                    }
                }
            }
            return Promise.resolve(res);
        }
        catch(err){

            return Promise.reject();
        }
        finally {
            await new Promise(resolve => setTimeout(() => { resolve(dispatch('changeThemeLoadingState', false, {root:true})) }, 100))
        }
    };

    
    actions[`update_${name}`] = async ({commit, dispatch, state}, payload) => {
        dispatch('changeThemeLoadingState', true, {root:true})
        let {
            input, 
            id, 
            ids = false, 
            list = false,
            refreshObjectNamespace = '',
            refreshStoreNamespace = ''
        } = payload;
    
        if(rules[strippedName] && "endpointProps" in rules[strippedName] && !ids) {
            ids = endpointIds(rules[strippedName]["endpointProps"], state)
        }

        try {

            await api[`update_${name}`](input, id, ids);

            if(list) {
                const listNameCap = "A_" + helpers.capAllLetters(strippedName)
                const listName = "A_" + strippedName
                const { data } = await api[`list_${listName}`](ids);
                commit(`SET_${listNameCap}`, data);
            }
            else {
                if(refreshStoreNamespace.length > 0 && refreshObjectNamespace.length > 0) {
                    dispatch(refreshStoreNamespace + "/get_" + refreshObjectNamespace, {}, {root:true})
                }
                else {
                    const { data } = await api[`get_${name}`](id, ids);
                    commit(`SET_${nameCap}`, data);
                }
            }

            return Promise.resolve();
        }
        catch(err){
            console.error(err);
            return Promise.reject();
        }
        finally {
            await new Promise(resolve => setTimeout(() => { resolve(dispatch('changeThemeLoadingState', false, {root:true})) }, 100))
        }
    };

    actions[`patch_${name}`] = async ({commit, dispatch, state}, payload) => {
        dispatch('changeThemeLoadingState', true, {root:true})
        let {input, id, ids = false, list = false} = payload;
    
        if(rules[strippedName] && "endpointProps" in rules[strippedName] && !ids) {
            ids = endpointIds(rules[strippedName]["endpointProps"], state)
        }

        try {
            await api[`patch_${name}`](input, id, ids);

            if(list) {
                const listNameCap = "A_" + helpers.capAllLetters(strippedName)
                const listName = "A_" + strippedName
                const { data } = await api[`list_${listName}`](ids);
                commit(`SET_${listNameCap}`, data);
            }
            else {
                const { data } = await api[`get_${name}`](id, ids);
                commit(`SET_${nameCap}`, data);
            }

            return Promise.resolve();
        }
        catch(err){
            console.error(err);
            return Promise.reject();
        }
        finally {
            await new Promise(resolve => setTimeout(() => { resolve(dispatch('changeThemeLoadingState', false, {root:true})) }, 100))
        }
    };
    //eslint-disable-next-line no-unused-vars
    actions[`delete_${name}`] = async ({commit, dispatch, state}, payload) => {
        dispatch('changeThemeLoadingState', true, {root:true})
        let {id, ids = false, params = {}} = payload;

        if(rules[strippedName] && "endpointProps" in rules[strippedName] && !ids) {
            ids = endpointIds(rules[strippedName]["endpointProps"], state)
        }

        try {
            //eslint-disable-next-line no-unused-vars
            const {data} = await api[`delete_${name}`](id, ids, params);

            //commit(`REMOVE_${nameCap}`, id);
            return Promise.resolve();
        }
        catch(err){
            console.error(err);
            return Promise.reject();
        }
        finally {
            await new Promise(resolve => setTimeout(() => { resolve(dispatch('changeThemeLoadingState', false, {root:true})) }, 100))
        }
    };
    

   
    return actions;

}

const build = (base = {}) => {
    const rules = ("stateRules" in base.state) ? base.state['stateRules'] : false;
    let state = base.state ? base.state : {};
    let mutations = base.mutations ? base.mutations : {};
    let getters = base.getters ? base.getters : {};
    let actions = base.actions ? base.actions : {};

    
    Object.keys(state).forEach(key => {
        
        const nameCap = helpers.capAllLetters(key);

        /*GETTER*/
        getters[key] = (state) => state[key]

        /*IF ARRAY*/
        if (Array.isArray(state[key])) {

            /*MUTATIONS*/
            mutations[`SET_${nameCap}`] = (state, data) => {
                state[key] = data;
            };    

            mutations[`PUSH_${nameCap}`] = (state, data) => {
                state[key].push(data);
            };
    
            mutations[`REMOVE_${nameCap}`] = (state, index) => {

                //const index = state[key].find(i => i.id == id);
                state[key].splice(index, 1);
            };

            mutations[`UPDATE_${nameCap}`] = (state, payload) => {
            
                const {index, object} = payload;
                //const index = state[key].find(i => i.id == id);
                state[key][index] = object;
                state[key] = [...state[key]]
            };

        }
        
        /* IF OBJECT = SINGLE */
        else if(helpers.isObject(state[key])) {
            
            mutations[`SET_${nameCap}`] = (state, data) => {
               
                state[key] = {...state[key], ...data};
            };
            
            mutations[`CLEAR_${nameCap}`] = (state) => {
                state[key] = {};
            };
            
        }

        //Check if we should assign base actions
        const keySplit = key.split('_');
        let tempAction = {}
        if(keySplit[0] === 'A') {
            //Object.assign(actions, arrayActions(key, nameCap, base.api))
            tempAction = arrayApiActions(key, nameCap, base.api, keySplit[1], rules)
        }
        else if(keySplit[0] === 'O') {
            //Object.assign(actions, objectActions(key, nameCap, base.api))
            tempAction = objectApiActions(key, nameCap, base.api, keySplit[1], rules)
        }
        
        //Check and filter before return
        if(rules && keySplit[1] in rules) {
            if("actions" in rules[keySplit[1]]) {
                const keys = rules[keySplit[1]]['actions'].map(x => x + '_' + key)
                tempAction = helpers.filterByKeyArray(tempAction, keys);
            }
        }

        Object.assign(actions, tempAction)

    });

    return {state, mutations, actions, getters};
};



export default {
    extend(base) {

        const module = build(base)
        module.namespaced = true;
        return module;
    }
}