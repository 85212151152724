<template>
    <div>
        
        <zebra-qr-scanner
            ref="qrScanner"
            @code="onQrCode"
        >
            <template #default="{isQrFocused}"> 
                <div class="d-flex items-center justify-center mt-6">
                    <h2 v-if="isOuterBagMode && isQrFocused">Väntar på ny emballagepåse...</h2>
                    <h2 v-else-if="!isOuterBagMode && isQrFocused && !allInnerBagsRegistred">Väntar på ny provpåse...</h2>
                </div>
            </template>  
        </zebra-qr-scanner>


        <div v-if="outerBag">
            <base-card class="px-6 py-4 mb-4">
                <v-row>
                    <v-col cols="12" md="3">
                        
                        <apexchart
                            type="radialBar"
                            height="250"
                            :options="radialChart.chartOptions"
                            :series="percentageRegistred"
                        />

                    </v-col>
                    <v-col cols="12" md="6" align-self="center">
                        <div class="d-flex align-start justify-space-between">
                            <div class="mb-3">
                                <h5 class="mr-2 mb-0">
                                    {{outerBag.client.name}}
                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{on, attrs}"
                                        >
                                            <v-icon
                                                class="body-1"
                                                color="primary"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                mdi-check-decagram</v-icon
                                            >
                                        </template>
                                        <span>Verified</span>
                                    </v-tooltip>
                                </h5>
                                <p class="text--disabled mb-0">Emballage: {{outerBag.outerbag.id}}</p>
                            </div>
                        </div>
                        <div class="d-flex mb-2">
                            <p class="ma-0 mr-8">
                                <span class="font-weight-medium mr-8">{{innerBagsList.length}} provpåsar</span>
                            </p>
                            <p class="ma-0 mr-8">
                                <span class="font-weight-medium mr-8">Fakturamärkning: {{outerBag.outerbag.name}}</span>
                            </p>
                            <p class="ma-0 mr-8">
                                <span class="font-weight-medium mr-8">Objekt: {{outerBag.outerbag.reference}}</span>
                            </p>
                            <p class="ma-0 mr-8">
                                <span class="font-weight-medium mr-8">Övrigt: {{outerBag.outerbag.description}}</span>
                            </p>
                        </div>
                        <p class="text--disabled">
                            Provet skickades in av {{outerBag.outerbag.registered_by.name}}, {{ moment(outerBag.outerbag.registered_at).format('YYYY-MM-DD HH:mm:ss') }}.
                        </p>
                    </v-col>
                    <v-col md="3">
                        <div class="text-right d-flex flex-column" style="height: 100%">
                            <div>
                                <v-chip class="green--text green lighten-5" v-if="allInnerBagsRegistred">
                                    Allt registrerat!
                                </v-chip>
                                <v-chip class="red--text red lighten-5" v-else>
                                    Ej klar
                                </v-chip>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </base-card>

            <base-card :padding="true">
                <v-card-title>Provpåsar</v-card-title>
                <v-card-text>
                    <v-data-table
                        hide-default-footer
                        :headers="headers"
                        :items="innerBagsList"
                        v-if="innerBagsList"
                        class="elevation-1 eg-table-bnone eg-table-td-py-md"
                    >

                        <template v-slot:item.registred="{item}">
                            <v-avatar
                                :color="item.received_at ? 'green lighten-5' : 'red lighten-5'"
                                size="28"
                            >
                                <v-icon 
                                    small 
                                    :color="item.received_at ? 'green' : 'red'"
                                >
                                    {{item.received_at ? 'mdi-check' : 'mdi-minus'}}
                                </v-icon>
                            </v-avatar>
                        </template>

                        <template v-slot:item.product="{ item }">
                            {{item.product.name}}
                        </template>


                    </v-data-table>
                </v-card-text>
            </base-card>

            <v-dialog
                v-model="dialog"
                max-width="600px"
                @click:outside="resetInner"
            >
                <edit-form
                    title="Välj kö"
                    subTitle="Välj vilken kö provpåsen ska läggas i"
                    :schema="schema"
                    storeNamespace="arrivalsNormal"
                    objectNamespace="O_inner"
                    :stateRulesIds="endpointProps"
                    :clearStoreOnCreate="false"
                    :resetFormEnabled="false"
                    :resetOnDone="true"
                    @requestIsDone="onInnerBagSaved"
                >
        
                </edit-form>   
            </v-dialog>


        </div>


    </div>
</template>

<script>
import {mapActions, mapMutations, mapGetters} from 'vuex'
import { focus } from 'vue-focus';
import VueApexCharts from 'vue-apexcharts'
import ZebraQrScanner from '@/components/util/ZebraQrScanner'
import eventBus from '@/plugins/eventbus.js'
import EditForm from '@/components/form/EditForm'
import {
    radialChart,
} from '../data/apexChart'

export default {
    name: 'NormalArrival',
    directives: { focus: focus },
    components: {
        'apexchart': VueApexCharts,
        ZebraQrScanner,
        EditForm
    },
    data() {
        return {
            radialChart,
            isQrFocused: false,
            dialog: false,
            outerBag: "",
            outerBagId: "",
            headers: [
                {
                    text: 'Registrerad',
                    sortable: false,
                    value: 'registred',
                    slot: true
                },
                {
                    text: 'Provpåse',
                    sortable: false,
                    value: 'id'
                },
                {
                    text: 'Produkt',
                    sortable: false,
                    value: 'product',
                    slot: true
                },
                {
                    text: 'Kö',
                    sortable: false,
                    value: 'queue_full_name'
                }
            ]
        }
    },

    computed: {
        isOuterBagMode() {
            return !this.outerBagId ? true : false;
        },
        innerBagsList() {
            if(this.outerBag && this.outerBag.outerbag.inner_bags) {
                return this.outerBag.outerbag.inner_bags
            }
            else {
                return []
            }
        },
        numOfInnerBagsRegistred() {
            return this.outerBag && this.outerBag.outerbag.inner_bags ? this.outerBag.outerbag.inner_bags.filter(bag => bag.received_at).length : 0
        },
        allInnerBagsRegistred() {
            return (this.numOfInnerBagsRegistred > 0 && this.numOfInnerBagsRegistred === this.innerBagsList.length) ? true : false;
        },
        percentageRegistred() {
            const percentage = ((100 * this.numOfInnerBagsRegistred) / this.innerBagsList.length).toFixed(2);
            return [percentage];
        },
        endpointProps() {
            return this.outerBagId ? {normal: this.outerBagId} : {}
        },
        schema() {
            const queues = this.scannedInnerBag ? this.scannedInnerBag.product.queues.map(e => {
                return {
                    text: e.name_full,
                    value: e.id
                }
            }) : [];

            const filter = {
                inner_bag_id: '{type:"input", rules:"required", readonly: true}',
                queue_id: '{type:"select", items:'+ JSON.stringify(queues)  +' , rules:"required", setDefaultValue: false}',
            }
            return this.$helpers.schemaParser(filter)
        },
        scannedInnerBag() {
            if(this.O_inner.inner_bag_id) {
                return this.innerBagsList.find(x => x.id == this.O_inner.inner_bag_id);
            }
            else {
                return false;
            }
        },
        ...mapGetters('arrivalsNormal', ['O_inner'])
    },
    methods: {
        ...mapActions('arrivalsNormal', ['create_O_normal', 'create_O_inner']),
        ...mapMutations("arrivalsNormal", ['SET_O_INNER', 'CLEAR_O_INNER']),
        async sendOuterBag(id) {
            try {
                const res = await this.create_O_normal({
                    input:{outer_bag_id: id}, 
                    store: false
                })

                this.outerBagId = id;
                this.outerBag = res.data;
    
            } catch (error) {
                console.log(error)
            }
        },
        /*
        async sendInnerBag(id) {
            try {
                const res = await this.create_O_inner({
                    input:{inner_bag_id: id},
                    ids:{normal: this.outerBagId},
                    store:false
                })
                this.outerBag = res.data;
                console.log(res)
    
            } catch (error) {
                console.log(error)
            }
        },
        */
        onQrCode(qrcode) {

            if(this.isOuterBagMode) {
                if(qrcode.type === 'outerbag') {
                    this.sendOuterBag(qrcode.id)
                }
                else {
                    eventBus.$emit.snackMsg({
                        "text": "Koden är inte en giltig emballagepåse",
                        "type": "danger"
                    });
                }
            }
            else {
                
                if(qrcode.type === 'inner') {
                    this.SET_O_INNER({inner_bag_id: qrcode.id})
                    this.dialog = true;
                    
                    //OK POST!
                    //this.sendInnerBag(qrcode.id)
                }
                else {
                    eventBus.$emit.snackMsg({
                        "text": "Koden är inte en giltig innerpåse",
                        "type": "danger"
                    });
                }
            }


        },
        onInnerBagSaved(res) {
            this.outerBag = res;
            this.resetInner();
            this.dialog = false;
        },
        resetInner() {
            this.CLEAR_O_INNER();
            this.$refs.qrScanner.focusQrInput
        }
    },
    watch: {
        allInnerBagsRegistred: {
            handler(val) {
                if(val) {
                    this.outerBagId="";
                }

            },
            immediate: true
        }
    },

}
</script>

<style lang="scss" scoped>

</style>