<template>
    <v-switch
        :label="label"
        :error-messages="errors"
        :input-value="value"
        inset
        v-on="$listeners"
    />
</template>

<script>

export default {
    name: "SwitchBtn",
    props: {
        label: {
            type: String,
            default: ""
        },
        errors: {
            type:[String, Array],
            default: function() {
                return []
            }
        },
        value: {
            type:Boolean,
            default: false
        },
        onResetForm: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            initialValue: false,
        }
    },
    watch: {
        onResetForm: {
            handler() {
                this.$emit('change', this.initialValue);
            }
        }
    },
    created () {
        this.$emit('change', this.value);
        this.initialValue = this.value
    },
}
</script>

<style>

</style>