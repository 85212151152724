<template>

    <div>
        <base-cta-list
            title="Kunder"
            subtitle="Här hittar du dina kunder som är upplagd i tjänsten"
            :headers="headers"
            storeNamespace="clients"
            listNamespace="A_client"
            objectNamespace="O_client"
            :icon="customerLogo"
            buttonText="Ny kund"
            :changePathOnEdit="true"
            editPath="card"
    
        >
    
            <template #dialog-content="{setDialog}"> 
                 <client-form-stepper
                    :inlineMode="true"
                    @stepper-done="setDialog(false)"
                 ></client-form-stepper>
             </template>
    
        </base-cta-list>

    </div>



</template>

<script>
import ClientFormStepper from "../components/ClientFormStepper.vue"
//import { mapMutations, mapActions } from "vuex";

export default {
    name: "Clients",
    metaInfo: {
        title: 'Clients'
    },
    components: {
        ClientFormStepper
    },
    data() {
      return {
        dialogDelete: false,
        itemToDelete: null,
        headers: [
            {
                text: 'Namn',
                align: 'start',
                sortable: true,
                value: 'name',
                width: '30%'
            },
            {
                text: 'Typ',
                align: 'start',
                sortable: true,
                value: 'type'
            },
            {
                text: 'Org.nr / Pers.nr',
                align: 'start',
                sortable: true,
                value: 'authority_id'
            },
            {
                text: 'Actions', 
                value: 'actions',
                delete: true,
                align: 'end',
                slot: true
            }
            
        ],
        customerLogo: require('@/assets/images/svg/customer.svg')
      }
    },
    methods: {
        /*
      ...mapMutations("clients", ['REMOVE_A_CLIENT']),
      ...mapActions("clients", ['delete_O_client']),
      deleteItem(entity) {
        this.dialogDelete = true;
        this.itemToDelete = entity.id;
      },
      deleteItemConfirm() {
        this.dialogDelete = false;
        this.delete_O_client({id:this.itemToDelete});
        //this.REMOVE_A_CLIENT(this.itemToDelete);
      },
      closeDelete() {
        this.dialogDelete = false;
        this.itemToDelete = null;
      }
      */
    },
    computed: {

    },
    created() {
      
    }
}
</script>

<style>

</style>