<template>
    <select-data
        v-bind="$props"
        @input="set($event)"
    />
</template>

<script>
import SelectData from '@/components/form/fields/SelectData'
import {mapGetters, mapMutations} from 'vuex'
export default {
    components: {
        SelectData,
    },
    props: {
        item: {
            type: Object,
            default: function() {
                return {}
            }
        },
        errors: {
            type:[String, Array],
            default: function() {
                return []
            }
        },
        value: {
            type:[String, Array],
            default: ''
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        triggerReload: {
            type: Number,
            default: 0
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    beforeCreate() {
        let storeNamespace = this.$options.propsData.item.storeNamespace;
        let objectNamespace = this.$options.propsData.item.objectNamespace;
        objectNamespace = 'O_' + objectNamespace.split("_")[1];

        let objectNamespaceUpperCase = objectNamespace.toUpperCase();

        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters(storeNamespace, [objectNamespace]),
            getObj: () => {
                return this[objectNamespace]
            }
        }

        this.$options.methods = {
            ...this.$options.methods,
            ...mapMutations(storeNamespace, ['SET_'+objectNamespaceUpperCase]),
            setData: (input) => {
                this['SET_'+objectNamespaceUpperCase](input)
            }
        }
    },
    methods: {
        set(ev) {
            this.setData({id:ev})
            this.$emit('input', ev)
        }
    },

}
</script>

<style>

</style>