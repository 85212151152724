<template>
  <div>

      <base-stepper
        title="Ny kund"
        subtitle="Här kan du skapa en ny kund"
        v-slot="{changeStep, currentStep}"
        :numOfSteps="3"
        :hideNavigator="inlineMode"
        :hideTitle="inlineMode"
      >

        <v-stepper-items>
          
          <step-form-content
            title="Kunduppgifter"
            titleKey="type"
            subTitle="Vänligen fyll i kundens uppgifter"
            :schema="schemaStep1"
            storeNamespace="clients"
            objectNamespace="O_client"
            :step="1"
            :saveOnContinue="true"
            :clearStoreOnCreate="true"
            @step-change="changeStep"
          ></step-form-content>


          <step-form-content
            title="Avtal"
            subTitle="Skapa ett nytt avtal för kunden"
            :schema="schemaStep2"
            storeNamespace="clients"
            objectNamespace="O_agreement"
            :saveOnContinue="true"
            :clearStoreOnCreate="true"
            :step="2"
            @step-change="changeStep"
          ></step-form-content>

          <step-form-content
            title="Användarkonto"
            subTitle="Skapa ett nytt användarkonto till klienten"
            :schema="schemaStep3"
            storeNamespace="clients"
            objectNamespace="O_user"
            :saveOnContinue="true"
            :clearStoreOnCreate="true"
            :step="3"
            @step-change="changeStep"
          ></step-form-content>
          
          <step-form-content
            title="Tilldela behörigheter"
            subTitle="Välj vilka behörigheter du vill tilldela användaren"
            :schema="schemaStep4"
            storeNamespace="clients"
            objectNamespace="O_permission"
            :clearStoreOnCreate="true"
            :step="4"
            :saveOnContinue="true"
            :arrayMode="false"
            :lastStep="true"
            :currentStep="currentStep"
            @step-change="changeStep"
            @step-done="onDone"
            :listOnSave="true"
            listObjectNamespace="A_client"
          ></step-form-content>
        </v-stepper-items>
      </base-stepper>

  </div>
</template>

<script>
import StepFormContent from '@/components/form/StepFormContent'
import {clientSchema} from '../schema/clientSchema.js'
import {clientAgreementSchema} from '../schema/clientAgreementSchema.js'
import {clientUserSchema} from '../schema/clientUserSchema.js'
import {clientUserPermissionSchema} from '../schema/clientUserPermissionSchema.js'
export default {
    name: "ClientStepper",
    components: {
      StepFormContent
    },
    props: {
      inlineMode: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      onDone() {
        this.$emit('stepper-done');
      }
    },
    computed: {

        schemaStep1() {
            return this.$helpers.schemaParser(clientSchema)
        },
        schemaStep2() {
          return this.$helpers.schemaParser(clientAgreementSchema)
        },
        schemaStep3() {
          return this.$helpers.schemaParser(clientUserSchema)
        },
        schemaStep4() {
          return this.$helpers.schemaParser(clientUserPermissionSchema)
        },
    }
}
</script>

<style>

</style>