<template>
    
    <edit-form
        :title="title"
        :subTitle="subTitle"
        :schema="schema"
        storeNamespace="clients"
        objectNamespace="O_client"
        :sectionTitles="sectionTitles"
        :columnLayout=6
        :objectId="id"
        :listOnSave="false"
    >
    </edit-form>

</template>

<script>
import EditForm from '@/components/form/EditForm'
import {clientSchema} from '../schema/clientSchema.js'
export default {
    name: "ClientForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        }
    },
    data() {
      return {
        sectionTitles: [
            '',
            'Kundinställningar',

        ]
      }
    },
    computed: {

        schema() {
            return this.$helpers.schemaParser(clientSchema)
        },

        title() {
            return this.id ? "Uppdatera kund" : "Ny kund";
        },
        subTitle() {
            return this.id ? "Redigera information om kunden" : "Fyll i information om den nya kunden";
        },
        
    }
}
</script>

<style>

</style>