<template>

    <edit-form
        :title="title"
        :subTitle="subTitle"
        :schema="schema"
        storeNamespace="services"
        objectNamespace="O_group"
        :objectId="id"
        :listOnSave="listOnSave"
    ></edit-form>

</template>

<script>
import EditForm from '@/components/form/EditForm'

export default {
    name: "ServiceGroupForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
    },
    computed: {
       schema() {
            const filter = {
                name: '{ type:"input", rules:"required" }',
            }
            
            return this.$helpers.schemaParser(filter)
        },
        title() {
            return this.id ? "Update service group" : "New service group";
        },
        subTitle() {
            return this.id ? "Here you can edit a service group" : "Here you can create a new service group";
        },
        
    },
}
</script>

<style>

</style>