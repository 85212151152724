<template>

    <edit-form
        :title="title"
        :subTitle="subTitle"
        :schema="schema"
        storeNamespace="services"
        objectNamespace="O_unit"
        :objectId="id"
        :listOnSave="listOnSave"
    >

    </edit-form>

</template>

<script>
import EditForm from '@/components/form/EditForm'

export default {
    name: "ServiceUnitForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
    },
    computed: {
       schema() {
            const filter = {
                name: '{ type:"input", rules:"required" }',
                type: '{ type:"select", rules:"required", items:[{"text":"True/False", "value":"bool"}, {"text":"Floating value", "value":"float"}, {"text":"Numeric value", "value":"integer"}] }',
                description: '{ type:"textarea"}',
            }
            
            return this.$helpers.schemaParser(filter)
        },
        title() {
            return this.id ? "Update unit" : "New unit";
        },
        subTitle() {
            return this.id ? "Here you can edit a unit" : "Here you can create a new unit";
        },
        
    },
}
</script>

<style>

</style>