import BaseStore from "@/modules/baseStore.js";
import BaseApi from "@/modules/baseApi.js";

const state = {

  A_pricepackage: [],
  O_pricepackage: {},

  A_pricelist: [],
  O_pricelist: {},

  stateRules: {
    pricelist: {
      endpointProps: ['pricepackage']
    },

  }
  
}

const api = BaseApi(state, "sample/service")


export default BaseStore.extend({
  state,
  api
})