<template>
    <div>
        <base-card>
            <v-card-title primary-title>
                <div>
                    <h3 class="headline mb-0">{{title}}</h3>
                </div>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-form-base :col="{ cols:12, sm:8, md:6, lg:4 }" :model="model" :schema="mySchema" @input="handleInput"/>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary">Save</v-btn>
            </v-card-actions>
        </base-card>
    </div>
</template>
<script>
import VFormBase from 'vuetify-form-base';
export default {
    name: "Form",
    components: {
        VFormBase
    },
    props: {
        title: {
            type: String,
            default: 'New'
        },
        model: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            mySchema: {
                company: { type: 'text', label: 'Företag', class: "ma-2" },
                address: { type: 'text', label: 'Adress', class: "ma-2" },
                zip: { type: 'text', label: 'Postnummer', class: "ma-2" },
                city: { type: 'text', label: 'Ort', class: "ma-2" },
                organizationNumber: { type: 'text', label: 'Organisationsnummer', class: "ma-2" },
                contactPerson: { type: 'text', label: 'Kontaktperson', class: "ma-2" },
                email: { type: 'email', label: 'Email', class: "ma-2" },
                phone: { type: 'text', label: 'Telefon', class: "ma-2" },
   
            }
        }
    },
    methods:{
        handleInput() {

        }
    }
}
</script>
<style lang="scss">
    
</style>