import { render, staticRenderFns } from "./LabJournals.vue?vue&type=template&id=85b87ab4&scoped=true"
import script from "./LabJournals.vue?vue&type=script&lang=js"
export * from "./LabJournals.vue?vue&type=script&lang=js"
import style0 from "./LabJournals.vue?vue&type=style&index=0&id=85b87ab4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85b87ab4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VDataTable,VDialog,VIcon,VProgressCircular})
