<template>
  <v-app>
    <router-view />
    <base-snackbar></base-snackbar>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  },
  data: () => ({

  }),

};
</script>
