
  export const customChartSeventeen = {
    chartOptions: {
      colors: ['#2196f3'],
      chart: {
        height: 350,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '75%',
          },
          dataLabels: {
            value: {
              fontSize: '20px',
            },
            total: {
              show: true,
              label: 'Registrerat',
              
            }
          }
        },
        
      },
      stroke: {
        lineCap: 'round'
      },
      
    },
  }