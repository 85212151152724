<template>

    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="300px"
    >

      <v-card v-if="groups">
        <v-card-title class="justify-space-between">
          <div v-show="step == 1">
            Välj organisation
          </div>
          <div v-show="step == 2">
            Välj vy
          </div>
           <v-btn
            icon
            v-show="organization && dashboardView"
            @click="dialog = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <div>
            <v-radio-group
              v-model="selectOrganization"
              column
              v-if="step == 1"
            >
              <v-radio 
                v-for="(organization, index) in groups"
                :key="`organization-${index}`"
                :label="organization[0].prefix" 
                :value="index">

              </v-radio>
    
            </v-radio-group>
          </div>
          
          <div>
            <v-radio-group 
              v-model="selectDashboardView" 
              column 
              v-if="step == 2">
                <template 
                  v-for="(role, index) in groups[selectOrganization]"
>
                  <v-radio                  
                  :key="`role-${index}`"
                  v-if="role.name != 'app'"
                  :label="$t('labels.'+`${role.name}`)" 
                  :value="role.id"></v-radio>

                </template>
            </v-radio-group>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right pa-0">
            <v-btn
              color="blue darken-1"
              text
              v-if="step > 1"
               @click="prevStep()"
            >
              Föregående
            </v-btn>
            <v-btn
              text
              color="blue darken-1"
              v-if="step <= 1"
              :disabled="!organization"
               @click="nextStep()"
            >
              Välj vy
            </v-btn>
            <v-btn
              color="blue darken-1"
              v-if="step == 2 && dashboardView"
              text
              @click="go()"
            >
              Go!
            </v-btn>
          </v-col>
         
        </v-card-actions>
      </v-card>

      <v-card v-else>
        <v-card-title class="justify-space-between">
          Missing Permissions
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          Currently you are missing permissions to view the dashboard, you can sign in and see your profile but you dont have access to other parts of the service. 
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between">
        
            <v-btn
              color="blue darken-1"
              text
               @click="toProfile()"
            >
              My profile
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="doSignOut()"
            >
              SignOut!
            </v-btn>
     
         
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import eventBus from '@/plugins/eventbus.js'
export default {

  data: () => ({
    dialog: false,
    view: '',
    step: 1,
  }),
  computed: {
    ...mapGetters('profile', ['userProfile', 'organization', 'dashboardView', 'dashboardIsValid', 'dashAndOrgExist', 'userProfileExist']),
    selectOrganization: {
      get() {
        return this.organization
      },
      set(value) {
        this.setOrganization(value)
      }
    },
    selectDashboardView: {
      get() {
        return this.dashboardView ? this.dashboardView.id : "";
      },
      set(value) {
        const {id, name, client_name} = this.groups[this.selectOrganization].find(o => o.id === value);
        this.setDashboardView({id: id, name: name.toLowerCase(), client_name: client_name})
      }
    },
    groups() {
      if(this.userProfileExist && this.userProfile.groups) {
        const groups = this.userProfile.groups.filter(o => o.type === "group");
        const filtered = this.$helpers.groupByKey(groups, 'client_id');

        return filtered
      }
      else {
        return false;
      }
    }


  },
  methods: {
    ...mapActions('profile', ['setOrganization', 'setDashboardView', 'getUser']),
    openDialog() {
        this.step = 1;
        this.dialog = true;
    },
    nextStep() {
      this.step++;
    },
    prevStep() {
      this.step--;
    },
    go() {
      this.dialog = false; 

      if(this.$router.currentRoute.path != '/dashboard') {
        this.$router.push('/dashboard')
      }
    },
    toProfile() {
      this.dialog = false;
 
      if(this.$router.currentRoute.path != '/profile/user') {
        this.$router.push('/profile/user')
      }
    },
    doSignOut() {
      eventBus.$emit.doSignOut();
    },
    async initCheck() {
  
        //We have user
        if(this.userProfileExist) {
            
            //Missing dash or org
            if(!this.dashAndOrgExist) {
                this.dialog = true;
                //console.log("Missing dash or org")
            }
            else {
                if(!this.dashboardIsValid) {
                    this.dialog = true;
                    //console.log("All not valid together")
                }
            }
        }
        //Missin user try to fetch
        else {
  
            try {
                //console.log("Missing user")
                await this.getUser();
                if(!this.dashboardIsValid) {
                    this.dialog = true;
                    //console.log("Missing user. User fetched. But dashboard is not valid")
                }
            }
            catch(err) {
                console.log(err);
            }

        }

    }
  },
  
  watch: {
      dashboardIsValid: function(val) {
          if (!val) {
            this.openDialog();
          }
        },
  },
  
  created() {
    
    eventBus.$on.organizationPicker(this.openDialog)

    this.initCheck();
 
  }

}
</script>
