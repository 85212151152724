var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[(_vm.loading)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table-heading, list-item-two-line, table-tfoot"}})],1):_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"px-6 py-0 mb-4",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Sök","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"style-1",attrs:{"items":_vm.A_result,"headers":_vm.headers,"single-expand":true,"show-expand":"","item-key":"id","search":_vm.search},on:{"click:row":_vm.showRow},scopedSlots:_vm._u([{key:"item.received_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.received_at).format('YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"item.data-num-of-bags",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","small":""}},[_vm._v(" "+_vm._s(item.inner_bags.length)+" ")])]}},{key:"item.data-table-expand",fn:function(ref){
var isExpanded = ref.isExpanded;
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4"},'v-icon',attrs,false),on),[_vm._v(" mdi-menu ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Rapporter")]),_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{on:{"click":function($event){return _vm.sendCC(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-email-multiple-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Skicka ut kopia")])],1)],1)],1)],1)],1),_c('v-icon',{on:{"click":_vm.showRow}},[_vm._v(_vm._s(isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"row pa-5"},[_c('table',{staticClass:"simple-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Journal id")]),_c('th',[_vm._v("Provnamn")]),_c('th',[_vm._v("Rum")]),_c('th',[_vm._v("Material")])])]),_c('tbody',_vm._l((item.inner_bags),function(row){return _c('tr',{key:row.id,on:{"click":function($event){return _vm.openResultItem(row)}}},[_c('td',[_vm._v(_vm._s(row.journal_id))]),_c('td',[_vm._v(_vm._s(row.name_inner))]),_c('td',[_vm._v(_vm._s(row.room))]),_c('td',[_vm._v(_vm._s(row.material))])])}),0)])])])]}}])}),_c('v-dialog',{attrs:{"width":"980px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('journal-card',{attrs:{"journal-item":_vm.resultItem},on:{"closeCard":function($event){_vm.dialog=false}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }