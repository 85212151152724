<template>

    <edit-form
        :title="title"
        :subTitle="subTitle"
        :schema="schema"
        storeNamespace="services"
        objectNamespace="O_product"
        :objectId="id"
        :listOnSave="listOnSave"
    >

    </edit-form>

</template>

<script>
import EditForm from '@/components/form/EditForm'

export default {
    name: "ServiceProductForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
    },
    computed: {
       schema() {
            const filter = {
                name: '{ type:"input", rules:"required" }',
                group_id: '{type:"selectData", exclude:["update"], rules:"required", storeNamespace: "services", objectNamespace: "A_group", itemText: "name"}',
                substance_ids: '{type:"selectData", exclude:["update"], rules:"required", storeNamespace: "services", objectNamespace: "A_substance", itemText: "name", multiple: "true"}',
                queue_ids: '{type:"selectData", rules:"required", storeNamespace: "samples", objectNamespace: "A_queue", itemText: "name_full", multiple: "true"}',
                response_time_min: '{ type:"input", rules:"required|numeric|min_value^:0", inputType:"number" }',
                response_time_max: '{ type:"input", rules:"required|numeric|min_value^:1", inputType:"number" }',
                description: '{ type:"textarea"}',
            }
            
            return this.$helpers.schemaParser(filter)
        },
        title() {
            return this.id ? "Update service product" : "New service product";
        },
        subTitle() {
            return this.id ? "Here you can edit a service product" : "Here you can create a new service product";
        },
        
    },
}
</script>

<style>

</style>