<template>
    <v-autocomplete
        :placeholder="'Select ' + $t('fields.'+`${item.field}`)"
        :error-messages="errors"
        :label="$helpers.capFirstLetter($t('fields.'+`${item.field}`))"
        :value="value"
        :items="items"
        :item-text="itemText"
        :item-value="item.itemValue ? item.itemValue : 'id'"
        :loading="isLoading"
        :disabled="isLoading"
        :multiple="item.multiple ? !!item.multiple : false"
        v-on="$listeners"
        outlined
        :clearable="!readOnly"
        :deletable-chips="item.multiple ? !!item.multiple : false"
        :chips="item.multiple ? !!item.multiple : false"
        :return-object="(item.returnObject || returnObject) ? true : false"
        :readonly="readOnly"
        :class="[readOnly ? 'disabled-input' : '']"

    ></v-autocomplete>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    name: "SelectData",
    props: {
        item: {
            type: Object,
            default: function() {
                return {}
            }
        },
        errors: {
            type:[String, Array],
            default: function() {
                return []
            }
        },
        value: {
            type:[String, Array, Object],
            default: ''
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        triggerReload: {
            type: Number,
            default: 0
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        initialObject: {
            type: [Object, Boolean, String]
        }
    },
    beforeCreate() {
        let storeNamespace = this.$options.propsData.item.storeNamespace;
        let objectNamespace = this.$options.propsData.item.objectNamespace;
        
        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters(storeNamespace, [objectNamespace]),

        }
        this.$options.methods = {
            ...this.$options.methods,
            ...mapActions(storeNamespace, ['list_'+ objectNamespace])
        }
    },
    data () {
        return {
            isLoading: false
        }
    },
    computed: {
        items() {
            return this[this.item.objectNamespace].map(obj => {
                const val = this.item.itemValue ? this.item.itemValue : 'id'
                return {
                   [this.item.itemText]: obj[this.item.itemText],
                   [val]: obj[val],
                   ...(this.item.itemSecondText && {[this.item.itemSecondText]: obj[this.item.itemSecondText]})
                }
            });
        }
    },
    methods: {
        async loadData() {
            this.isLoading = true;
            await this['list_'+this.item.objectNamespace]({toggleLoad: false});
            this.isLoading = false;
        },
        itemText(input) {
            if(this.item.itemSecondText) {
                return `${input[this.item.itemText]} <${input[this.item.itemSecondText]}>`;
            }
            else {
                return input[this.item.itemText]
            }
        }
    },
    watch: {
        triggerReload: {
            handler() {
                this.loadData();
            }
        },
        initialObject: {
            handler() {
                
                if((!this.value && this.initialObject) || this.initialObject != this.value) {

                    /*
                    if((this.item.returnObject)) {
                        this.$emit('input', this.initialObject)
                    }
                    else {
                    */
                        this.$emit('input', this.initialObject.id)
                    /*}*/
                }               
            }
        }
    },
    async created() {
        await this.loadData();
    },
    mounted() {
        //Handle if array contais objects
        if(Array.isArray(this.value) && this.value.some(obj => 'id' in obj)) {
            this.$emit('input', this.value.map(obj => obj.id));
        }
    }
}
</script>

<style>

</style>