<template>
    <div class="page-wrap">
        <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="primary"
      />
        <v-row no-gutters>
            <v-col cols="12" class="ma-0 pa-0">
              <div class="d-flex justify-center align-center text-center  md:h-screen h-full">
                    <div class="session-form-hold">
                        <v-card
                            elevation="2"
                            class="pa-4"
                        >
                            <div v-if="!isSent">
                                <v-avatar class="mb-8" size="200" tile>
                                    <img
                                        src="@/assets/images/illustrations/breaking_barriers.svg"
                                    />
                                </v-avatar>
                                <h1 class="h5 text--disabled font-weight-medium mb-0">Ändra lösenord</h1>
                                <p class="text--disabled font-weight-medium mb-10">Ange e-postadress för kontot</p>
                                <v-form v-model="isFormValid" @submit.prevent="forgot">
                                    <v-text-field
                                        prepend-icon="mdi-account"
                                        label="Email"
                                        autocapitalize="off"
                                        v-model="email"
                                        type="email"
                                        :rules="emailRules"
                                        required
                                        tabindex="1"
                                    />

                                    <div class="my-4">
                                    <v-divider></v-divider>
                                    </div>
                                    <v-btn
                                    color="primary"
                                    :disabled="!isFormValid"
                                    type="submit"
                                    >Skicka
                                    </v-btn>

                                </v-form>
                            </div>
                            <div v-else transition="scroll-y-transition">
                                <v-avatar
                                    color="green"
                                    size="72"
                                    class="mb-4"
                                >
                                    <v-icon dark x-large>
                                       mdi-check
                                    </v-icon>
                                </v-avatar>
                                <h1 class="h5 font-weight-medium mb-4">Meddelande skickat!</h1>
                                <p class="text--disabled font-weight-medium">Ett meddelande har skickats till ditt e-postkonto med en länk för att återställa ditt lösenord</p>
                                <router-link 
                                    to="/sign-in"
                                >Till inlogg</router-link>
                       
                            </div>
                        </v-card>
                    </div>
              </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import eventBus from '@/plugins/eventbus.js'
import authService from '../api/authService.js'

export default {
    name: 'Forgot',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Forgot'
    },
    data() {
        return {
            show: false,
            email: '',
            isFormValid: false,
            loading: false,
            isSent: false,
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
        }
    },
    computed: {

    },
    methods: {
      forgot() {
        this.loading = true;
        const data = {
            "username": this.email,
            "frontendUrl": window.location.origin + '/' + 'reset'
        }  
        authService.forgot(data)
        .then((response) => {
            this.isSent = true;
            console.log("🚀 ~ file: Forgot.vue ~ line 82 ~ .then ~ response", response)
        })
        .catch(() => {
             eventBus.$emit.snackMsg({
              "text": "Couldn't send reset link. Please try again",
              "type": "danger"
            });
        })
        .finally(() => {
            this.loading = false;
        })
      }
    },


}
</script>
<style lang="scss" scoped>
    .session-form-hold {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
    }
    .page-wrap {
        background-color: #242939 !important;
        display: flex;
        align-items: center;
        padding: 40px 1rem;
        height: 100%;
        min-height: 100vh;
    }

</style>
