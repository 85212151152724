<template>

   <simple-card>
       <v-card-title class="justify-center">
           {{ $helpers.capFirstLetter($t(`${title}`)) }}
           
       </v-card-title>
       <v-card-subtitle class="text-center subtitle-width" >
           {{ subTitle }}
       </v-card-subtitle>
        <v-card-text class="mt-4">

            <slot name="stepContentSlot"></slot>
        </v-card-text>
        
        <v-card-actions
            class="primary--text grey lighten-5 pa-4"
            v-if="action"
            style="cursor:pointer"
        >
   
            <slot name="stepActionSlot"></slot>
        </v-card-actions>
   </simple-card>


</template>
<script>
import SimpleCard from '@/components/card/SimpleCard'


export default {
    components: {
       SimpleCard
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        action: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
        }
    },
    methods: {

    }
}
</script>
<style lang="scss" scoped>
    .subtitle-width {
        max-width: 80%;
        margin: 0 auto;
    }
</style>