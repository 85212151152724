<template>
    <div>
    
        <base-card class="px-6 py-4 mb-4">
            <v-row>
                <v-col cols="12" md="8" align-self="center">
                    <div class="d-flex align-start justify-space-between">
                        <div class="mb-3">
                            <h5 class="mr-2 mb-0">
                                OCAB

                                <v-tooltip top>
                                    <template
                                        v-slot:activator="{on, attrs}"
                                    >
                                        <v-icon
                                            class="body-1"
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-check-decagram</v-icon
                                        >
                                    </template>
                                    <span>Verified</span>
                                </v-tooltip>
                            </h5>
                            <p class="text--disabled mb-0">Provpåse: {{id}}</p>
                        </div>
                    </div>
                    <div class="d-flex mb-2">

                        <p class="ma-0 mr-8">
                            <span class="font-weight-medium">Vasagatan 2 </span
                            >
                        </p>
                        <p class="ma-0 mr-8">
                            <span class="font-weight-medium">906 56 Umeå</span>
                        </p>
                    </div>
                    <p class="text--disabled">
                        Provet skickades in av Jane Doe, kl.13.20 den 3 dec 2021.
                    </p>
                </v-col>
                <v-col md="4">
                    <div class="text-right d-flex flex-column" style="height: 100%">
                        <div>
                            <v-chip class="red--green green lighten-5" v-if="bag.answer">
                                Svar lämnat
                            </v-chip>
                            <v-chip class="red--text red lighten-5" v-else>
                                Svar ej lämnat
                            </v-chip>
                        </div>
                        <div class="d-flex flex-1 align-end justify-end">
            
                        </div>
                    </div>
                </v-col>
            </v-row>
        </base-card>
            <e-info-card 
            
            title="Register answer"
            subTitle="Here you register the answer of the test"
            link
            >
                <template slot="basic-info-slot">
                <v-select
                    name="name"
                    :items="['Postivt', 'Falskt']"
                    label="Svar"
                    id="id"
                    outlined
                    dense
                ></v-select>
                
                <v-textarea
                    label="Kommentar"
                    name="comment"
                    auto-grow
                    outlined
                ></v-textarea>
                </template>
                <template slot="basic-action-slot">
                    <v-spacer></v-spacer>
                    <v-btn 
                        large color="primary"
                        class="mr-4"
                        @click="register"
                    >Register</v-btn>
                </template>
            </e-info-card>
    </div>
</template>

<script>
import EInfoCard from '@/components/card/BasicInfoCard'
//import {mapGetters, mapActions} from 'vuex'

export default {
    name: "SampleDemo",
    components: {
        EInfoCard
    },
    props: {
        id: {
            type: String
        }
    },
    methods: {
        //...mapActions('demo', ['registerAnswer']),
        register() {

            //this.registerAnswer(this.id);
        }
    },
    computed: {
        //...mapGetters('demo', ['envelope']),

        bag() {
            return []
            //return this.envelope.bags.find(o => o.id == this.id)
        }
    },

}
</script>

<style>

</style>