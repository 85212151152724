<template>


    <base-cta-list
        title="Service units"
        subtitle="Here you find the current service units"
        :headers="headers"
        storeNamespace="services"
        listNamespace="A_unit"
        :icon="unitsLogo"
        buttonText="New unit"
    >

         <template #dialog-content="{setDialog, dataId}"> 
             <service-unit-form
                :listOnSave="true"
                @requestIsDone="setDialog(false)"
                :id="dataId"
             ></service-unit-form>
         </template>

    </base-cta-list>



</template>

<script>
import ServiceUnitForm from './ServiceUnitForm.vue'


export default {
    name: "ServiceUnits",
    metaInfo: {
        title: 'Service Units'
    },
    components: {
        ServiceUnitForm,
    },
    data() {
      return {
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name'
            },
            {
                text: 'Type',
                align: 'start',
                sortable: true,
                value: 'type'
            }
        ],
        unitsLogo: require('@/assets/images/svg/weight-scale.svg')
      }
    },
}
</script>

<style>

</style>