import BaseStore from "@/modules/baseStore.js";
import BaseApi from "@/modules/baseApi.js";

const state = {
  A_site:[],  
  O_examination: {},
  A_journal:[],
  O_journal:{},


  stateRules: {
    site: {
      prepend: ['queue']
    },
  }

}

const api = BaseApi(state, "sample/lab")


export default BaseStore.extend({
  state,
  api
})