<template>
   <div
        class="mb-4 input-buttons"
        :class="styleClass"
    >
        <v-list-item-subtitle
            class="mb-4"
            v-if="item.label"
        >
            {{item.label}}
        </v-list-item-subtitle>

        <v-btn-toggle
            :value="value"                
            color="info"
            class="d-flex"
            :class="{ 
                'flex-column': (styleClass == 'list-input-buttons'),
             
            }"
        >
            <v-row no-gutters>
                <v-col
                    v-for="(btn, i) in item.items"
                    :key="'button_'+`${i}`"
                    :class="[
                        styleClass == 'compact-input-buttons' ? compactCols : '',
                        styleClass == 'list-input-buttons' ? 'col-12' : '',
                    ]"
                    
                >
                    <v-btn
                    
                        :value="btn.value"
                        outlined
                        class="d-flex pa-10 border"
                        :class="{ 
                            'justify-start mb-5': (styleClass == 'list-input-buttons'),
                        }"
                        @click="$emit('clicked', btn.value, item.field)"
                    >
                        
                        <v-icon 
                            large 
                            left
                            class="pa-2 mr-9"
                            v-if="btn.icon"
                        >
                            {{btn.icon}}
                        </v-icon>
                        <span 
                            class="d-flex flex-column align-start"
                        >
                            {{btn.value}}
                            <span 
                                class="caption"
                                v-if="btn.caption"
                            >
                                {{btn.caption}}
                            </span>
                        </span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-btn-toggle>
    </div>
</template>

<script>
export default {
    name: "InputButtons",
    props: {
        item: {
            type: Object,
            default: function() {
                return {}
            }
        },
        value: {
            type:String,
            default: ''
        },
    },
    computed: {
        styleClass() {
            switch (this.item.buttonStyle) {
                case 'square':
                    return 'square-input-buttons'
                case 'list':
                    return 'list-input-buttons'
                case 'compact':
                    return 'compact-input-buttons'
                default:
                    return ""
            }
        },
        compactCols() {
            const length = this.item.items.length;

            if(length > 2) {
                return "col-md-6 col-lg-4 col-12";
            } 
            else if(length == 2) {
                return "col-md-6 col-12";
            }
            else if(length < 2) {
                return "col-12";
            }

            return ""
        }
    },
}
</script>

<style lang="scss" scoped>
    .input-buttons::v-deep .v-btn {
        height: auto !important;
    }
    .border {
        border: 1px solid !important;
    }
    .input-buttons::v-deep .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn--active {
       border-color:var(--v-info-base) !important;
       .v-icon {
           color: var(--v-info-base) !important;
       }
    }
    .input-buttons::v-deep .v-btn__content {
        flex: 1 1 auto;
    }
    .input-buttons::v-deep span {
        white-space: normal;
        text-align: left;
    
        .caption {
            text-transform: none;
        }
    }
    .compact-input-buttons .v-btn, .list-input-buttons .v-btn {
        width: 100%;
        border-radius: 0;
    }
    .v-btn.v-btn--active:before {
        background: var(--v-info-base) !important;
    }
    .v-btn.v-btn--active span {
        color: var(--v-info-base) !important;
    }
    .compact-input-buttons .row {
        width: 0;
    }
 
</style>