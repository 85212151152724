<template>
    <v-row justify="center">
        <v-col cols="10" md="10" class="text-center">
            <h3 class="mb-4">Account info</h3>
            <p>
                Basic info, like your name and email, that you use on Labflow
                services 
            </p>
        </v-col>

        <v-col cols="10" md="10">
            <expansion-card
            title="Profile"
            sub-title="Some info may be visible to other people using Labflow services"
            :list="personalInfo"
   
            >
            </expansion-card>
        </v-col>

        <v-col cols="10" md="10">
            <expansion-card
            title="Account info"
            :list="accountInfo"
         
            >
            </expansion-card>
        </v-col>
    </v-row>
</template>

<script>

import ExpansionCard from '@/components/card/ExpansionCard'

export default {
    name: 'User',
    components: {
        ExpansionCard
    },
    metaInfo: {
        title: 'User Page'
    },
    props: {
        id: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            imgTwo: require('@/assets/images/svg/login.svg'),  
        }
    },
    methods: {
        

    },
    computed: {
      
        personalInfo() {
            
            const filter = {
                test:["name_first: [type:'input', 'rules:required']"],
                name_last: ["type:input", 'rules:required']
            }
            
            return this.$helpers.filterAndAssign([], filter)

        },
        accountInfo() {
    
            const filter = {
                email: ['type:input', 'rules:required|email'],
                password: [
                    'icon: mdi-lock', 
                    'content:', 
                    'label: password', 
                    'panelContent: Password is hidden for security reasons'
                ]
            }

        
            return this.$helpers.filterAndAssign([], filter)
        },

    },
    created() {
     
    }

}
</script>