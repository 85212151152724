<template>
    
    <base-stepper
        title="Set priceperiod"
        subtitle="Here you can set priceperiod"
        v-slot="{changeStep, currentStep}"
        :initialStep="1"
        :numOfSteps="pricelists.length"
        v-if="pricelists.length > 0" 
        @stepUpdated="onUpdateStep"
    >
        <v-stepper-items>
            <step-form-content 
                v-for="(item, index) in pricelists" :key="item.id"
                :title="item.name"
                subTitle="Details for the priceperiod"
                :schema="schema"
                storeNamespace="services"
                objectNamespace="O_priceperiod"
                :step="index+1"
                :currentStep="currentStep"
                :saveOnContinue="true"
                :clearStoreOnCreate="true"
                :lastStep="(index == (pricelists.length - 1)) ? true : false"
                @step-change="changeStep"
                @step-done="onDone"
            ></step-form-content>

        </v-stepper-items>

    </base-stepper>

</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import StepFormContent from '@/components/form/StepFormContent'
import {priceperiodSchema} from '@/modules/Service/schema/priceperiodSchema.js'

export default {
    name: "PriceperiodStepper",
    components: {
        StepFormContent
    },
    props: {
        id: {
            type: String
        }
    },
    computed: { 
        ...mapGetters('pricepackages', ['O_pricepackage']),
        pricelists() {
            return this.O_pricepackage.price_lists ? this.O_pricepackage.price_lists.filter(o => o.price_periods.length === 0) : false
        },
        schema() {
          return this.$helpers.schemaParser(priceperiodSchema)
        },
    },
    methods: {
        ...mapActions("services", ['get_O_pricelist']),
        ...mapActions("pricepackages", ['get_O_pricepackage']),
        async getPricepackage(id) {
            try {
                await this.get_O_pricepackage({id:id});
            } catch (error) {
                console.error(error);
            }
        },
        onUpdateStep(val) {
            this.get_O_pricelist({id:this.pricelists[val - 1].id});
        },
        onDone() {
            this.$emit('stepper-done');
        }
    },
    mounted() {

        if(this.id) {
            this.getPricepackage(this.id);
        }
        else if(this.O_pricepackage) {

            this.getPricepackage(this.O_pricepackage['id']);
        }
        
    }
}
</script>

<style>

</style>