var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('zebra-qr-scanner',{ref:"qrScanner",on:{"code":_vm.onQrCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isQrFocused = ref.isQrFocused;
return [_c('div',{staticClass:"d-flex items-center justify-center mt-6"},[(_vm.isOuterBagMode && isQrFocused)?_c('h2',[_vm._v("Väntar på ny emballagepåse...")]):(!_vm.isOuterBagMode && isQrFocused && !_vm.allInnerBagsRegistred)?_c('h2',[_vm._v("Väntar på ny provpåse...")]):_vm._e()])]}}])}),(_vm.outerBag)?_c('div',[_c('base-card',{staticClass:"px-6 py-4 mb-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('apexchart',{attrs:{"type":"radialBar","height":"250","options":_vm.radialChart.chartOptions,"series":_vm.percentageRegistred}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","align-self":"center"}},[_c('div',{staticClass:"d-flex align-start justify-space-between"},[_c('div',{staticClass:"mb-3"},[_c('h5',{staticClass:"mr-2 mb-0"},[_vm._v(" "+_vm._s(_vm.outerBag.client.name)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"body-1",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-decagram")])]}}],null,false,1226304696)},[_c('span',[_vm._v("Verified")])])],1),_c('p',{staticClass:"text--disabled mb-0"},[_vm._v("Emballage: "+_vm._s(_vm.outerBag.outerbag.id))])])]),_c('div',{staticClass:"d-flex mb-2"},[_c('p',{staticClass:"ma-0 mr-8"},[_c('span',{staticClass:"font-weight-medium mr-8"},[_vm._v(_vm._s(_vm.innerBagsList.length)+" provpåsar")])]),_c('p',{staticClass:"ma-0 mr-8"},[_c('span',{staticClass:"font-weight-medium mr-8"},[_vm._v("Fakturamärkning: "+_vm._s(_vm.outerBag.outerbag.name))])]),_c('p',{staticClass:"ma-0 mr-8"},[_c('span',{staticClass:"font-weight-medium mr-8"},[_vm._v("Objekt: "+_vm._s(_vm.outerBag.outerbag.reference))])]),_c('p',{staticClass:"ma-0 mr-8"},[_c('span',{staticClass:"font-weight-medium mr-8"},[_vm._v("Övrigt: "+_vm._s(_vm.outerBag.outerbag.description))])])]),_c('p',{staticClass:"text--disabled"},[_vm._v(" Provet skickades in av "+_vm._s(_vm.outerBag.outerbag.registered_by.name)+", "+_vm._s(_vm.moment(_vm.outerBag.outerbag.registered_at).format('YYYY-MM-DD HH:mm:ss'))+". ")])]),_c('v-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"text-right d-flex flex-column",staticStyle:{"height":"100%"}},[_c('div',[(_vm.allInnerBagsRegistred)?_c('v-chip',{staticClass:"green--text green lighten-5"},[_vm._v(" Allt registrerat! ")]):_c('v-chip',{staticClass:"red--text red lighten-5"},[_vm._v(" Ej klar ")])],1)])])],1)],1),_c('base-card',{attrs:{"padding":true}},[_c('v-card-title',[_vm._v("Provpåsar")]),_c('v-card-text',[(_vm.innerBagsList)?_c('v-data-table',{staticClass:"elevation-1 eg-table-bnone eg-table-td-py-md",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.innerBagsList},scopedSlots:_vm._u([{key:"item.registred",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":item.received_at ? 'green lighten-5' : 'red lighten-5',"size":"28"}},[_c('v-icon',{attrs:{"small":"","color":item.received_at ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.received_at ? 'mdi-check' : 'mdi-minus')+" ")])],1)]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.name)+" ")]}}],null,false,250881296)}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},on:{"click:outside":_vm.resetInner},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('edit-form',{attrs:{"title":"Välj kö","subTitle":"Välj vilken kö provpåsen ska läggas i","schema":_vm.schema,"storeNamespace":"arrivalsNormal","objectNamespace":"O_inner","stateRulesIds":_vm.endpointProps,"clearStoreOnCreate":false,"resetFormEnabled":false,"resetOnDone":true},on:{"requestIsDone":_vm.onInnerBagSaved}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }