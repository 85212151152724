<template>
    
    <edit-form
        :title="title"
        :subTitle="subTitle"
        :schema="schema"
        storeNamespace="clients"
        objectNamespace="O_contact"
        :objectId="id"
        :listOnSave="listOnSave"
    >

    </edit-form>

</template>

<script>
import EditForm from '@/components/form/EditForm'
import {clientContactSchema} from '@/modules/Clients/schema/clientContactSchema'
export default {
    name: "ClientContactForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
    },
    data() {
      return {

      }
    },
    computed: {
        schema() {
            
            return this.$helpers.schemaParser(clientContactSchema)

        },
        title() {
            return this.id ? "Update contact" : "New contact";
        },
        subTitle() {
            return this.id ? "Here you can edit a contact" : "Here you type the information of the new contact";
        },
        
    },
}
</script>

<style>

</style>