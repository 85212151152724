<template>

    <div>

        <base-action-list
            :title="title"
            :subtitle="subtitle"
            :buttonText="buttonText"
            :icon="icon"
            :headers="headers"
            :storeNamespace="storeNamespace"
            :listNamespace="listNamespace"
            :objectNamespace="objectNamespace"
            :changePathOnEdit="changePathOnEdit"
            :buttonLink="buttonLink"
            :editPath="editPath"
            :editEnabled="editEnabled"
            :newEnabled="newEnabled"
            :inlineMode="inlineMode"
            :refreshData="refreshTimestamp"
            :exportData="exportData"
            @onEdit="edit"
            @onNew="setDialog(true)"
        >
            <template v-slot:header v-if="newEnabled">
                <v-btn
                    class="izy-btn" 
                    color="primary" 
                    large
                    :to="{path: buttonLink}"
                    v-if="buttonLink"
                >
                    {{buttonText}}
                </v-btn>
                
                <v-btn 
                    class="izy-btn" 
                    color="primary" 
                    large
                    @click="setDialog(true)" 
                    v-else
                >
                    {{buttonText}}
                </v-btn>
            </template>

            <template v-for="(_, scopedSlotName) in scopedSlots" v-slot:[scopedSlotName]="slotData">
                <slot :name="scopedSlotName" v-bind="slotData" />
            </template>

        </base-action-list>


        <v-dialog
            content-class="cta-dialog"
            v-model="dialog"
            :max-width="modalSize"
            :scrollable="modalScrollable"
        >

            <v-btn
                class="close-btn"
                fab
                small
                depressed
                text
                @click="setDialog(false)"
                v-if="!hideCloseBtn"
            >
                <v-icon dark>
                    mdi-close
                </v-icon>
            </v-btn>

            <slot 
                name="dialog-content"
                v-bind:setDialog="setDialog"
                v-bind:dataId="dataId"
                v-bind:dataItem="dataItem"
                v-bind:closeDialogAndRefresh="closeDialogAndRefresh"
                v-if="dialog"
            >
            </slot>
           
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: "CtaList",
    components: {

    },
    props: {
        title: {
            type: String,
            default: 'Table'
        },
        subtitle: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        buttonText: {
            type: String,
            default: 'New'
        },
        headers: {
            type: Array,
            default: () => ([])
        },
        storeNamespace: {
            type:String,
            default: ''
        },
        listNamespace: {
            type:String,
            default: ''
        },
        objectNamespace: {
            type:String,
            default: ''
        },
        changePathOnEdit: {
            type:Boolean,
            default: false
        },
        buttonLink: {
            type:String,
            default: ''
        },
        editPath: {
            type:String,
            default: 'edit'
        },
        hideTitle: {
            type:Boolean,
            default: false
        },
        hideCloseBtn: {
            type:Boolean,
            default: false
        },
        editEnabled: {
            type:Boolean,
            default: true
        },
        newEnabled: {
            type: Boolean,
            default: true
        },
        inlineMode: {
            type: Boolean,
            default: false
        },
        modalScrollable: {
            type: Boolean,
            default: false
        },
        modalSize: {
            type: String,
            default: "600px"
        },
        exportData: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialog: false,
            dataId: "",
            dataItem: "",
            refreshTimestamp: 0,
        }
    },
    methods: {
        setDialog(value) {
            this.dialog = value;
        },
        async edit(item) {
            this.dataId = item.id;
            this.dataItem = item;
            this.setDialog(true)
        },
        closeDialogAndRefresh() {
            this.refreshTimestamp = Date.now();
            this.setDialog(false)
        }
    },
    computed: {
        scopedSlots() {

            return Object.fromEntries(
                Object.entries(this.$scopedSlots).filter(([key]) => key != 'dialog-content')
            )
        }
    },
    watch: {
        dialog: function(val) {
     
            if(val === false) {
                this.dataId = "";
                this.dataItem = "";
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .izy-btn {
        text-transform: none;
        letter-spacing: 0;
    }
    ::v-deep .cta-dialog {
        position: relative;
    }
    .close-btn {
        position: absolute;
        right: 24px;
        top: 25px;
        z-index: 999;
        /*
        display: block;
        width: auto;
        padding: 6px;
        border-radius: 50%;
        &:hover {
            background: #ececec;
        }
        */
    }
</style>