<template>

    <base-cta-list
        title="Kontaktuppgifter kund"
        subtitle="Här finner du kundens olika kontaktuppgifter."
        :headers="headers"
        storeNamespace="clients"
        listNamespace="A_contact"
        buttonText="Ny kontaktuppgift"
        :inlineMode="true"
        modalSize="95%"
        :modalScrollable="true"
    >
        <template v-slot:row.name_organisation="{ entity }">
            {{entity.name_organisation.length > 0 ? entity.name_organisation : entity.name_first + ' ' + entity.name_last}}
        </template>
        <template v-slot:row.binding="{ entity }">

            <span class="contact-type" v-for="binding in entity.binding" :key="binding.id">
                <span>
                    {{$helpers.capFirstLetter($t('fields.'+`${binding.type}`))}},
                    {{$helpers.capFirstLetter($t('fields.'+`${binding.order}`))}},
                    {{$helpers.capFirstLetter($t('fields.'+`${binding.destination}`))}}
                </span>
            </span>
        </template>
        <template v-slot:row.name_first="{ entity }">
            {{entity.name_first + ' ' + entity.name_last}}
        </template>

        <template #dialog-content="{setDialog, dataId}"> 
            <client-contact-form
                :listOnSave="true"
                @requestIsDone="setDialog(false)"
                :id="dataId"
            ></client-contact-form>
        </template>

    </base-cta-list>



</template>

<script>
import ClientContactForm from './ClientContactForm.vue'
export default {
    name: "ClientContacts",
    metaInfo: {
        title: 'Client Contacts'
    },
    components: {
        ClientContactForm,
    },
    data() {
      return {
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name_organisation',
                slot: true
            },
            {
                text: 'Typ',
                align: 'start',
                value: 'binding',
                slot: true
            },
            {
                text: 'Person',
                align: 'start',
                value: 'name_first',
                slot: true
            }
        ]
      }
    }
}
</script>

<style type="scss" scoped>
    .contact-type {
        display: block;;
    }
</style>