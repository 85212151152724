import Vue from 'vue';
import VueHtmlToPaper from 'vue-html-to-paper';

/* This will change according to your server */
let basePath= window.location.origin;

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=no',
    'scrollbars=no'
  ],
  styles: [
    `${basePath}/printqr.css`
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}
Vue.use(VueHtmlToPaper, options);