<template>
    <v-row justify="center">
        <v-col cols="10">
            <group-form
                :id="id"
            ></group-form>
        </v-col>
    </v-row>
</template>

<script>
import GroupForm from '@/modules/AAA/components/GroupForm'

export default {
    name: "GroupEdit",
    components: {
        GroupForm
    },
    metaInfo: {
        title: 'Group'
    },
    props: {
        id: {
            type: String
        }
    }
}
</script>

<style>

</style>