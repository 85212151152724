import api from "@/services/api";


class sampleService {
    sendCC(clientId, bagId) {
        return api.post("admin/customer/client/"+ clientId +"/sample/result/" + bagId + "/sendjob", {
            name: "Kopia resultat",
            send_at:"",
            description:""
        })
    }
}

export default new sampleService





