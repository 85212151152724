<template>


    <base-cta-list
        title="Service groups"
        subtitle="Here you find the current service groups"
        :headers="headers"
        storeNamespace="services"
        listNamespace="A_group"
        :icon="logo"
        buttonText="New group"
    >

         <template #dialog-content="{setDialog, dataId}"> 
             <service-group-form
                :listOnSave="true"
                @requestIsDone="setDialog(false)"
                :id="dataId"
             ></service-group-form>
         </template>

    </base-cta-list>



</template>

<script>
import ServiceGroupForm from '../components/Groups/ServiceGroupForm.vue'


export default {
    name: "ServiceGroups",
    metaInfo: {
        title: 'Service Groups'
    },
    components: {
        ServiceGroupForm,
    },
    data() {
      return {
        headers: [
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name'
            }
        ],
        logo: require('@/assets/images/svg/blocks-toy.svg')
      }
    },
}
</script>

<style>

</style>