<template>

    <expansion-card
        :title="title"
        :list="filteredSchema"
        :boxShadow="false"
        @inputBlur="blurEvent"
    />
    
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import ExpansionCard from '@/components/card/ExpansionCard'

export default {
    name: "ExpansionUpdateForm",
    props: {
        id: {
            type: String
        },
        title: {
            type: String,
            default: ""
        },
        schema: {
            type: Object,
            default: function() {
                return {}
            }
        },
        storeNamespace: {
            type:String,
            default: ''
        },
        objectNamespace: {
            type:String,
            default: ''
        },
        initialLoad: {
            type: Boolean,
            default: true
        }
    },
    components: {
        ExpansionCard,
    },
    beforeCreate() {
        let storeNamespace = this.$options.propsData.storeNamespace;
        let objectNamespace = this.$options.propsData.objectNamespace;
        let objectNamespaceUpperCase = objectNamespace.toUpperCase();

        const splitObjectNamespace = objectNamespace.split('_');
        
        let stateRules = (this.$store.getters[storeNamespace + '/stateRules'] && splitObjectNamespace[1] in this.$store.getters[storeNamespace + '/stateRules']) ? this.$store.getters[storeNamespace + '/stateRules'][splitObjectNamespace[1]] : false;

        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters(storeNamespace, [objectNamespace]),
            stateRules: () => stateRules,
        }
        this.$options.methods = {
            ...this.$options.methods,
            ...mapMutations(storeNamespace, ['CLEAR_'+objectNamespaceUpperCase]),
            ...mapActions(storeNamespace, ['create_'+ objectNamespace, 'get_'+ objectNamespace, 'update_'+ objectNamespace]),
        }
    },
    computed: {
        filteredSchema() {
            return this.$helpers.schemaParser(this.schema, this[this.objectNamespace], true)

        }
    },
    methods: {
        async getItem() {
            try {
                await this['get_'+this.objectNamespace]({id:this.id});
            } catch (error) {
                console.error(error);
            }
        },

        async blurEvent(data) {
             
            const obj = {...this[this.objectNamespace], ...{[data.fieldName]: data.value}}
            const formData = this.$helpers.cleanForm(this.filteredSchema, obj, 'update');
            await this['update_'+this.objectNamespace]({id: this.id, input: formData});
        },
    },
    created () {
        if(this.id && this.initialLoad) {
            this.getItem();
        }
    },
}
</script>

