<template>
    <base-cta-list
        title="Kundanvändare"
        subtitle="Här hittar du kundens användare"
        :headers="headers"
        storeNamespace="clients"
        listNamespace="A_user"
        objectNamespace="O_user"
        :inlineMode="true"
        buttonText="Ny användare"
    >
        <template v-slot:row.permissions="{ entity }">
    
            <div v-for="(permissions, index) in entity.permissions" :key="`permissions-${index}`">
                {{ permissions.client_name }} -
                <template v-for="(role, idx) in permissions.roles">
                    <span :key="`role-${idx}`">{{idx > 0 ? ', ' : ''}}{{role.name}}</span>
                </template>
            </div>

        </template>


         <template #dialog-content="{setDialog, dataId}">

                <client-user-form-stepper
                    v-if="!dataId"
                    @stepper-done="setDialog(false)"
                ></client-user-form-stepper>
                <client-user-edit
                    v-else 
                    :id="dataId"
                    @requestIsDone="setDialog(false)"
                    :listOnSave="true"
                ></client-user-edit>

         </template>

    </base-cta-list>
</template>

<script>
import ClientUserFormStepper from './ClientUserFormStepper.vue'
import ClientUserEdit from './ClientUserEdit.vue'
//import { mapMutations, mapActions } from 'vuex'
export default {
    components: {
        ClientUserFormStepper,
        ClientUserEdit
    },
    data() {
        return {
            headers: [
                {
                    text: 'Name',
                    align: 'start',
                    sortable: false,
                    value: 'name'
                },
                {
                    text: 'Permissions', 
                    value: 'permissions',
                    slot: true,
                },
                {
                    text: 'Actions', 
                    value: 'actions',
                    delete: true,
                    align: 'end',
                    slot: true
                }
            ],
        }
    },
    methods: {
        /*
      ...mapMutations("clients", ['REMOVE_A_USER']),
      ...mapActions("clients", ['delete_O_user']),
      deleteItem(entity, id) {
        this.delete_O_user({id:id});
        //this.REMOVE_A_USER(index);
      }
      */
    },
}
</script>

<style>

</style>