import { v4 as uuidv4 } from 'uuid';

const uuid = {
    data() {
        return {
            componentId: uuidv4()
        }
    },
}

export default uuid;