<template>
    
        <edit-form
            :title="title"
            :subTitle="subTitle"
            :schema="schema"
            storeNamespace="samples"
            objectNamespace="O_batch"
            :objectId="id"
            :listOnSave="listOnSave"
        >
        </edit-form>
    
</template>

<script>
import EditForm from '@/components/form/EditForm'
import {mapMutations} from 'vuex'

export default {
    name: "InnerEnvelopeBatchForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
    },
    computed: {
       schema() {
            const filter = {
                numbers: '{ type:"select", rules:"required|numeric|min_value^:1", items:["24", "48", "72", "96", "120", "144"] }',
                client_id: '{type:"hiddenInput",  rules:"required", exclude:["update"], storeNamespace: "profile", objectNamespace: "organization"}'
            }
            
            return this.$helpers.schemaParser(filter)
        },
        title() {
            return this.id ? "Updatera batch" : "Ny batch med QR-koder för innerpåsar";
        },
        subTitle() {
            return this.id ? "Här kan du redigera en batch" : "Här kan du skapa en ny batch med innerpåsar";
        },
    },
    methods: {
        ...mapMutations("samples", ['SET_O_BATCH'])
    },
    mounted() {
        if(!this.id) {
            this.SET_O_BATCH({numbers:1})
        }
        
    }
}
</script>

<style>

</style>