<template>
  <div class="d-flex flex-column" style="height: 100%; overflow: hidden">
    <v-toolbar
      flat
      dark
      color="primary"
    >
      
      <v-btn
        icon
        dark
        @click="closeScanner()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Qr Scanner</v-toolbar-title>
      <v-spacer></v-spacer>
      
      <v-toolbar-title v-if="numOfMaxScan">
        {{numOfScanned}} / {{numOfMaxScan}}
      </v-toolbar-title>
      <v-toolbar-title v-else>Emballagepåse</v-toolbar-title>
    </v-toolbar>

    <qrcode-stream 
      :camera="camera"
      @decode="onDecode" 
      @init="onInit"
      :track="paintOutline"
    >
        <div 
          :class="[dialogContent.state == 'error' || (loading && !isCameraMounted) ? 'grey darken-4' : '', 'd-flex justify-center flex-column align-center fill-height']" 
          v-if="loading || dialog"
        >
          <div v-if="loading" class="d-flex align-center flex-column">
            <v-progress-circular
                :size="70"
                color="primary"
                indeterminate
            ></v-progress-circular>
            <p v-if="!isCameraMounted" class="grey--text text--lighten-2 mt-6">Trying to start scanner...</p>
            <p v-else class="grey--text text--lighten-2 mt-6">Staring scanner again...</p>
          </div>
        </div>

        <v-dialog
          v-model="validationPending"
          persistent
          width="300"
        >
          <v-card
            color="primary"
            dark
          >
            <v-card-text>
              Validating code
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

    </qrcode-stream>


    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="500"
      >
        <v-card>
          <v-card-title class="text-h5">
            {{dialogContent.title}}
          </v-card-title>
          <v-avatar
            class="ma-4"
            size="72"
            :color="dialogContent.color"
            v-if="dialogContent.color"
          >
          </v-avatar>
          <v-card-text v-html="dialogContent.message">

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            
            <v-btn
              color="red darken-1"
              text
              v-if="dialogContent.actions.includes('warning')"
              @click="closeDialog()"
            >
              {{dialogContent.btn.warning}}
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              v-if="dialogContent.actions.includes('close')"
              @click="closeScanner()"
            >
              {{dialogContent.btn.close}}
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              v-if="dialogContent.actions.includes('continue')"
              @click="continueScan()"
            >
              {{dialogContent.btn.continue}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
 

  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {

  components: { QrcodeStream },
  props: {
    validationResult: {
      type: Object,
      required: true
    },
    numOfScanned: {
      type: [Number, Boolean],
    },
    numOfMaxScan: {
      type: [Number, Boolean]
    },
    allScanned: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      result: '',
      dialogContent: {},
      loading: false,
      dialog: false,
      validationPending: false,
      camera: 'auto',
      isCameraMounted: false,
    }
  },

  methods: {
    setDefaultDialogContent() {
      this.dialogContent = {
        title: '',
        message: '',
        state: '',
        actions: ['close'],
        btn: {
          continue: 'Fortsätt',
          close: 'Stäng',
          warning: 'Ok'
        }
      }
    },
    paintOutline (detectedCodes, ctx) {
        for (const detectedCode of detectedCodes) {
          const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

          ctx.strokeStyle = "red";

          ctx.beginPath();
          ctx.moveTo(firstPoint.x, firstPoint.y);
          for (const { x, y } of otherPoints) {
            ctx.lineTo(x, y);
          }
          ctx.lineTo(firstPoint.x, firstPoint.y);
          ctx.closePath();
          ctx.stroke();
        }
    },
    onDecode (result) {

      try {
        const resJson = JSON.parse(result)
        //if(this.result != result) {
        this.turnCameraOff()
        this.result = resJson
        this.validationPending = true;
        
        this.$emit('onResult', resJson)
      //}
      }
      catch(e) {
          this.dialogContent = {
            title: "Warning",
            message: "The supplied QR-code is not valid for this system",
            state: 'warning',
            actions: ['warning'],
            btn: {...this.dialogContent.btn}
          }

          this.dialog = true;
        
      }
      
    },
    continueScan() {
      if(this.camera != 'auto') {
        this.turnCameraOn();
      }
      this.closeDialog();
    },
    closeDialog() {
      this.dialog = false;
      this.setDefaultDialogContent();
    },
    closeScanner() {
      if(this.dialog) {
        this.closeDialog();
      }
      this.$emit('closeScanner', true)
    },
    async onInit (promise) {
    
        this.loading = true;
        try {
            await promise
            if(!this.isCameraMounted) {
              this.isCameraMounted = true;
            }
        } catch (error) {
            this.dialogContent.title = 'Error';
            this.dialogContent.state = 'error';

            if (error.name === 'NotAllowedError') {
              this.dialogContent.message = "You need to grant camera access permission"
            } else if (error.name === 'NotFoundError') {
              this.dialogContent.message = "No camera on this device"
            } else if (error.name === 'NotSupportedError') {
              this.dialogContent.message = "Secure context required (HTTPS, localhost)"
            } else if (error.name === 'NotReadableError') {
              this.dialogContent.message = "Is the camera already in use?"
            } else if (error.name === 'OverconstrainedError') {
              this.dialogContent.message = "Installed cameras are not suitable"
            } else if (error.name === 'StreamApiNotSupportedError') {
              this.dialogContent.message = "Stream API is not supported in this browser"
            } else if (error.name === 'InsecureContextError') {
              this.dialogContent.message = 'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
            } else {
              this.dialogContent.message = `Camera error (${error.name})`;
            }
            this.dialog = true;
        }
        finally {
            this.loading = false;
        }
    },
    turnCameraOn () {
      this.camera = 'auto'
    },
    turnCameraOff () {
      this.camera = 'off'
    },
    onValidationResult(res) {
      //Handle success
      if(res.status && this.validationPending) {

          this.dialogContent = {
            title: res.title ? res.title : "Sucess",
            message: res.message ? res.message : "Validation is successfull, registration completed",
            state: res.state ? res.state : 'sucess',
            actions: res.actions ? res.actions : ['continue', 'close'],
            color: res.color ? res.color : false,
            btn: res.btn ? {...this.dialogContent.btn, ...res.btn} : {...this.dialogContent.btn}
          }

          this.validationPending = false
          this.dialog = true;
      }
    }
  },
  watch: {
    validationResult: {
      handler(val) {
        this.onValidationResult(val)
      }
    },
    allScanned: {
      handler(val) {
        if(val === true) {
          this.closeScanner();
        }
      }
    } 
  },
  created () {
    this.setDefaultDialogContent();
    if(this.allScanned) {
        this.camera = 'off'
        this.dialogContent = {
          title: "Hmm, why?",
          message: "All bags for this enevelope is allready scanned",
          state: 'success',
          actions: ['close']
        }

        this.dialog = true;
    }
    
  },
}
</script>