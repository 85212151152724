<template>
    <div>
        <v-row justify="center">
            <v-col cols="10">
                <user-form
                    :id="id"
                ></user-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import UserForm from '@/components/form/forms/UserForm.vue'

export default {
    name: "UserEdit",
    components: {
        UserForm
    },
    metaInfo: {
        title: 'User'
    },
    props: {
        id: {
            type: String
        }
    },
}
</script>

<style>

</style>