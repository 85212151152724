<template>
    <div>

        <base-stepper
            v-slot="{changeStep, currentStep, previousStep, stepperId}"
            :numOfSteps="4"
            :intialStep="1"
            :hideNavigator="true"
            :hideTitle="true"
        >
            
            <v-stepper-items>

                <step-form-content
                    title="Användarkonto"
                    subTitle="Välj om du vill skapa ett nytt eller ladda ett befintligt konto"
                    :schema="schemaStep1"
                    storeNamespace="clientsUser"
                    objectNamespace="clientUserForm"
                    :clearStoreOnCreate="true"
                    :step="1"
                    :parentId="stepperId"
                    @step-change="changeStep"
                ></step-form-content>
                

                <v-stepper-content class="pa-0" 
                    :step="2"
                >
                    <v-lazy>
                        <client-user-form
                            v-if="clientUserForm.type === 'new'"
                            :inlineMode="true"
                            @stepper-done="onDone"
                        ></client-user-form>
                        <jump-step 
                            v-else
                            :step="2"
                            :previousStep="previousStep"
                            :currentStep="currentStep" 
                            @step-change="changeStep"
                        ></jump-step>
                    </v-lazy>
                </v-stepper-content>


                <step-form-content
                    title="Välj användare"
                    subTitle=""
                    :schema="schemaStep3"
                    storeNamespace="clientsUser"
                    objectNamespace="O_user"
                    :step="3"
                    :currentStep="currentStep"
                    :clearStoreOnCreate="true"
                    @step-change="changeStep"
                ></step-form-content>

                <step-form-content
                    title="Tilldela behörigheter"
                    subTitle="Välj vilka behörigheter du vill tilldela användaren"
                    :schema="schemaStep4"
                    storeNamespace="clients"
                    objectNamespace="O_permission"
                    :clearStoreOnCreate="true"
                    :step="4"
                    :saveOnContinue="true"
                    :listOnSave="true"
                    listObjectNamespace="A_user"
                    :lastStep="true"
                    :currentStep="currentStep"
                    :parentId="stepperId"
                    @step-change="changeStep"
                    @step-done="onDone"
                ></step-form-content>

            </v-stepper-items>
        </base-stepper>

    </div>
</template>

<script>
import StepFormContent from '@/components/form/StepFormContent'
import ClientUserForm from './ClientUserForm.vue';
import {mapGetters} from 'vuex'
import JumpStep from '@/components/util/JumpStep'
import {clientAddUserPermissionSchema} from '@/modules/Clients/schema/clientAddUserPermissionSchema.js'
export default {
    name: "ClientUserFormStepper",
    components: {
      StepFormContent,
      ClientUserForm,
      JumpStep,
    },
    methods: {
      onDone() {
        this.$emit('stepper-done');
      }
    },
    computed: {
        ...mapGetters('clientsUser', ['clientUserForm']),
        schemaStep1()  {
            const filter = {
                type: '{ type:"buttons", rules:"required", exclude:["save", "update"], buttonStyle:"compact", items:[{value: "new", icon:"mdi-account-plus", caption:"Skapa nytt konto"}, {value: "load", icon:"mdi-upload", caption:"Ladda befintlig konto"}] }',
            }
            return this.$helpers.schemaParser(filter)
        },
        schemaStep3() {
            const filter = {
                obj: '{type:"selectData", rules:"required", storeNamespace: "clientsUser", objectNamespace:"A_user", itemText: "name", itemSecondText: "username", returnObject:true}',
            }
            return this.$helpers.schemaParser(filter)
        },
        schemaStep4() {
            return this.$helpers.schemaParser(clientAddUserPermissionSchema)
        }
    }
}
</script>

<style>

</style>