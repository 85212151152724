//import { userModule } from './User'
import { authModule } from './Auth'
import { dashboardModule } from './Dashboard'
import { profileModule } from './Profile'
import { aaaModule } from './AAA'

import { clientsModule } from './Clients'

import { rolesModule } from './Roles'


import { samplingModule } from './Sampling'

import { sampleModule } from './Sample'
import { serviceModule } from './Service'
import { settingsModule } from './Settings'

import { labModule } from './Lab'


import { testModule } from './Test'

export const modules = {
	authModule,
	dashboardModule,
	aaaModule,
	clientsModule,
	profileModule,
	testModule,
	samplingModule,
	rolesModule,
	sampleModule,
	serviceModule,
	settingsModule,
	labModule
}