import Vuex from 'vuex'
import Vue from 'vue'
import largeSidebar from './modules/largeSidebar'
import compactSidebar from './modules/compactSidebar'
import themeConfig from './modules/themeConfig'
import verticalSidebar from './modules/verticalSidebar'

Vue.use(Vuex)


export default new Vuex.Store({
	modules: {
		largeSidebar,
		compactSidebar,
		themeConfig,
		verticalSidebar,
	}
})