const state = {

    arrivals: [],
    envelopes: [],
    envelope: {},

    queue: {
        bas: [],
        express: [],
        direct: []
    }
      
}

const getters = {
  arrivals: state => state.arrivals,
  envelopes: state => state.envelopes,
  envelope: state => state.envelope,
  queue: state => state.queue,

  bas: state => state.queue.bas,
  express: state => state.queue.express,
  direct: state => state.queue.direct,

samples: state => state.queue.bas.concat(state.queue.express, state.queue.direct)


}

const actions = {
  pushArrival({commit }, data) {
    commit("PUSH_ARRIVAL", data);
  },
  pushEnvelope({commit }, data) {
    commit("PUSH_ENVELOPE", data);
  },
  setEnvelope({commit }, data) {
    commit("SET_ENVELOPE", data);
  },
  clearEnvelope({commit }) {
    commit("SET_ENVELOPE", {});
  },
  pushQueue({commit}, data) {
    commit("PUSH_QUEUE", data);
  },
  registerBag({commit},data) {
    commit("REGISTER_BAG", data);
  },
  registerAnswer({commit},data) {
    commit("REGISTER_ANSWER", data);
  }
  
}

const mutations = {
    PUSH_ARRIVAL(state, data) {
        state["arrivals"].push(data);
    },
    PUSH_ENVELOPE(state, data) {
        state["envelopes"].push(data);
    },
    SET_ENVELOPE(state, data) {
        state["envelope"] = data
    },
    PUSH_QUEUE(state, data) {
        data.content.name = data.content.id,
        data.content.time = new Date().toLocaleString() 
        state["queue"][data.key].push(data.content);
    },
    REGISTER_BAG(state, data) {
        console.log(data)
        const registred = state.envelope.bags.find(o => o.id == data)
        registred.registred = true;
    },
    REGISTER_ANSWER(state, data) {
        console.log(data)
        const registred = state.envelope.bags.find(o => o.id == data)
        registred.answer = true;
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}