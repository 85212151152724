<template>
    <v-row>
        <v-col cols="12">
            <simple-card>
                <v-skeleton-loader
                    height="100%"
                    width="100%"
                    type="card"
                    :loading="false"
                >
                <v-card-title>
                    {{title}}
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="list"
                    :options.sync="options"
                    :footer-props="{
                        itemsPerPageOptions:[10,20,50,100]
                    }"
                    item-key="id"
                    show-select
                    class="table-one"
                    multi-sort
                    :loading="loading"
                >


                    <template 
                        v-for="slotValue in slotValues" 
                        v-slot:[`item.${slotValue.value}`]="{ item }">
                        <div :key="slotValue.value">
                            <slot :name="[`row.${slotValue.value}`]" :entity="item" :index="list.indexOf(item)"></slot>
                        </div>
                    </template>

       

                    <template v-slot:item.action="{item}">
                        <div class="d-flex">
                            <v-tooltip top>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn
                                        color="success"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="editItem(item)"
                                        icon
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
                </v-skeleton-loader>
            </simple-card>
        </v-col>
    </v-row>
</template>
<script>

import {mapGetters, mapActions} from 'vuex'
import SimpleCard from '@/components/card/SimpleCard'

export default {
    name:"ActionTable",
    props: {
        title: {
            type: String,
            default: 'Table'
        },
        headers: {
            type: Array,
            default: () => ([])
        },
        storeNamespace: {
            type:String,
            default: ''
        },
        listNamespace: {
            type:String,
            default: ''
        },
        changePathOnEdit: {
            type:Boolean,
            default: true
        }


    },
    components: {
        SimpleCard,
    },
    beforeCreate() {
        let storeNamespace = this.$options.propsData.storeNamespace;
        let listNamespace = this.$options.propsData.listNamespace;
  
        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters(storeNamespace, [listNamespace]),
        }
        this.$options.methods = {
            ...this.$options.methods,
            ...mapActions(storeNamespace, ['list_'+ listNamespace])
        }
    },
    data() {
        return {
            search: '',
            selected: [],
            options: {},
            loading: false
        }
    },
    methods: {

        editItem(item) {
            if(this.changePathOnEdit) {
                const path = this.$route.path.substring(0, this.$route.path.lastIndexOf('/')) 
                this.$router.push({ path: path + `/envelope/${item.id}` }) 
            }
            else {
                this.$emit('onEdit', item);
            }
        },
    },
    computed: {
        ...mapGetters(['getThemeMode']),
        slotValues() {
            return this.headers.filter(o => (o.slot === true)); 
        },
        list() {
            return this[this.listNamespace];
        },
    },
    created() {
        
    }
}
</script>
<style lang="scss">
    
</style>