<template>
    <CustomerDashboard v-if="dashboardView && dashboardView.name == 'customer'"></CustomerDashboard>
    <AdminDashboard v-else-if="dashboardView && dashboardView.name == 'admin'"></AdminDashboard>
    <UserDashboard v-else-if="dashboardView && dashboardView.name == 'user'"></UserDashboard>
</template>
<script>


import {mapGetters} from 'vuex'
import CustomerDashboard from '../components/CustomerDashboard.vue';
import AdminDashboard from '../components/AdminDashboard.vue';
import UserDashboard from '../components/UserDashboard.vue';
export default {
    name: 'Dashboard',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Dashboard'
    },
    components: {
        CustomerDashboard,
        AdminDashboard,
        UserDashboard
    },
    computed: {
       ...mapGetters('profile', ['dashboardView']),
    },
    methods: {
     
    },
    created () {

    }
}
</script>