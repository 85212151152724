
import InnerEnvelopeBatch from './pages/InnerEnvelopeBatch.vue'
import ManualArrival from './pages/ManualArrival.vue'
import samplesStore from './store/samples'
import arrivalsManual from './store/arrivalsManual'
import arrivalsNormal from './store/arrivalsNormal'

import ManualScan from './components/ManualScan.vue'

import CustomerSampleResult from './components/CustomerSampleResult.vue'

import NormalArrival from './pages/NormalArrival.vue'

// eslint-disable-next-line no-unused-vars
export function sampleModule({ app, router, store, eventBus }) {

	router.addRoute('Home', { 
		path: 'sample', 
		name:'sample', 
		component: () => import("@/components/ParentNode"), 
		redirect: to => {return to.path + '/list'}
	})



	router.addRoute('sample', { path: 'envelope/inner/batch', component: InnerEnvelopeBatch })

	router.addRoute('sample', { path: 'arrival/manual', component: ManualArrival })

	router.addRoute('sample', { path: 'arrival/manual/scan', component: ManualScan })

	router.addRoute('sample', { path: 'arrival/normal', component: NormalArrival })

	router.addRoute('sample', { path: 'result', component: CustomerSampleResult })

	/**
	 * Register store
	 */
	store.registerModule('samples', samplesStore)
	store.registerModule('arrivalsManual', arrivalsManual)
	store.registerModule('arrivalsNormal', arrivalsNormal)

}