import api from "./api";
import storageService from "./storageService";
//import eventBus from "@/plugins/eventbus";
import errorResponseHandler from './errorResponseHandler.js';
import sucessResponseHandler from './successResponseHandler.js';

// eslint-disable-next-line no-unused-vars
const setup = (store) => {
    
    api.interceptors.request.use(
      (config) => {
        const token = storageService.getToken();
        const client = storageService.getOrganization()
        if (token) {
          config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
          // for Spring Boot back-end
          //config.headers["x-access-token"] = token; // for Node.js Express back-end
        }
        if(client) {
          config.headers["X-Client"] = client; 
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    
    api.interceptors.response.use(
      response => sucessResponseHandler(response), 
      error => errorResponseHandler(error)
    );
    

};  

export default setup;