<template>

    <v-btn color="primary" outlined small @click="generate">
        Export CSV <i class="mdi mdi-export-variant" aria-hidden="true"></i>
    </v-btn>


</template>

<script>
    import {mapKeys, pickBy, pick} from "lodash";


    import {saveAs} from "file-saver";
    import {unparse} from "papaparse";
    export const isType = (value, type) => typeof value === type;
    export default {
        name: "JsonToCsv",
        props: {
            data: {
                type: Array,
                required: true
            },
            fields: {
                type: [Array, Function],
                required: false
            },
            name: {
                type: String,
                default: "data.csv"
            },
            delimiter: {
                type: String,
                default: ",",
                required: false
            },
            separatorExcel: {
                type: Boolean,
                default: false
            },
            encoding: {
                type: String,
                default: "utf-8"
            },
            advancedOptions: {
                type: Object,
                default: () => {}
            },
            labels: {
                type: [Object, Function],
                required: false
            },
            testing: {
                required: false,
                default: false
            }
        },
        computed: {
            idName() {
                return "export_" + Date.now();
            },
            exportableData() {
                const filteredData = this.cleaningData();
                if (!filteredData.length) {
                     return null;
                }

                return filteredData;
            }
        },
        methods: {
            labelsFunctionGenerator() {
                let labels = this.labels;

                if (!isType(labels, "undefined") && !isType(labels, "function") && !isType(labels, "object")) {
                    throw new Error("Labels needs to be a function(value,key) or object.");
                }

                if (isType(labels, "function")) {
                    return (item) => {
                        let _mapKeys = mapKeys(item, labels);
                        return _mapKeys;
                    };
                }

                if (isType(labels, "object")) {
                    return item => {
                        return mapKeys(item, (item, key) => {
                            return labels[key] || key;
                        });
                    };
                }

                return item => item;
            },
            fieldsFunctionGenerator() {
                let fields = this.fields;

                if (!isType(fields, "undefined") && !isType(fields, "function") && !isType(fields, "object") && !Array.isArray(fields)) {
                    throw new Error("Fields needs to be a function(value,key) or array.");
                }

                if (isType(fields, "function") ||(isType(fields, "object") && !Array.isArray(fields))) {
                    return item => {
                        return pickBy(item, fields);
                    };
                }

                if (Array.isArray(fields)) {
                    return item => {
                        return pick(item, fields);
                    };
                }
                return item => item;

            },
            cleaningData() {
                if (isType(this.fields, "undefined") && isType(this.labels, "undefined")) {
                    return this.data;
                }

                const labels = this.labelsFunctionGenerator();
                const fields = this.fieldsFunctionGenerator();

                return this.data.map(item => labels(fields(item)));
            },
            generate() {
                this.$emit("export-started");
                let dataExport = this.exportableData;

                dataExport.forEach(el => {
                    for (var key in el) {
                        if(Array.isArray(el[key]) && typeof el[key][0] === 'object') {
                            
                            const tempArr = el[key].map(obj => Object.values(obj));

                            for (let i = 0; i < tempArr[0].length; i++) {
                                el["t_"+key+"_"+i] = tempArr[0][i];
                            }
                            delete el[key]; // Remove the el[key] property

                        }
                    }
                });

                if (!dataExport) {
                    console.error("No data to export");
                    return;
                }

                let csv = unparse(
                    dataExport,
                    Object.assign(
                        {
                            delimiter: this.delimiter,
                            encoding: this.encoding
                        },
                        this.advancedOptions
                    )
                );
                if (this.separatorExcel) {
                    csv = "SEP=" + this.delimiter + "\r\n" + csv;
                }
                //Add BOM when UTF-8
                if (this.encoding === "utf-8") {
                    csv = "\ufeff" + csv;
                }
                this.$emit("export-finished");
                if (!this.testing) {
                    let blob = new Blob([csv], {
                    type: "text/csv;charset=" + this.encoding
                    });
                    saveAs(blob, this.idName);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>