<template>
  <div>

      <base-stepper
        title="Test stepper"
        v-slot="{changeStep, currentStep}"
        :numOfSteps="2"
        :hideNavigator="true"
        :hideTitle="true"
      >

        <v-stepper-items>
          
          <step-form-content
            title="Step 1"
            :schema="schemaStep1"
            storeNamespace="devtest"
            objectNamespace="O_test1"
            :step="1"
            :clearStoreOnCreate="true"
            @step-change="changeStep"
          ></step-form-content>


          <step-form-content
            title="Step 2"
            :schema="schemaStep2"
            storeNamespace="devtest"
            objectNamespace="O_test2"
            :clearStoreOnCreate="true"
            :step="2"
            :lastStep="true"
            :currentStep="currentStep"
            @step-change="changeStep"
            @step-done="onDone"
          ></step-form-content>
        </v-stepper-items>
      </base-stepper>

  </div>
</template>

<script>
import StepFormContent from '@/components/form/StepFormContent'

export default {
    name: "StepperTest",
    components: {
      StepFormContent
    },
    props: {
      inlineMode: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      onDone() {

        this.$emit('stepper-done');
      }
    },
    computed: {

        schemaStep1() {
            const test1 = {
                name: '{ type:"input"}',
                description: '{ type:"textarea"}',
            }
            return this.$helpers.schemaParser(test1)
        },
        schemaStep2() {
            const test2 = {
                name: '{ type:"input"}',
                description: '{ type:"textarea"}',
            }
            return this.$helpers.schemaParser(test2)
        },
    }
}
</script>

<style>

</style>