<template>
    <izy-card
        :title="title"
        :subtitle="subtitle"
        :icon="showList ? icon : ''"
        :inlineMode="inlineMode"
    >
        <template
            v-if="showList" 
            v-slot:header
        >
            <slot name="header"></slot>
        </template>
        
        <v-row>

            <v-col cols="12" v-if="loading" class="px-6">
                <v-skeleton-loader
                    type="table-heading, list-item-two-line, table-tfoot"
                ></v-skeleton-loader>
            </v-col>

            <v-col cols="12" v-else-if="!showList && !loading">
                <div class="d-flex flex-column align-center mt-5">
                    <v-avatar 
                        color="grey lighten-4"
                        size="100"
                    >
                        <v-img
                            :src="icon"
                            contain
                        />
                    </v-avatar>
                    <p class="mt-2 mb-0">Du har inget innehåll att visa här än</p>
                </div>
                <div 
                    v-if="newEnabled"
                    class="d-flex justify-center izy-btn mt-5"
                >
                    <v-btn
                        class="izy-btn" 
                        color="primary" 
                        large
                        :to="{path: buttonLink}"
                        v-if="buttonLink"
                    >
                        {{buttonText}}
                    </v-btn>

                    <v-btn 
                        color="primary" 
                        large
                        @click="$emit('onNew')"
                        v-else 
                    >
                        {{buttonText}}
                    </v-btn>
                </div>

            </v-col>


            <v-col cols="12" v-else-if="showList">
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="4" class="px-6 py-0 mb-2">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Sök"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-data-table
                        class="list-table"
                        v-model="selected"
                        :headers="headers"
                        :items="list"
                        :options.sync="options"
                        :search="search"
                        :footer-props="{
                            itemsPerPageOptions:[10,20,50,100]
                        }"
                        item-key="id"
                        multi-sort
                        sort-by="journal_id"
                        sort-desc
                        :loading="getThemeMode.isListLoading"
                        :item-class="setRowStyle"
                        @click:row="editItem"
                    >

                        <template 
                            v-for="slotValue in slotValues" 
                            v-slot:[`item.${slotValue.value}`]="{ item }">
                            <div :key="slotValue.value">
                                <slot :name="[`row.${slotValue.value}`]" :entity="item" :index="list.indexOf(item)">
                                    <v-icon
                                        v-if="slotValue.delete"
                                        @click.stop="deleteItem(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </slot>
                            </div>
                        </template>
                        
                        <template
                            v-if="exportData"
                            v-slot:footer.page-text="items"
                        >
                           
                            <div class="d-flex px-4 py-4 justify-end align-center">    
                                <span class="mr-8">{{ items.pageStart }} - {{ items.pageStop }} av {{ items.itemsLength }}</span>
                                <json-to-csv :fields="filterExportListHeaders" :data="list"></json-to-csv>
                            </div>
                        </template>

                        
                    </v-data-table>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">Är du säkert på att du vill ta bort?</v-card-title>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
            </v-col>
        </v-row>
    </izy-card>
</template>
<script>

import {mapGetters, mapActions} from 'vuex'
import IzyCard from '@/components/card/IzyCard'
import JsonToCsv from '../util/JsonToCsv.vue'
export default {
    name:"ActionList",
    props: {
        title: {
            type: String,
            default: 'Table'
        },
        subtitle: {
            type: String,
        },
        headers: {
            type: Array,
            default: () => ([])
        },
        icon: {
            type: String
        },
        storeNamespace: {
            type:String,
            default: ''
        },
        listNamespace: {
            type:String,
            default: ''
        },
        objectNamespace: {
            type:String,
            default: ''
        },
        changePathOnEdit: {
            type:Boolean,
            default: true
        },
        buttonLink: {
            type:String,
            default: ''
        },
        editPath: {
            type:String,
            default: 'edit'
        },
        buttonText: {
            type: String
        },
        editEnabled: {
            type:Boolean,
            default: true
        },
        newEnabled: {
            type: Boolean,
            default: true
        },
        inlineMode: {
            type: Boolean,
            default: false
        },
        refreshData: {
            type: Number,
            default:0
        },
        exportData: {
            type: Boolean,
            default: false
        }
    },
    components: {
        IzyCard,
        JsonToCsv
    },
    beforeCreate() {
        let storeNamespace = this.$options.propsData.storeNamespace;
        let listNamespace = this.$options.propsData.listNamespace;
        let objectNamespace = this.$options.propsData?.objectNamespace;

        let actions = [
            'list_'+ listNamespace,
        ]
        if(objectNamespace) {
            actions.push('delete_' + objectNamespace)
        }

        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters(storeNamespace, [listNamespace]),
        }
        this.$options.methods = {
            ...this.$options.methods,
            ...mapActions(storeNamespace, actions)
        }

    },
    data() {
        return {
            search: '',
            selected: [],
            options: {},
            loading: false,
            dialogDelete: false,
            itemToDelete: null
        }
    },
    methods: {
        setRowStyle() {
            return this.editEnabled ? 'style-1' : 'style-2'
        },
        async getData() {
            this.loading = true;

            const paramObj = {
                ...(this.search && {query: this.search})
            }

            await this['list_'+this.listNamespace]({params: paramObj});
            this.loading = false;
        },
        editItem(item) {
    
            if(this.editEnabled) {
                if(this.changePathOnEdit) {
                    const path = this.$route.path.substring(0, this.$route.path.lastIndexOf('/')) 
                    this.$router.push({ path: path + `/${this.editPath}/${item.id}` }) 
                }
                else {
                
                    this.$emit('onEdit', item);
                }
            }
        },
        filterExportList() {
            const keysToFilter = this.headers.map(obj => obj.value);
     
            let filteredData = this.list.map(obj => {
                return this.$helpers.filterByKeyArray(obj, keysToFilter);
            });
             
    
            return filteredData;
        },
        deleteItem(entity) {
            this.dialogDelete = true;
            this.itemToDelete = entity.id;
        },
        async deleteItemConfirm() {
            this.dialogDelete = false;
            await this['delete_' + this.objectNamespace]({id:this.itemToDelete});
            await this.getData();

        },
        closeDelete() {
            this.dialogDelete = false;
            this.itemToDelete = null;
        }
    },
    computed: {
        ...mapGetters(['getThemeMode']),
        slotValues() {
            return this.headers.filter(o => (o.slot === true)); 
        },
        list() {
            if(this[this.listNamespace].data) {
                return this[this.listNamespace].data
            }
            else {
                return this[this.listNamespace];
            }
        },
        params() {
            return {
                //...this.options,
                query: this.search
            }
        },
        showList() {
            return this.list.length > 0 ? true : false;
        },
        filterExportListHeaders() {
            const keysToFilter = this.headers.map(obj => obj.value);
            return keysToFilter;
        },
    },
    watch: {
        refreshData: {
            handler() {
                alert("refresh");
            }
        }
        /*
        params: {
            handler (val) {
                console.log("PARAM")
                console.log(val)
                this.getData();
            },
            deep: true,
        },
        options: {
            handler (val) {
                console.log("options")
                console.log(val)
    
            },
        }
        */
    },
    created() {
        this.getData();
    }
}
</script>
<style scoped>
    * >>>.list-table th {
        padding: 0 28px !important;
    }
    * >>>.style-1:hover {
        cursor: pointer !important;
    }
    * >>>.style-1 td, * >>>.style-2 td  {
            padding: 0 28px !important;
            height: 55px !important;
            max-width: 200px !important;
    }
    * >>>.style-1:hover {
        cursor: pointer !important;
    }

</style>
