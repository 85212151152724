<template>

    <edit-form
        :title="title"
        :subTitle="subTitle"
        :schema="schema"
        :storeNamespace="storeNamespace"
        :objectNamespace="objectNamespace"
        :objectId="id"
        :listOnSave="listOnSave"
    >

    </edit-form>

</template>

<script>
import EditForm from '@/components/form/EditForm'

export default {
    name: "UserForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
        storeNamespace: {
            type: String,
            default: '' 
        },
        objectNamespace: {
            type: String,
            default: '' 
        }
    },
    computed: {
       schema() {
           
            let frontendUrl = window.location.origin + '/' + 'reset';
            frontendUrl = frontendUrl.replaceAll(':', '^:')

            const filter = {
                name_first: '{type:"input", rules:"required"}',
                name_last: '{type:"input", rules:"required"}',
                email: '{type:"input", rules:"required|email"}',
                phone: '{type:"input", rules:"required"}',
                frontend_url: '{type:"hiddenInput", rules:"required", exclude:["update"], initialValue:"'+ frontendUrl +'"}'
            }

            return this.$helpers.schemaParser(filter)
        },
        title() {
            return this.id ? "Uppdatera användare" : "Ny användare";
        },
        subTitle() {
            return this.id ? "Här kan du redigera användaren" : "Här kan du skapa en ny användare";
        },
        
    },
}
</script>

<style>

</style>