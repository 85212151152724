import BaseStore from "@/modules/baseStore.js";
import BaseApi from "@/modules/baseApi.js";

const state = {
  A_batch: [],
  O_batch: {},
  
  A_result: [],
  O_result: {},

  pdfs: {},

  A_queue: [],
  O_queue: {},

  A_stats: [],


  stateRules: {
    batch: {
      prepend: ['envelope', 'inner']
    },
    queue: {
      prepend: ['lab']
    },
  }

}

const api = BaseApi(state, "sample")


export default BaseStore.extend({
  state,
  api
})