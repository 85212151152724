<template>
  <v-snackbar v-model="snackbar" top fixed :color="snackbarType">
    <v-icon class="mr-2" v-if="snackbarType === 'success'">mdi-checkbox-marked-circle</v-icon>
      {{snackbarText}}
    <template v-slot:action="{attrs}">
      <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar = false"
      >
          <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import eventBus from '@/plugins/eventbus.js'
export default {
  name: 'Snackbar',
  data: () => ({
    snackbar: false,
    snackbarText: "",
    snackbarType: "info",
  }),
  methods: {
    setSnackbar(payload) {
      this.snackbarText = payload.text;
      this.snackbarType = payload.type;
      this.snackbar = true;
    }
  },
  created() {
    eventBus.$on.snackMsg(this.setSnackbar)
  }

}
</script>
