
import settingsLabStore from './store/settingsLab'
import settingsEconomyStore from './store/settingsEconomy'

import Sites from './pages/Sites'

import SiteCard from './pages/SiteCard'

import EconomyCard from './pages/EconomyCard'
// eslint-disable-next-line no-unused-vars
export function settingsModule({ app, router, store, eventBus }) {

	router.addRoute('Home', { 
		path: 'settings', 
		name:'settings', 
		component: () => import("@/components/ParentNode"), 
		redirect: to => {return to.path + '/list'}
	})


    router.addRoute('settings', { path: 'sites', component: Sites })

	router.addRoute('settings', { path: 'site/:id?', component: SiteCard, props:true})


	router.addRoute('settings', { path: 'economy', component: EconomyCard })
	/**
	 * Register store
	 */
	store.registerModule('settings', settingsLabStore)
	store.registerModule('economy', settingsEconomyStore)


}