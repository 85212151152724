<template>
  <client-form-stepper
    @stepper-done="onDone"
  ></client-form-stepper>
</template>

<script>
import ClientFormStepper from '../components/ClientFormStepper.vue'

export default {
    name: "ClientStepper",
    components: {
      ClientFormStepper
    },
    methods: {
      onDone() {
        this.$router.push('/clients/list')
      }
    },
}
</script>

<style>

</style>