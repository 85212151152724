import BaseStore from "@/modules/baseStore.js";
import BaseApi from "@/modules/baseApi.js";

const state = {
    A_site: [],
    O_site: {},


    A_queue: [],
    O_queue: {},

    stateRules: {
      
        site: {
          //prepend: ['lab']
        },
        queue: {
          endpointProps: ['site']
        }
      }


}

const api = BaseApi(state, "setting/lab")


export default BaseStore.extend({
  state,
  api
})