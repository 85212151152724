<template>
    
    <edit-form
        :title="title"
        :subTitle="subTitle"
        :schema="schema"
        storeNamespace="clients"
        objectNamespace="O_contact"
        :objectId="id"
        :listOnSave="listOnSave"
        :sectionTitles="sectionTitles"
    >
        <template #editSlot>
            
        </template>
    </edit-form>

</template>

<script>
import EditForm from '@/components/form/EditForm'
import {clientContactSchema} from '../../schema/clientContactSchema'
export default {
    name: "ClientContactForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
    },
    data() {
      return {
        sectionTitles: [
            'Kontaktuppgifter',
            'Kontakttyp',
        ]
      }
    },
    computed: {
        schema() {
            
            return this.$helpers.schemaParser(clientContactSchema)

        },
        title() {
            return this.id ? "Uppdatera kontakt" : "Ny kontakt";
        },
        subTitle() {
            return this.id ? "Här kan du redigera en kontakt" : "Här fyller du i information om din nya kontakt";
        },
        
    },
}
</script>

<style>

</style>