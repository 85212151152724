<template>
    
    <edit-form
        :title="title"
        :subTitle="subTitle"
        :schema="schema"
        storeNamespace="settings"
        objectNamespace="O_site"
        :objectId="id"
        :listOnSave="listOnSave"
    >

    </edit-form>

</template>

<script>
import EditForm from '@/components/form/EditForm'
import {siteSchema} from '../schema/siteSchema.js'

export default {
    name: "PriceperiodForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
    },
    data() {
      return {

      }
    },
    computed: {
        schema() {
          return this.$helpers.schemaParser(siteSchema)

        },
        title() {
            return this.id ? "Uppdatera sajt" : "Ny sajt";
        },
        subTitle() {
            return this.id ? "Här kan du redigera en sajt" : "Här fyller du i information om den nya sajten";
        },
        
    },
}
</script>

<style>

</style>