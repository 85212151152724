<template>
  <div></div>
</template>

<script>
export default {
    props: {
        step: {
            type: Number,
            default: null
        },
        currentStep: {
            type: Number,
            default: null
        },
        previousStep: {
            type: Number,
            default: null
        }
    },
    computed: {
        isActive() {
            return this.currentStep == this.step ? true : false
        }
    },
    watch: {
        isActive: {
            handler(val) {
                if(val) {
                   if(this.currentStep > this.previousStep) {
                       this.$emit('step-change', {action: "next", curr: this.step})
                   }
                   else if(this.currentStep < this.previousStep) {
                       this.$emit('step-change', {action: "prev", curr: this.step})
                   }
                }
            },immediate: true
        }
    }
}
</script>

<style>

</style>