<template>
    <input
        type="hidden"
        :value="value"
    />
</template>

<script>

export default {
    name: "HiddenInput",
    props: {
        item: {
            type: Object,
            default: function() {
                return {}
            }
        },
        value: {
            type:[String, Array],
            default: ''
        },
        onResetForm: {
            type: Number,
            default: 0
        },
    },
    data () {
        return {
            
        }
    },
    computed: {
        inputValue() {
            if(this.item.initialValue) {
                return this.item.initialValue
            }
            else {
                if(this.item.keyName) {
                    return this.$store.state[this.item.storeNamespace][this.item.objectNamespace][this.item.keyName];
                }
                else {
                    return this.$store.state[this.item.storeNamespace][this.item.objectNamespace]
                }
            }
        }
    },
    watch: {
       
        inputValue: {
            immediate: true, 
            handler(val) {

                this.$emit('updated', val);
            }
        },
        onResetForm: {
            handler() {
                this.$emit('updated', this.inputValue);
            }
        }
    
    },
    async created() {
 
    }
}
</script>

<style>

</style>