import BaseStore from "@/modules/baseStore.js";
import BaseApi from "@/modules/baseApi.js";

const state = {
  A_site: [],
  O_test1: {},
  A_test2: [],
  O_test2: {},
  
}

const api = BaseApi(state, "setting/lab")

export default BaseStore.extend({
  state,
  api
})