import BaseStore from "@/modules/baseStore.js";
import BaseApi from "@/modules/baseApi.js";

const state = {
    A_method: [],
    O_method: {},

    A_term: [],
    O_term: {},

    stateRules: {
      
        term: {
          prepend: ['billing']
        },
        method: {
          prepend: ['billing']
        },

      }


}

const api = BaseApi(state, "setting/economy")


export default BaseStore.extend({
  state,
  api
})