<template>
  <base-tab-card
    :tabs="tabs"
    :title="title"
    v-if="objectLoaded"
    v-on="$listeners"
  >
  </base-tab-card>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {mapGetters, mapActions, mapMutations} from 'vuex'

export default {
    props: {
        id: {
            type: String
        },
        tabs: {
            type: Array,
            default: () => {
                return []
            }
        },
        storeNamespace: {
            type:String,
            default: ''
        },
        objectNamespace: {
            type:String,
            default: ''
        }
    },
    beforeCreate() {
        let storeNamespace = this.$options.propsData.storeNamespace;
        let objectNamespace = this.$options.propsData.objectNamespace;
        let objectNamespaceUpperCase = objectNamespace.toUpperCase();

        const splitObjectNamespace = objectNamespace.split('_');
        
        let stateRules = (this.$store.getters[storeNamespace + '/stateRules'] && splitObjectNamespace[1] in this.$store.getters[storeNamespace + '/stateRules']) ? this.$store.getters[storeNamespace + '/stateRules'][splitObjectNamespace[1]] : false;

        this.$options.computed = {
            ...this.$options.computed,
            ...mapGetters(storeNamespace, [objectNamespace]),
            stateRules: () => stateRules,
        }
        this.$options.methods = {
            ...this.$options.methods,
            ...mapMutations(storeNamespace, ['CLEAR_'+objectNamespaceUpperCase]),
            ...mapActions(storeNamespace, ['create_'+ objectNamespace, 'get_'+ objectNamespace, 'update_'+ objectNamespace]),
        }
    },
    methods: {
        async getItem() {
            try {
                await this['get_'+this.objectNamespace]({id:this.id});
            } catch (error) {
                console.error(error);
            }
        },
    },
    computed: {
        title() {
            return this[this.objectNamespace]['name'] ? this[this.objectNamespace]['name'] : ''
        },
        objectLoaded() {
            //return true
            return this[this.objectNamespace] && this[this.objectNamespace]['id']  ? true : false
        }
    },
    created () {
       this.getItem();
    },
}
</script>

<style>

</style>