<template>
  <div>
    <h1>test</h1>
    <v-btn @click="getTest()" color="success">get</v-btn>
  </div>
 
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  methods: {
    ...mapActions('test', ['getTest']),

  },
  computed: {
    ...mapGetters('test', ['test']),
  },
}
</script>

