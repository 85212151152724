import Dashboard from './pages/Dashboard.vue'
import DashboardStore from './store/dashboard.js'


// eslint-disable-next-line no-unused-vars
export function dashboardModule({ app, router, store, eventBus }) {

    
	router.addRoute('Home', { 
		path: 'dashboard', 
		name:'dashboard', 
		component: Dashboard, 
	})
/*
	router.addRoute('profile', { path: 'user', component: Profile })
    */

	/**
	 * Register store
	 */
	store.registerModule('dashboard', DashboardStore)





}





