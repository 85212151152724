<template>
    
    <edit-form
        :title="title"
        :subTitle="subTitle"
        :schema="schema"
        storeNamespace="clients"
        objectNamespace="O_agreement"
        :objectId="id"
        :listOnSave="listOnSave"
    >

    </edit-form>

</template>

<script>
import EditForm from '@/components/form/EditForm'
import {clientAgreementSchema} from '../../schema/clientAgreementSchema'

export default {
    name: "ClientAgreementForm",
    components: {
        EditForm,
    },
    props: {
        id: {
            type: String
        },
        listOnSave: {
            type: Boolean,
            default: false 
        },
    },
    data() {
      return {

      }
    },
    computed: {
        schema() {
            
            return this.$helpers.schemaParser(clientAgreementSchema)

        },
        title() {
            return this.id ? "Update agreement" : "Nytt avtal";
        },
        subTitle() {
            return this.id ? "Here you can edit a agreement" : "Här väljer du vilket avtal kunden ska ha";
        },
        
    },
}
</script>

<style>

</style>