import api from "@/services/api";

class profileService {
    

    getUser() {
        return api.get("aaa/user")
    }
    updateUser(data) {
        return api.put("aaa/user", data)
    }

}

export default new profileService;

